import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Text, VStack, List, ListItem, Spinner, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, Flex } from '@chakra-ui/react';
import { useAuth } from '../../../context/AuthContext';
import { useNotifications } from '../../../context/NotificationContext'; // Import notification context
import { BsInfoCircleFill } from "react-icons/bs";
import { FaPowerOff } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import axios from "axios";
import { API_URL } from "../../../utils/apiConfig";
import PlayerGrid from "./PlayerGrid";




const TeamDetailsPage = () => {
    const { eventId, game, teamName } = useParams();
    const { user } = useAuth();
    const [event, setEvent] = useState(null);
    const [player, setPlayer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isJudge1ModalOpen, setIsJudge1ModalOpen] = useState(false);
    const [isJudge2ModalOpen, setIsJudge2ModalOpen] = useState(false);
    const [isJudge3ModalOpen, setIsJudge3ModalOpen] = useState(false);
    const [isJudge4ModalOpen, setIsJudge4ModalOpen] = useState(false);
    const [isA1ModalOpen, setIsA1ModalOpen] = useState(false);
    const [isA2ModalOpen, setIsA2ModalOpen] = useState(false);
    const [isA3ModalOpen, setIsA3ModalOpen] = useState(false);
    const [isA4ModalOpen, setIsA4ModalOpen] = useState(false);
    const [isD1ModalOpen, setIsD1ModalOpen] = useState(false);
    const [isD2ModalOpen, setIsD2ModalOpen] = useState(false);
    const [isD3ModalOpen, setIsD3ModalOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [score, setScore] = useState('');
    const [s1, setS1] = useState("");
    // const [s2, setS2] = useState("");
    const [penalty, setPenalty] = useState(0);
    const [cjp, setCJP] = useState(0);
    const [dividedby, setDividedBy] = useState("");
    const [judgeToEdit, setJudgeToEdit] = useState('');
    const [approveD, setApproveD] = useState('');
    const [isScoreModalOpen, setIsScoreModalOpen] = useState(false);
    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const { addNotification } = useNotifications(); // Use addNotification from context
    const [status, setStatus] = useState('not played'); // Track player status
    const [startBtnDisabled, setStartBtnDisabled] = useState(false);
    const [editCount, setEditCount] = useState(0); // Track edit count
    const [editCountJudge1, setEditCountJudge1] = useState(0);
    const [editCountJudge3, setEditCountJudge3] = useState(0);
    const [editCountJudge4, setEditCountJudge4] = useState(0);
    const [editCountA1, setEditCountA1] = useState(0);
    const [editCountA2, setEditCountA2] = useState(0);
    const [editCountA3, setEditCountA3] = useState(0);
    const [editCountA4, setEditCountA4] = useState(0);
    const [editCountD1, setEditCountD1] = useState(0);
    const [editCountD2, setEditCountD2] = useState(0);
    const [editCountD3, setEditCountD3] = useState(0);
    const [editCounts, setEditCounts] = useState({ judge1: 0, judge2: 0, judge3: 0, judge4: 0 , a1:0, a2:0, a3:0, a4:0, d2:0, d3:0});
    const [isHoveredE, setIsHoveredE] = useState({ judge1: false, judge2: false, judge3: false, judge4: false , a1:false, a2:false, a3:false, a4:false, d2:false, d3:false});
    const [isScoreBtnDisabledJudge1, setIsScoreBtnDisabledJudge1] = useState(false);
    const [isScoreBtnDisabledJudge3, setIsScoreBtnDisabledJudge3] = useState(false);
    const [isScoreBtnDisabledJudge4, setIsScoreBtnDisabledJudge4] = useState(false);
    const [isScoreBtnDisabledD1, setIsScoreBtnDisabledD1] = useState(false);
    const [isScoreBtnDisabledD2, setIsScoreBtnDisabledD2] = useState(false);
    const [isScoreBtnDisabledD3, setIsScoreBtnDisabledD3] = useState(false);      
    const [isScoreBtnDisabledA1, setIsScoreBtnDisabledA1] = useState(false);
    const [isScoreBtnDisabledA2, setIsScoreBtnDisabledA2] = useState(false);
    const [isScoreBtnDisabledA3, setIsScoreBtnDisabledA3] = useState(false);
    const [isScoreBtnDisabledA4, setIsScoreBtnDisabledA4] = useState(false);
    const [isScoreBtnDisabled, setIsScoreBtnDisabled] = useState(false); // Disable score button after 2 edits
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredJudge1, setIsHoveredJudge1] = useState(false);
    const [isHoveredJudge3, setIsHoveredJudge3] = useState(false);
    const [isHoveredJudge4, setIsHoveredJudge4] = useState(false);
    const [isHoveredD1, setIsHoveredD1] = useState(false);
    const [isHoveredD2, setIsHoveredD2] = useState(false);
    const [isHoveredD3, setIsHoveredD3] = useState(false);
    const [isHoveredA1, setIsHoveredA1] = useState(false);
    const [isHoveredA2, setIsHoveredA2] = useState(false);
    const [isHoveredA3, setIsHoveredA3] = useState(false);
    const [isHoveredA4, setIsHoveredA4] = useState(false);
    const [playerInfo, setPlayerInfo] = useState(false);


    useEffect(() => {
        const fetchTeamDetails = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/events-aerobic/${eventId}/team/${teamName}/aerotype/${game}`);
                const eventDetails = response.data;
                if (eventDetails) {
                    const playerDetails = eventDetails.data;
                    console.log("playrdetails", playerDetails)
                    if (playerDetails) {
                        setEvent(eventDetails);
                        setPlayer(playerDetails);
                        setStatus(playerDetails.status); // Sync status with backend data
                        setStartBtnDisabled(playerDetails.status !== 'not played'); // Disable button based on status
                        setEditCounts({
                            judge1: playerDetails.editCountDjudge.judge1 || 0,
                            judge2: playerDetails.editCountDjudge.judge2 || 0,
                            judge3: playerDetails.editCountDjudge.judge3 || 0,
                            judge4: playerDetails.editCountDjudge.judge4 || 0,
                            a1: playerDetails.editCountDjudge.a1 || 0,
                            a2: playerDetails.editCountDjudge.a2 || 0,
                            a3: playerDetails.editCountDjudge.a3 || 0,
                            a4: playerDetails.editCountDjudge.a4 || 0,
                            d2: playerDetails.editCountDjudge.d2 || 0,
                            d3: playerDetails.editCountDjudge.d3 || 0,
                        });
                        if (playerDetails.scores?.judge1 !== undefined) {
                            setEditCountJudge1(playerDetails.scores.judge1 || 0);
                        }
                        setEditCountJudge1(playerDetails.editCount.judge1 || 0);
                        setIsScoreBtnDisabledJudge1(playerDetails.editCount.judge1 >= 2);
                        if (playerDetails.scores?.judge2 !== undefined) {
                            setScore(playerDetails.scores.judge2 || 0);
                        }

                        // Initialize edit count if it's stored in player data, or default to 0
                        setEditCount(playerDetails.editCount.judge2 || 0);

                        // Disable the score button if the edit count is 2 or more

                        setIsScoreBtnDisabled(playerDetails.editCount.judge2 >= 2);
                        if (playerDetails.scores?.judge3 !== undefined) {
                            setEditCountJudge3(playerDetails.scores.judge3 || 0);
                        }
                        setEditCountJudge3(playerDetails.editCount.judge3 || 0);
                        setIsScoreBtnDisabledJudge3(playerDetails.editCount.judge3 >= 2);

                        if (playerDetails.scores?.judge4 !== undefined) {
                            setEditCountJudge4(playerDetails.scores.judge4 || 0);
                        }
                        setEditCountJudge4(playerDetails.editCount.judge4 || 0);
                        setIsScoreBtnDisabledJudge4(playerDetails.editCount.judge4 >= 2);

                        if (playerDetails.scores?.a1 !== undefined) {
                            setEditCountA1(playerDetails.scores.a1 || 0);
                        }
                        setEditCountA1(playerDetails.editCount.a1 || 0);
                        setIsScoreBtnDisabledA1(playerDetails.editCount.a1 >= 2);

                        if (playerDetails.scores?.a2 !== undefined) {
                            setEditCountA2(playerDetails.scores.a2 || 0);
                        }
                        setEditCountA2(playerDetails.editCount.a2 || 0);
                        setIsScoreBtnDisabledA2(playerDetails.editCount.a2 >= 2);

                        if (playerDetails.scores?.a3 !== undefined) {
                            setEditCountA3(playerDetails.scores.a3 || 0);
                        }
                        setEditCountA3(playerDetails.editCount.a3 || 0);
                        setIsScoreBtnDisabledA3(playerDetails.editCount.a3 >= 2);

                        if (playerDetails.scores?.a4 !== undefined) {
                            setEditCountA4(playerDetails.scores.a4 || 0);
                        }
                        setEditCountA4(playerDetails.editCount.a4 || 0);
                        setIsScoreBtnDisabledA4(playerDetails.editCount.a4 >= 2);

                        if(playerDetails.scores?.d1 !== undefined){
                            setEditCountD1(playerDetails.scores.d1 || 0);
                        }
                        setEditCountD1(playerDetails.editCount.d1 || 0);
                        setIsScoreBtnDisabledD1(playerDetails.editCount.d1 >= 2);

                        if(playerDetails.scores?.d2 !== undefined){
                            setEditCountD2(playerDetails.scores.d2 || 0);
                        }
                        setEditCountD2(playerDetails.editCount.d2 || 0);
                        setIsScoreBtnDisabledD2(playerDetails.editCount.d2 >= 2);

                        if(playerDetails.scores?.d3 !== undefined){
                            setEditCountD3(playerDetails.scores.d3 || 0);
                        }
                        setEditCountD3(playerDetails.editCount.d3 || 0);
                        setIsScoreBtnDisabledD3(playerDetails.editCount.d3 >= 2);
                        
                    } else {
                        console.error('Player not found');
                    }
                } else {
                    console.error('Event not found');
                }
            } catch (error) {
                console.error('Error fetching event:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTeamDetails();
    }, [teamName]);
    


    const handleStartPlaying = async (teamName) => {
        try {
            const updatedEvent = { ...event };

            // Find the player's index in the selected game
            const playerIndex = updatedEvent.teamName === teamName;

            if (playerIndex !== true) {
                console.error('Player not found');
                return;
            }

            // Update the player's status to 'playing'
            const updatedStatus = 'playing';

            // Prepare the updated data to be sent to the backend
            const updateData = {
                status: updatedStatus
            };

            // Send the updated status to the backend via a PATCH request
            await axios.patch(
                `${API_URL}/api/events-aerobic-live/${eventId}/${teamName}/${game}/jte/status`,
                updateData
            );

            // Update the local event state
            updatedEvent.data.status = updatedStatus;

            // Update the UI accordingly
            setStatus(updatedStatus);
            setEvent(updatedEvent);
            setStartBtnDisabled(true); // Disable the start button after status update

        } catch (error) {
            console.error('Error updating player status:', error);
        }
    };



    const openJudge1Modal = (player) => {
        setSelectedPlayer(player);
        setIsJudge1ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.judge1 !== undefined) {
            setScore(player.scores.judge1);
        } else {
            setScore('');
        }

    };
    const openJudge2Modal = (player) => {
        setSelectedPlayer(player);
        setIsJudge2ModalOpen(true);
        handleStartPlaying(teamName);
        if (player.scores.judge2 !== undefined) {
            setScore(player.scores.judge2);
        } else {
            setScore('');
        }
    };
    const openJudge3Modal = (player) => {
        setSelectedPlayer(player);
        setIsJudge3ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.judge3 !== undefined) {
            setScore(player.scores.judge3);
        } else {
            setScore('');
        }
    };
    const openJudge4Modal = (player) => {
        setSelectedPlayer(player);
        setIsJudge4ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.judge4 !== undefined) {
            setScore(player.scores.judge4);
        } else {
            setScore('');
        }
    };

    const openA1Modal = (player) => {
        setSelectedPlayer(player);
        setIsA1ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.a1 !== undefined) {
            setScore(player.scores.a1);
        } else {
            setScore('');
        }

    };
    const openA2Modal = (player) => {
        setSelectedPlayer(player);
        setIsA2ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.a2 !== undefined) {
            setScore(player.scores.a2);
        } else {
            setScore('');
        }

    };
    const openA3Modal = (player) => {
        setSelectedPlayer(player);
        setIsA3ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.a3 !== undefined) {
            setScore(player.scores.a3);
        } else {
            setScore('');
        }

    };
    const openA4Modal = (player) => {
        setSelectedPlayer(player);
        setIsA4ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.a4 !== undefined) {
            setScore(player.scores.a4);
        } else {
            setScore('');
        }

    };

    const openD1Modal = (player, judge) => {
        setSelectedPlayer(player);
        setJudgeToEdit(judge);
        setIsD1ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.cjp !== undefined) {
            setCJP(player.scores.cjp);
        } else {
            setCJP('');
        }
    };

    const openD2Modal = (player) => {
        setSelectedPlayer(player);
        setIsD2ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.d2 !== undefined) {
            setScore(player.scores.d2);
        } else {
            setScore('');
        }
        
    };

    const openD3Modal = (player) => {
        setSelectedPlayer(player);
        setIsD3ModalOpen(true);
        handleStartPlaying(player._id);
        if (player.scores.d3 !== undefined) {
            setScore(player.scores.d3);
        } else {
            setScore('');
        }
        if(player.scores.penalty !== undefined){
            setPenalty(player.scores.penalty)
        }
        if(player.scores.dividedby !== undefined){
            setDividedBy(player.scores.dividedby)
        }
        
    };

    const openScoreModal = (player, judge) => {
        setSelectedPlayer(player);
        setJudgeToEdit(judge);
        setScore(player.scores[judge] || '');
        setIsScoreModalOpen(true);
        handleStartPlaying(player._id);
    };

    const openApproveModal = (player, judge) => {
        setSelectedPlayer(player);
        setJudgeToEdit(judge);
        setIsApproveModalOpen(true);
        handleStartPlaying(player._id);
    }


    const closeModal = () => {
        setSelectedPlayer(null);
        setScore('');
        setIsJudge1ModalOpen(false);
        setIsJudge2ModalOpen(false);
        setIsJudge3ModalOpen(false);
        setIsJudge4ModalOpen(false);
        setIsA1ModalOpen(false);
        setIsA2ModalOpen(false);
        setIsA3ModalOpen(false);
        setIsA4ModalOpen(false);
        setIsD1ModalOpen(false);
        setIsD2ModalOpen(false);
        setIsD3ModalOpen(false);
        setIsScoreModalOpen(false);
        setIsApproveModalOpen(false);
    };


    const getUserRoleForGame = (judges, game) => {
        const userEmail = user?.email;
    
        if (userEmail) {
            if (judges[game]) {
                const judge1Email = judges[game].judge1 ? extractEmail(judges[game].judge1.name) : null;
                console.log("judge1emaillllllllllllllllll", judge1Email)
                const judge2Email = judges[game].judge2 ? extractEmail(judges[game].judge2.name) : null;
                console.log("judge2emaillllllllllllllllll", judge2Email)
                const judge3Email = judges[game].judge3 ? extractEmail(judges[game].judge3.name) : null;
                console.log("judge3emaillllllllllllllllll", judge3Email)
                const judge4Email = judges[game].judge4 ? extractEmail(judges[game].judge4.name) : null;
                console.log("judge4emaillllllllllllllllll", judge4Email)
                const a1Email = judges[game].a1 ? extractEmail(judges[game].a1.name) : null;
                const a2Email = judges[game].a2 ? extractEmail(judges[game].a2.name) : null;
                const a3Email = judges[game].a3 ? extractEmail(judges[game].a3.name) : null;
                const a4Email = judges[game].a4 ? extractEmail(judges[game].a4.name) : null;
                const d1Email = judges[game].d1 ? extractEmail(judges[game].d1.name) : null;
                console.log("d1", d1Email)
                const d2Email = judges[game].d2 ? extractEmail(judges[game].d2.name) : null;
                console.log("d2", d2Email);
                const d3Email = judges[game].d3 ? extractEmail(judges[game].d3.name) : null;
                console.log("d3", d3Email)
    
    
                if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && a1Email === userEmail && a2Email === userEmail && a3Email === userEmail && a4Email === userEmail && d1Email === userEmail && d2Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge3, judge4, a1, a2, a3, a4, D1, D2 and Difficulty 1 Judge for game ${game}`);
                    return 'all';
                }else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail && d2Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge3, judge4, D1, D2 and Difficulty 1  Judge for game ${game}`);
                    return 'all-ejudge';
                }else if (a1Email === userEmail && a2Email === userEmail && a3Email === userEmail && a4Email === userEmail && d1Email === userEmail && d2Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a2, a3, a4, D1 ,D2 and Difficulty 1 Judge for game ${game}`);
                    return 'all-ajudge';
                } else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge3, judge4 for game ${game}`);
                    return 'j1234';
                } else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge3, D Judge for game ${game}`);
                    return 'j123d1';
                } else if (judge1Email === userEmail && judge2Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge4, D Judge for game ${game}`);
                    return 'j124d1';
                } else if (judge1Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge3, judge4, D Judge for game ${game}`);
                    return 'j134d1';
                } else if (judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, judge3, judge4, D Judge for game ${game}`);
                    return 'j234d1';
                }else if (a1Email === userEmail && a2Email === userEmail && a3Email === userEmail && a4Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a2, a3, a4 for game ${game}`);
                    return 'a1234';
                } else if (a1Email === userEmail && a2Email === userEmail && a3Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a2, a3, D Judge for game ${game}`);
                    return 'a123d1';
                } else if (a1Email === userEmail && a2Email === userEmail && a4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a2, a4, D Judge for game ${game}`);
                    return 'a124d1';
                } else if (a1Email === userEmail && a3Email === userEmail && a4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a3, a4, D Judge for game ${game}`);
                    return 'a134d1';
                } else if (a2Email === userEmail && a3Email === userEmail && a4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a2, a3, a4, D Judge for game ${game}`);
                    return 'a234d1';
                } else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge3 for game ${game}`);
                    return 'j123';
                } else if (judge1Email === userEmail && judge2Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, judge4 for game ${game}`);
                    return 'j124';
                } else if (judge1Email === userEmail && judge2Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2, D Judge for game ${game}`);
                    return 'j12d1';
                } else if (judge1Email === userEmail && judge3Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge3, judge4 for game ${game}`);
                    return 'j134';
                } else if (judge1Email === userEmail && judge3Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge3, D Judge for game ${game}`);
                    return 'j13d1';
                } else if (judge1Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge4, D Judge for game ${game}`);
                    return 'j14d1';
                } else if (judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, judge3, judge4 for game ${game}`);
                    return 'j234';
                } else if (judge2Email === userEmail && judge3Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, judge3, D Judge for game ${game}`);
                    return 'j23d1';
                } else if (judge2Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, judge4, D Judge for game ${game}`);
                    return 'j24d1';
                } else if (judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge3, judge4, D Judge for game ${game}`);
                    return 'j34d1';
                }else if (d1Email === userEmail && d2Email === userEmail && d3Email === userEmail){
                    console.log(`User ${userEmail} is D1, D2, CJP Judge for game ${game}`);
                    return 'd123';
                }
                  else if (a1Email === userEmail && a2Email === userEmail && a3Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a2, a3 for game ${game}`);
                    return 'a123';
                } else if (a1Email === userEmail && a2Email === userEmail && a4Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a2, a4 for game ${game}`);
                    return 'a124';
                } else if (a1Email === userEmail && a2Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a2, D Judge for game ${game}`);
                    return 'a12d1';
                } else if (a1Email === userEmail && a3Email === userEmail && a4Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a3, a4 for game ${game}`);
                    return 'a134';
                } else if (a1Email === userEmail && a3Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a3, D Judge for game ${game}`);
                    return 'a13d1';
                } else if (a1Email === userEmail && a4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a4, D Judge for game ${game}`);
                    return 'a14d1';
                } else if (a2Email === userEmail && a3Email === userEmail && a4Email === userEmail) {
                    console.log(`User ${userEmail} is a2, a3, a4 for game ${game}`);
                    return 'a234';
                } else if (a2Email === userEmail && a3Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a2, a3, D Judge for game ${game}`);
                    return 'a23d1';
                } else if (a2Email === userEmail && a4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a2, a4, D Judge for game ${game}`);
                    return 'a24d1';
                } else if (a3Email === userEmail && a4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a3, a4, D Judge for game ${game}`);
                    return 'a34d1';
                } else if (judge1Email === userEmail && judge2Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge2 for game ${game}`);
                    return 'j12';
                } else if (judge1Email === userEmail && judge3Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge3 for game ${game}`);
                    return 'j13';
                } else if (judge1Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, judge4 for game ${game}`);
                    return 'j14';
                } else if (judge1Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, D Judge for game ${game}`);
                    return 'j1d1';
                }else if (judge1Email === userEmail && d2Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, D2 Judge for game ${game}`);
                    return 'j1d2'; //////////d2/////////////
                }else if (judge1Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is judge1, D3 Judge for game ${game}`);
                    return 'j1d3'; //////////d3/////////////
                } else if (judge2Email === userEmail && judge3Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, judge3 for game ${game}`);
                    return 'j23';
                } else if (judge2Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, judge4 for game ${game}`);
                    return 'j24';
                } else if (judge2Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, D Judge for game ${game}`);
                    return 'j2d1';
                } else if (judge2Email === userEmail && d2Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, D2 Judge for game ${game}`);
                    return 'j2d2'; /////////////d2/////////////
                } else if (judge2Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is judge2, D3 Judge for game ${game}`);
                    return 'j2d3'; /////////////d3/////////////
                } else if (judge3Email === userEmail && judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge3, judge4 for game ${game}`);
                    return 'j34';
                } else if (judge3Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge3, D Judge for game ${game}`);
                    return 'j3d1';
                }else if (judge3Email === userEmail && d2Email === userEmail) {
                    console.log(`User ${userEmail} is judge3, D2 Judge for game ${game}`);
                    return 'j3d2'; ///////////d2///////////////
                }else if (judge3Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is judge3, D3 Judge for game ${game}`);
                    return 'j3d3'; ///////////d3///////////////
                } else if (judge4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is judge4, D Judge for game ${game}`);
                    return 'j4d1';
                }else if (judge4Email === userEmail && d2Email === userEmail) {
                    console.log(`User ${userEmail} is judge4, D2 Judge for game ${game}`);
                    return 'j4d2'; ///////////d2/////////////
                }else if (judge4Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is judge4, D3 Judge for game ${game}`);
                    return 'j4d3'; ///////////d3/////////////
                }else if (d1Email === userEmail && d2Email === userEmail) {
                    console.log(`User ${userEmail} is D1, D2 Judge for game ${game}`);
                    return 'd1d2'; ///////////////d2///////////////
                }else if (d1Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is D1, D3 Judge for game ${game}`);
                    return 'd1d3'; ///////////////d3///////////////
                }else if (d2Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is D2, D3 Judge for game ${game}`);
                    return 'd2d3'; ///////////////d3///////////////
                }else if (a1Email === userEmail && a2Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a2 for game ${game}`);
                    return 'a12';
                } else if (a1Email === userEmail && a3Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a3 for game ${game}`);
                    return 'a13';
                } else if (a1Email === userEmail && a4Email === userEmail) {
                    console.log(`User ${userEmail} is a1, a4 for game ${game}`);
                    return 'a14';
                } else if (a1Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a1, D Judge for game ${game}`);
                    return 'a1d1';
                }else if (a1Email === userEmail && d2Email === userEmail) {
                    console.log(`User ${userEmail} is a1, D2 Judge for game ${game}`);
                    return 'a1d2'; //////////////d2///////////////
                }else if (a1Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is a1, D3 Judge for game ${game}`);
                    return 'a1d3'; //////////////d3///////////////
                } else if (a2Email === userEmail && a3Email === userEmail) {
                    console.log(`User ${userEmail} is a2, a3 for game ${game}`);
                    return 'a23';
                } else if (a2Email === userEmail && a4Email === userEmail) {
                    console.log(`User ${userEmail} is a2, a4 for game ${game}`);
                    return 'a24';
                } else if (a2Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a2, D Judge for game ${game}`);
                    return 'a2d1';
                } else if (a2Email === userEmail && d2Email === userEmail) {
                    console.log(`User ${userEmail} is a2, D2 Judge for game ${game}`);
                    return 'a2d2';/////////////d2/////////////////
                }else if (a2Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is a2, D3 Judge for game ${game}`);
                    return 'a2d3';/////////////d3/////////////////
                } else if (a3Email === userEmail && a4Email === userEmail) {
                    console.log(`User ${userEmail} is a3, a4 for game ${game}`);
                    return 'a34';
                } else if (a3Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a3, D Judge for game ${game}`);
                    return 'a3d1';
                } else if (a3Email === userEmail && d2Email === userEmail) {
                    console.log(`User ${userEmail} is a3, D2 Judge for game ${game}`);
                    return 'a3d2'; ////////////d2//////////////////
                }else if (a3Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is a3, D3 Judge for game ${game}`);
                    return 'a3d3'; ////////////d3//////////////////
                } else if (a4Email === userEmail && d1Email === userEmail) {
                    console.log(`User ${userEmail} is a4, D Judge for game ${game}`);
                    return 'a4d1';
                }else if (a4Email === userEmail && d2Email === userEmail) {
                    console.log(`User ${userEmail} is a4, D2 Judge for game ${game}`);
                    return 'a4d2'; ////////////d2/////////////////
                }else if (a4Email === userEmail && d3Email === userEmail) {
                    console.log(`User ${userEmail} is a4, D3 Judge for game ${game}`);
                    return 'a4d3'; ////////////d3/////////////////
                }else if (judge1Email === userEmail && a1Email === userEmail){
                    console.log(`User ${userEmail} is judge1, a1 for game ${game}`);
                    return 'j1a1';
                }
                else if (judge1Email === userEmail && a2Email === userEmail){
                    console.log(`User ${userEmail} is judge1, a2 for game ${game}`);
                    return 'j1a2'; 
                }
                else if (judge1Email === userEmail && a3Email === userEmail){
                    console.log(`User ${userEmail} is judge1, a3 for game ${game}`);
                    return 'j1a3';
                }
                else if (judge1Email === userEmail && a4Email === userEmail){
                    console.log(`User ${userEmail} is judge1, a4 for game ${game}`);
                    return 'j1a4';
                }
                else if (judge2Email === userEmail && a1Email === userEmail){
                    console.log(`User ${userEmail} is judge2, a1 for game ${game}`);
                    return 'j2a1';
                }
                else if (judge2Email === userEmail && a2Email === userEmail){
                    console.log(`User ${userEmail} is judge2, a2 for game ${game}`);
                    return 'j2a2';
                }
                else if (judge2Email === userEmail && a3Email === userEmail){
                    console.log(`User ${userEmail} is judge2, a3 for game ${game}`);
                    return 'j2a3';
                }
                else if (judge2Email === userEmail && a4Email === userEmail){
                    console.log(`User ${userEmail} is judge2, a4 for game ${game}`);
                    return 'j2a4';
                }
                else if (judge3Email === userEmail && a1Email === userEmail){
                    console.log(`User ${userEmail} is judge3, a1 for game ${game}`);
                    return 'j3a1';
                }
                else if (judge3Email === userEmail && a2Email === userEmail){
                    console.log(`User ${userEmail} is judge3, a2 for game ${game}`);
                    return 'j3a2';
                }
                else if (judge3Email === userEmail && a3Email === userEmail){
                    console.log(`User ${userEmail} is judge3, a3 for game ${game}`);
                    return 'j3a3';
                }
                else if (judge3Email === userEmail && a4Email === userEmail){
                    console.log(`User ${userEmail} is judge3, a4 for game ${game}`);
                    return 'j3a4';
                }
                else if (judge4Email === userEmail && a1Email === userEmail){
                    console.log(`User ${userEmail} is judge4, a1 for game ${game}`);
                    return 'j4a1';
                }
                else if (judge4Email === userEmail && a2Email === userEmail){
                    console.log(`User ${userEmail} is judge4, a2 for game ${game}`);
                    return 'j4a2';
                }
                else if (judge4Email === userEmail && a3Email === userEmail){
                    console.log(`User ${userEmail} is judge4, a3 for game ${game}`);
                    return 'j4a3';
                }
                else if (judge4Email === userEmail && a4Email === userEmail){
                    console.log(`User ${userEmail} is judge4, a4 for game ${game}`);
                    return 'j4a4';
                } else if (judge1Email === userEmail) {
                    console.log(`User ${userEmail} is judge1 for game ${game}`);
                    return 'judge1';
                } else if (judge2Email === userEmail) {
                    console.log(`User ${userEmail} is judge2 for game ${game}`);
                    return 'judge2';
                } else if (judge3Email === userEmail) {
                    console.log(`User ${userEmail} is judge3 for game ${game}`);
                    return 'judge3';
                } else if (judge4Email === userEmail) {
                    console.log(`User ${userEmail} is judge4 for game ${game}`);
                    return 'judge4';
                } else if (d1Email === userEmail) {
                    console.log(`User ${userEmail} is D Judge for game ${game}`);
                    return 'd1';
                } else if (d2Email === userEmail) {
                    console.log(`User ${userEmail} is D2 Judge for game ${game}`);
                    return 'd2';
                } else if (d3Email === userEmail) {
                    console.log(`User ${userEmail} is D3 Judge for game ${game}`);
                    return 'd3';
                } else if (a1Email === userEmail) {
                    console.log(`User ${userEmail} is a1 for game ${game}`);
                    return 'a1';
                } else if (a2Email === userEmail) {
                    console.log(`User ${userEmail} is a2 for game ${game}`);
                    return 'a2';
                } else if (a3Email === userEmail) {
                    console.log(`User ${userEmail} is a3 for game ${game}`);
                    return 'a3';
                } else if (a4Email === userEmail) {
                    console.log(`User ${userEmail} is a4 for game ${game}`);
                    return 'a4';
                }
                
    
            }
        }
        console.log(`User ${userEmail} is not judge1 or judge2 or judge3 or judge4 for game ${game}. Assigning as New judge.`);
        return 'newjudge';
    };

    

    const extractEmail = (nameString) => {
        const emailMatch = nameString.match(/\(([^)]+)\)/);
        return emailMatch ? emailMatch[1] : null;
    };

    
    const handleScoreSubmit = async (judgeRole) => {
        try {
            const updatedEvent = { ...event };
            console.log("Updated Event:", updatedEvent);
    
            if (!updatedEvent.teamName) {
                console.error('No team for this game');
                return;
            }
    
            // Check if the team exists
            const isTeamFound = updatedEvent.teamName === teamName;
            if (!isTeamFound) {
                console.error('Team not found');
                return;
            }

            // Get the player's current scores and edit counts
            const playerData = updatedEvent.data;
            const originalScores = { ...playerData.scores };
            const updatedScores = { ...originalScores };
            const originalEditCount = { ...playerData.editCount };
            const updatedEditCount = { ...originalEditCount };

            // Update scores and edit counts for the given judge role
            const changes = {}; // Track changes in scores
            const editCountChanges = {};

            // Assign scores based on the judge role
    
             // Add scores for the relevant judge
             if (['judge1', 'all', 'j1234', 'j123d1', 'j124d1', 'j134d1', 'j123', 'j124', 'j12d1', 'j134', 'j13d1', 'j14d1', 'j12', 'j13', 'j14', 'j1d1', 'j1a1','j1a2', 'j1a3', 'j1a4' ].includes(judgeRole)) {
                const newScore = parseFloat(score).toFixed(3);
                if (originalScores.judge1 !== newScore) {
                    updatedScores.judge1 = newScore;
                    updatedEditCount.judge1 = (updatedEditCount.judge1 || 0) + 1;
                    changes.judge1 = newScore; // Record change
                    editCountChanges.judge1 = updatedEditCount.judge1;

                }
    
                setEditCountJudge1(updatedEditCount.judge1);
                if (updatedEditCount.judge1 >= 2) {
                    setIsScoreBtnDisabledJudge1(true);
                }
    
                addNotification(
                    `${selectedPlayer.name} was scored by the E1 judge in the ${game} apparatus of the ${updatedEvent.eventName} competition`,
                    eventId,
                    game,
                    updatedEvent.eventName
                );
            }
    
            // Judge2 score assignment
            if (['judge2', 'all', 'j1234', 'j123d1', 'j124d1', 'j234d1', 'j123', 'j124', 'j12d1', 'j234', 'j23d1', 'j24d1', 'j12', 'j23', 'j24', 'j2d1', 'j2a1', 'j2a2', 'j2a3', 'j2a4'].includes(judgeRole)) {
                const newScore = parseFloat(score).toFixed(3);
                if(originalScores.judge2 !== newScore){
                    updatedScores.judge2 = newScore;
                    updatedEditCount.judge2 = (updatedEditCount.judge2 || 0) + 1;
                    changes.judge2 = newScore;
                    editCountChanges.judge2 = updatedEditCount.judge2;
                }
    
                setEditCount(updatedEditCount.judge2);
                if (updatedEditCount.judge2 >= 2) {
                    setIsScoreBtnDisabled(true);
                }
    
                addNotification(
                    `${selectedPlayer.name} was scored by the E2 judge in the ${game} apparatus of the ${updatedEvent.eventName} competition`,
                    eventId,
                    game,
                    updatedEvent.eventName
                );
            }
    
            // Judge3 score assignment
            if (['judge3', 'all', 'j1234', 'j123d1', 'j134d1', 'j234d1', 'j123', 'j134', 'j13d1', 'j234', 'j23d1', 'j34d1', 'j13', 'j23', 'j34', 'j3d1', 'j3a1', 'j3a2', 'j3a3', 'j3a4'].includes(judgeRole)) {
                const newScore = parseFloat(score).toFixed(3);
                if (originalScores.judge3 !== newScore) {
                    updatedScores.judge3 = newScore;
                    updatedEditCount.judge3 = (updatedEditCount.judge3 || 0) + 1;
                    changes.judge3 = newScore; // Record change
                    editCountChanges.judge3 = updatedEditCount.judge3;
                }
    
                setEditCountJudge3(updatedEditCount.judge3);
                if (updatedEditCount.judge3 >= 2) {
                    setIsScoreBtnDisabledJudge3(true);
                }
    
                addNotification(
                    `${selectedPlayer.name} was scored by the E3 judge in the ${game} apparatus of the ${updatedEvent.eventName} competition`,
                    eventId,
                    game,
                    updatedEvent.eventName
                );
            }
    
            // Judge4 score assignment
            if (['judge4', 'all', 'j1234', 'j124d1', 'j134d1', 'j234d1', 'j124', 'j134', 'j14d1', 'j234', 'j24d1', 'j34d1', 'j14', 'j24', 'j34', 'j4d1', 'j4a1', 'j4a2', 'j4a3', 'j4a4'].includes(judgeRole)) {
                const newScore = parseFloat(score).toFixed(3);
                if (originalScores.judge4 !== newScore) {
                    updatedScores.judge4 = newScore;
                    updatedEditCount.judge4 = (updatedEditCount.judge4 || 0) + 1;
                    changes.judge4 = newScore; // Record change
                    editCountChanges.judge4 = updatedEditCount.judge4;
                }
    
                setEditCountJudge4(updatedEditCount.judge4);
                if (updatedEditCount.judge4 >= 2) {
                    setIsScoreBtnDisabledJudge4(true);
                }
    
                addNotification(
                    `${selectedPlayer.name} was scored by the E4 judge in the ${game} apparatus of the ${updatedEvent.eventName} competition`,
                    eventId,
                    game,
                    updatedEvent.eventName
                );
            }

            if (['a1','all','a1234','a123d1','a124d1','a134d1','a123','a124','a12d1','a134','a13d1','a14d1','a12','a13','a14','a1d1',"j1a1","j2a1","j3a1","j4a1", ].includes(judgeRole)) {
                const newScore = parseFloat(score).toFixed(3);
                if (originalScores.a1 !== newScore) {
                    updatedScores.a1 = newScore;
                    updatedEditCount.a1 = (updatedEditCount.a1 || 0) + 1;
                    changes.a1 = newScore; // Record change
                    editCountChanges.a1 = updatedEditCount.a1;
                }
    
                setEditCountA1(updatedEditCount.a1);
                if (updatedEditCount.a1 >= 2) {
                    setIsScoreBtnDisabledA1(true);
                }
    
                addNotification(
                    `${selectedPlayer.name} was scored by the A1 judge in the ${game} apparatus of the ${updatedEvent.eventName} competition`,
                    eventId,
                    game,
                    updatedEvent.eventName
                );
            }
    
            // A2 score assignment
            if (['a2','all','a1234','a123d1','a124d1','a234d1','a123','a124','a12d1','a234','a23d1','a24d1','a12','a23','a24','a2d1',"j1a2","j2a2","j3a2","j4a2"].includes(judgeRole)) {
                const newScore = parseFloat(score).toFixed(3);
                if (originalScores.a2 !== newScore) {
                    updatedScores.a2 = newScore;
                    updatedEditCount.a2 = (updatedEditCount.a2 || 0) + 1;
                    changes.a2 = newScore; // Record change
                    editCountChanges.a2 = updatedEditCount.a2;
                }
    
                setEditCountA2(updatedEditCount.a2);
                if (updatedEditCount.a2 >= 2) {
                    setIsScoreBtnDisabledA2(true);
                }
    
                addNotification(
                    `${selectedPlayer.name} was scored by the A2 judge in the ${game} apparatus of the ${updatedEvent.eventName} competition`,
                    eventId,
                    game,
                    updatedEvent.eventName
                );}
    
            // A3 score assignment
            if (['a3','all','a1234','a123d1','a134d1','a234d1','a123','a134','a13d1','a234','a23d1','a34d1','a13','a23','a34','a3d1',"j1a3","j2a3","j3a3","j4a3"].includes(judgeRole)) {
                const newScore = parseFloat(score).toFixed(3);
                if (originalScores.a3 !== newScore) {
                    updatedScores.a3 = newScore;
                    updatedEditCount.a3 = (updatedEditCount.a3 || 0) + 1;
                    changes.a3 = newScore; // Record change
                    editCountChanges.a3 = updatedEditCount.a3;
                }
    
                setEditCountA3(updatedEditCount.a3);
                if (updatedEditCount.a3 >= 2) {
                    setIsScoreBtnDisabledA3(true);
                }
    
                addNotification(
                    `${selectedPlayer.name} was scored by the A3 judge in the ${game} apparatus of the ${updatedEvent.eventName} competition`,
                    eventId,
                    game,
                    updatedEvent.eventName
                );
            }
    
            // A4 score assignment
            if (['a4','all','a1234','a124d1','a134d1','a234d1','a14d1','a124','a134','a234','a24d1','a34d1','a14','a24','a34','a4d1',"j1a4","j2a4","j3a4","j4a4"].includes(judgeRole)) {
                const newScore = parseFloat(score).toFixed(3);
                if (originalScores.a4 !== newScore) {
                    updatedScores.a4 = newScore;
                    updatedEditCount.a4 = (updatedEditCount.a4 || 0) + 1;
                    changes.a4 = newScore; // Record change
                    editCountChanges.a4 = updatedEditCount.a4;
                }
    
                setEditCountA4(updatedEditCount.a4);
                if (updatedEditCount.a4 >= 2) {
                    setIsScoreBtnDisabledA4(true);
                }
    
                addNotification(
                    `${selectedPlayer.name} was scored by the A4 judge in the ${game} apparatus of the ${updatedEvent.eventName} competition`,
                    eventId,
                    game,
                    updatedEvent.eventName
                );
            }

            // Add scores for the relevant judge
            if (['d2', 'all','j123d2','j124d2','j124d2','j234d2','j12d2','j13d2','j14d2','j23d2','j24d2','j34d2','j1d2','j2d2','j3d2','j4d2',"a1d2","a2d2","a3d2","a4d2","d1d2","d123" ].includes(judgeRole)) {
                const newScore = parseFloat(score).toFixed(3);
                if (originalScores.d2 !== newScore) {
                    updatedScores.d2 = newScore;
                    updatedEditCount.d2 = (updatedEditCount.d2 || 0) + 1;
                    changes.d2 = newScore; // Record change
                    editCountChanges.d2 = updatedEditCount.d2;
                }
    
                setEditCountD2(updatedEditCount.d2);
                if (updatedEditCount.d2 >= 2) {
                    setIsScoreBtnDisabledD2(true);
                }
    
                addNotification(
                    `${selectedPlayer.name} was scored by the D2 judge in the ${game} apparatus of the ${updatedEvent.eventName} competition`,
                    eventId,
                    game,
                    updatedEvent.eventName
                );
            }

            // Add scores for the relevant judge
if (['d3', 'all', 'j123d3', 'j124d3', 'j234d3', 'j12d3', 'j13d3', 'j14d3', 'j23d3', 'j24d3', 'j34d3', 'j1d3', 'j2d3', 'j3d3', 'j4d3', "a1d3", "a2d3", "a3d3", "a4d3", "d1d3", "d2d3", "d123"].includes(judgeRole)) {
    // Ensure individual values are provided for each field
    const newD3 = parseFloat(score).toFixed(3); // Assume `d3` is the input for d3 score
    const newPenalty = parseFloat(penalty).toFixed(3); // Assume `penalty` is the input for penalty score
    const newDividedBy = parseFloat(dividedby).toFixed(3); // Assume `dividedby` is the input for dividedby score

    // Update d3 if it has changed
    if (originalScores.d3 !== newD3) {
        updatedScores.d3 = newD3;
        updatedEditCount.d3 = (updatedEditCount.d3 || 0) + 1;
        changes.d3 = newD3; // Record change
        editCountChanges.d3 = updatedEditCount.d3;
    }

    // Update penalty if it has changed
    if (originalScores.penalty !== newPenalty) {
        updatedScores.penalty = newPenalty;
        // updatedEditCount.d3 = (updatedEditCount.d3 || 0) + 1;
        changes.penalty = newPenalty; // Record change
    }

    // Update dividedby if it has changed
    if (originalScores.dividedby !== newDividedBy) {
        updatedScores.dividedby = newDividedBy;
        // updatedEditCount.d3 = (updatedEditCount.d3 || 0) + 1;
        changes.dividedby = newDividedBy; // Record change
    }

    setEditCountD3(updatedEditCount.d3);
    if (updatedEditCount.d3 >= 2) {
        setIsScoreBtnDisabledD3(true);
    }

    addNotification(
        `${selectedPlayer.name} was scored by the Difficulty judge 1 in the ${game} apparatus of the ${updatedEvent.eventName} competition`,
        eventId,
        game,
        updatedEvent.eventName
    );
}

            // Submit only updated scores
            if (Object.keys(changes).length === 0 && Object.keys(editCountChanges).length === 0) {
                console.error("No changes detected");
                return;
            }
    
            // API call to update scores
            const payload = {};
            if (Object.keys(changes).length > 0) payload.scores = changes;
            if (Object.keys(editCountChanges).length > 0) payload.editCount = editCountChanges;
            const response = await fetch(
                `${API_URL}/api/events-aerobic-live/${eventId}/${teamName}/${game}/jte/${userRole}`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                }
            );
    
            // Update the local state and close the modal
            // Update the local state
            updatedEvent.data.scores = updatedScores;
            updatedEvent.data.editCount = updatedEditCount;
            setEvent(updatedEvent);
            closeModal();
    
            // Add notification for the update
            // addNotification(
            //     `${teamName} was scored by ${judgeRole} in ${game} apparatus of ${updatedEvent.eventName} competition`,
            //     eventId,
            //     game,
            //     updatedEvent.eventName
            // );
    
            console.log('Score updated successfully');
        } catch (error) {
            console.error('Error updating score:', error);
        }
    };
    
    



    const handleJudge1ScoreSubmit = () => handleScoreSubmit('judge1');
    const handleJudge2ScoreSubmit = () => handleScoreSubmit('judge2');
    const handleJudge3ScoreSubmit = () => handleScoreSubmit('judge3');
    const handleJudge4ScoreSubmit = () => handleScoreSubmit('judge4');
    const handleA1ScoreSubmit = () => handleScoreSubmit('a1');
    const handleA2ScoreSubmit = () => handleScoreSubmit('a2');
    const handleA3ScoreSubmit = () => handleScoreSubmit('a3');
    const handleA4ScoreSubmit = () => handleScoreSubmit('a4');
    const handleD2ScoreSubmit = () => handleScoreSubmit('d2');
    const handleD3ScoreSubmit = () => handleScoreSubmit('d3');


    // const handleD1ScoreSubmit = async () => {
    //     try {
    //         // Clone the current event state
    //         const updatedEvent = { ...event };
    //         console.log("updated event", updatedEvent)
    //         const eventName = updatedEvent.eventName;

    //         // Check if players for this game exist
    //         if (!updatedEvent.teamName) {
    //             console.error('No team for this game');
    //             return;
    //         }

    //         // Find the index of the selected player in this game
    //         const playerIndex = updatedEvent.teamName === teamName;
    //         if (playerIndex !== true) {
    //             console.error('Pair not found');
    //             return;
    //         }

    //         // Initialize scores and editCount if not already present
    //         // updatedEvent.data.scores = updatedEvent.data.scores || {};
    //         // updatedEvent.data.editCount = updatedEvent.data.editCount || {};
    //          // Get the player's current scores and edit counts
    //          const playerData = updatedEvent.data;
    //          const originalScores = { ...playerData.scores };
    //          const updatedScores = { ...originalScores };
    //          const updatedEditCount = { ...playerData.editCount };

    //          const changes = {};

    //         const newScore = parseFloat(cjp).toFixed(3);
    //         if (originalScores.cjp !== newScore) {
    //             updatedScores.cjp = newScore;
    //             updatedEditCount.d1 = (updatedEditCount.d1 || 0) + 1;
    //             changes.cjp = newScore; // Record change

    //         }
            
    //         // Update local state
    //         setEditCountD1(updatedEditCount.d1);
    //         if (updatedEditCount.d1 >= 2) {
    //             setIsScoreBtnDisabledD1(true);
    //         }

    //         // Prepare the data to be sent to the backend
    //         // const updatedPlayerData = {
    //         //     scores: updatedEvent.data.scores,
    //         //     editCount: updatedEvent.data.editCount
    //         // };

    //         // // Send the updated player data to the backend
    //         // await axios.patch(`${API_URL}/${eventId}/${teamName}/${game}/jte/cjp`, updatedPlayerData);

    //         // Submit only updated scores
    //         if (Object.keys(changes).length === 0) {
    //             console.error("No changes detected");
    //             return;
    //         }
    
    //         // API call to update scores
    //         const payload = { scores: changes, editCount: { ...updatedEditCount }, };
    //         const response = await fetch(
    //             `${API_URL}/${eventId}/${teamName}/${game}/jte/cjp`,
    //             {
    //                 method: "PATCH",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //                 body: JSON.stringify(payload),
    //             }
    //         );

    //         // Add notification
    //         addNotification(`${selectedPlayer.name} was scored by the CJP judge in the ${game} apparatus of the ${eventName} competition`, eventId, game, eventName);

    //         // Close modal after successful submission
    //         updatedEvent.data.scores = updatedScores;
    //         updatedEvent.data.editCount = updatedEditCount;
    //         setEvent(updatedEvent);
    //         closeModal();
    //     } catch (error) {
    //         console.error('Error updating score:', error);
    //     }
    // };


    const handleD1ScoreSubmit = async () => {
        try {
            // Clone the current event state
            const updatedEvent = { ...event };
            console.log("updated event", updatedEvent);
            const eventName = updatedEvent.eventName;
    
            // Check if players for this game exist
            if (!updatedEvent.teamName) {
                console.error('No team for this game');
                return;
            }
    
            // Check if the team exists
            const playerIndex = updatedEvent.teamName === teamName;
            if (playerIndex !== true) {
                console.error('Pair not found');
                return;
            }
    
            // Get the player's current scores and edit counts
            const playerData = updatedEvent.data;
            const originalScores = { ...playerData.scores };
            const updatedScores = { ...originalScores };
            const originalEditCount = { ...playerData.editCount };
            const updatedEditCount = { ...originalEditCount };
    
            // Track changes for scores and edit counts
            const scoreChanges = {};
            const editCountChanges = {};
    
            // Example: Update score for CJP
            const newScore = parseFloat(cjp).toFixed(3);
            if (originalScores.cjp !== newScore) {
                updatedScores.cjp = newScore;
                updatedEditCount.d1 = (updatedEditCount.d1 || 0) + 1;
    
                // Record changes
                scoreChanges.cjp = newScore;
                editCountChanges.d1 = updatedEditCount.d1;
            }
    
            // Update local state
            setEditCountD1(updatedEditCount.d1);
            if (updatedEditCount.d1 >= 2) {
                setIsScoreBtnDisabledD1(true);
            }
    
            // Check if there are changes to submit
            if (Object.keys(scoreChanges).length === 0 && Object.keys(editCountChanges).length === 0) {
                console.error("No changes detected");
                return;
            }
    
            // Prepare the payload with only updated fields
            const payload = {};
            if (Object.keys(scoreChanges).length > 0) payload.scores = scoreChanges;
            if (Object.keys(editCountChanges).length > 0) payload.editCount = editCountChanges;
    
            // API call to update scores and edit counts
            const response = await fetch(
                `${API_URL}/api/events-aerobic-live/${eventId}/${teamName}/${game}/jte/cjp`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                }
            );
    
            if (!response.ok) {
                throw new Error("Failed to update scores");
            }
    
            // Add notification
            addNotification(
                `${selectedPlayer.name} was scored by the CJP judge in the ${game} apparatus of the ${eventName} competition`,
                eventId,
                game,
                eventName
            );
    
            // Update local event state
            updatedEvent.data.scores = updatedScores;
            updatedEvent.data.editCount = updatedEditCount;
            setEvent(updatedEvent);
    
            // Close modal after successful submission
            closeModal();
        } catch (error) {
            console.error('Error updating score:', error);
        }
    };
    

    const handleEScoreSubmit = async ({ judgeToEdit }) => {
        try {
            const updatedEvent = { ...event };
            console.log("updated event", updatedEvent);
    
            if (!updatedEvent.teamName) {
                console.error('No team for this game');
                return;
            }
    
            // Find the player's index in the selected game
            const playerIndex = updatedEvent.teamName === teamName;
            if (playerIndex !== true) {
                console.error('Pair not found');
                return;
            }
    
            // Prepare the updated score for the judge
            const updatedScore = {
                [judgeToEdit]: parseFloat(score).toFixed(3) // Include only the updated score
            };
    
            // Prepare the updated editCountDjudge for the judge
            const updatedEditCount = {
                [judgeToEdit]: (updatedEvent.data.editCountDjudge[judgeToEdit] || 0) + 1
            };
    
            // Prepare data for backend update
            const updateData = {
                scores: updatedScore, // Only the updated score is sent here
                editCountDjudge: updatedEditCount // Only the updated edit count is sent here
            };
    
            // Make the PATCH request to update the player's score in the specific sport
            await axios.patch(
                `${API_URL}/api/events-aerobic-live/${eventId}/${teamName}/${game}/jte/${judgeToEdit}`,
                updateData
            );
    
            // Update the local event state with the new scores and edit count
            updatedEvent.data.scores = {
                ...updatedEvent.data.scores,
                ...updatedScore // Merge the updated score into the local state
            };
            updatedEvent.data.editCountDjudge = {
                ...updatedEvent.data.editCountDjudge,
                ...updatedEditCount // Merge the updated edit count into the local state
            };
    
            setEvent(updatedEvent);
    
            // Update edit counts for the specific judge
            setEditCounts(prevCounts => ({
                ...prevCounts,
                [judgeToEdit]: prevCounts[judgeToEdit] + 1
            }));
    
            closeModal(); // Close the modal after the update
        } catch (error) {
            console.error('Error updating score:', error);
        }
    };
    
    

    // const handleEScoreSubmit = async ({judgeToEdit}) => {
    //     try {
    //         const updatedEvent = { ...event };
    //         console.log("updated event", updatedEvent)
    //         if (!updatedEvent.teamName) {
    //             console.error('No team for this game');
    //             return;
    //         }

    //         // Find the player's index in the selected game
    //         const playerIndex = updatedEvent.teamName === teamName;
    //         if (playerIndex !== true) {
    //             console.error('Pair not found');
    //             return;
    //         }

    //         // Prepare the updated score for the judge
    //         const updatedScores = {
    //             ...updatedEvent.data.scores,
    //             [judgeToEdit]: parseFloat(score).toFixed(3) 
    //         };

    //         const updatedEditCountDjudge = {
    //             ...updatedEvent.data.editCountDjudge,
    //             [judgeToEdit]: (updatedEvent.data.editCountDjudge[judgeToEdit] || 0) + 1
    //         };

    //         // Prepare data for backend update
    //         const updateData = {
    //             scores: updatedScores, // only the scores object is needed as per the PATCH route
    //             editCountDjudge: updatedEditCountDjudge // updated edit count for the specific judge
    //         };

    //         // Make the PATCH request to update the player's score in the specific sport
    //         await axios.patch(
    //             `${API_URL}/${eventId}/${teamName}/${game}/jte/${judgeToEdit}`,
    //             updateData
    //         );
    //         // Update the local event state with the new scores
    //         updatedEvent.data.scores = updatedScores;
    //         updatedEvent.data.editCountDjudge[judgeToEdit] =
    //             (updatedEvent.data.editCountDjudge[judgeToEdit] || 0) + 1;

    //         setEvent(updatedEvent);

    //         // Update edit counts for the specific judge
    //         setEditCounts(prevCounts => ({
    //             ...prevCounts,
    //             [judgeToEdit]: prevCounts[judgeToEdit] + 1
    //         }));

    //         closeModal(); // Close the modal after the update
    //     } catch (error) {
    //         console.error('Error updating score:', error);
    //     }
    // };

    const calculateScoreWithTolerance = (scores) => {
        if (!scores || !Array.isArray(scores) || scores.length <= 2) {
            return 0; // Not enough scores to calculate an average
        }
    
        // Sort the scores
        scores.sort((a, b) => a - b);
    
        // Remove the highest and lowest scores
        const filteredScores = scores.slice(1, -1);
    
        // Calculate the average of the remaining scores
        const avgScore = filteredScores.reduce((acc, score) => acc + score, 0) / filteredScores.length;
    
        // Determine the allowed tolerance based on the average score
        let allowedTolerance = 0.3; // Default
        if (avgScore >= 8.0 && avgScore <= 10.0) {
            allowedTolerance = 0.3;
        } else if (avgScore >= 7.0 && avgScore < 7.99) {
            allowedTolerance = 0.4;
        } else if (avgScore >= 6.0 && avgScore < 6.99) {
            allowedTolerance = 0.5;
        } else if (avgScore >= 0.0 && avgScore < 5.99) {
            allowedTolerance = 0.6;
        }
    
        // Calculate the gap (tolerance) between the two remaining scores
        const gap = Math.abs(filteredScores[0] - filteredScores[1]);
    
        // If the gap exceeds allowed tolerance, recalculate the average using all scores
        if (gap > allowedTolerance) {
            const totalScore = scores.reduce((acc, score) => acc + score, 0);
            return (totalScore / scores.length).toFixed(3);
        }
    
        // Return the average of the filtered scores if within tolerance
        return avgScore.toFixed(3);
    };

    const approve = async () => {
        try {
            const updatedEvent = { ...event };

            if (!updatedEvent.teamName) {
                console.error('No team for this game');
                return;
            }

            // Find the player index
            const playerIndex = updatedEvent.teamName === teamName;

            // Check if player exists
            if (playerIndex !== true) {
                console.error('Pair not found');
                return;
            }

            // Calculate the average score using the calculateAverage function
            const averageScore = calculateScoreWithTolerance([
                Number(updatedEvent.data.scores.judge1),
                Number(updatedEvent.data.scores.judge2),
                Number(updatedEvent.data.scores.judge3),
                Number(updatedEvent.data.scores.judge4)
            ].filter(score => !isNaN(score)));

            console.log("E Score:", averageScore);

            // Calculate the average score using the calculateAverage function
            const averageScoreOfAScore = calculateScoreWithTolerance([
                Number(updatedEvent.data.scores.a1),
                Number(updatedEvent.data.scores.a2),
                Number(updatedEvent.data.scores.a3),
                Number(updatedEvent.data.scores.a4)
            ].filter(score => !isNaN(score)));

            console.log("Average Score of A:", averageScoreOfAScore);

            const dScore = (
                (Number(updatedEvent.data.scores.d3) +
                Number(updatedEvent.data.scores.d2)) / 
                Number(updatedEvent.data.scores.dividedby)
            ).toFixed(3);

            const result = (
                Number(averageScore) +
                Number(averageScoreOfAScore) +
                Number(dScore) -
                Number(updatedEvent.data.scores.d1) -
                Number(updatedEvent.data.scores.penalty)
            ).toFixed(3);

            console.log("Total Result:", result);

            // Prepare the updated player data
            const updatedPlayerData = {
                scores: {
                    ...updatedEvent.data.scores,
                    e: Number(averageScore),
                    a: Number(averageScoreOfAScore),
                    d: Number(dScore),
                    total: Number(result)
                },
                approve: true,
                status: 'completed'
            };

            // Update the player in the backend using the specific API
            await axios.patch(`${API_URL}/api/events-aerobic-live/${eventId}/${teamName}/${game}/jte/approve`, updatedPlayerData);

            // Update the local state
            updatedEvent.data.scores = updatedPlayerData.scores;
            updatedEvent.data.approve = true;
            updatedEvent.data.status = 'completed';

            setEvent(updatedEvent);
            closeModal();
        } catch (error) {
            console.error('Error approving player:', error);
        }
    };


    const handleMouseEnter = judge => {
        setIsHoveredE(prev => ({ ...prev, [judge]: true }));
    };

    const handleMouseLeave = judge => {
        setIsHoveredE(prev => ({ ...prev, [judge]: false }));
    };

    

    if (loading) {
        return <Spinner />;
    }
    if (!event) {
        return <Text>Event not found</Text>;
    }


    const gamePlayers = game && event.players ? event.players[game] : [];
    const userRole = getUserRoleForGame(event.judges, game);
    console.log("userole", userRole);
    const totalEditJudge1 = 2 - editCountJudge1;
    const totalEdit = 2 - editCount;
    const totalEditJudge3 = 2 - editCountJudge3;
    const totalEditJudge4 = 2 - editCountJudge4;
    const totalEditD1 = 2 - editCountD1;
    const totalEditD2 = 2 - editCountD2;
    const totalEditD3 = 2 - editCountD3;
    const totalEditA1 = 2 - editCountA1;
    const totalEditA2 = 2 - editCountA2;
    const totalEditA3 = 2 - editCountA3;
    const totalEditA4 = 2 - editCountA4;

    return (
        <Box p={4} minHeight={"90vh"} bg={"gray"}>
            <Box w={"95%"} background={"gray.700"} color={"white"} boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" m={"auto"} borderRadius={"20px"}>
                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box m={4} ml={"50px"}>
                        <Text fontSize="lg"><b>Competition Name:</b> {event.eventName}</Text>
                        <Text fontSize="lg"><b>Date:</b> {new Date(event.dateTime).toLocaleString()}</Text>
                        {/* <Text fontSize="lg"><b>Appratus:</b> {game}</Text> */}

                    </Box>
                    <Box m={4} ml={{ base: "50px", md: "50px", lg: "0px" }} mr={"50px"}>
                    <Text fontSize="lg"><b>Competition Level:</b> {event.competitionLevel}</Text>
                        <Text fontSize="lg"><b>Event:</b> {game}</Text>
                        
                    </Box>

                </Flex>

            </Box>
            <VStack align="start" mt={4}>
            {(userRole === 'd1' || userRole === 'all' || userRole === 'j123d1' || userRole === 'j124d1' || userRole === 'j124d1' || userRole === 'j234d1' || userRole === 'j12d1' || userRole === 'j13d1' || userRole === 'j14d1' || userRole === 'j23d1' || userRole === 'j24d1' || userRole === 'j34d1' || userRole === 'j1d1' || userRole === 'j2d1' || userRole === 'j3d1' || userRole === 'j4d1' || userRole === "a1d1" || userRole === "a2d1" || userRole === "a3d1" || userRole === "a4d1" || userRole === "d1d2" || userRole === "d1d3" || userRole === "d123") && (
                    <>
                        <Box w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="22px" fontWeight={"bold"} color={"white"} textAlign={"center"}>CJP Judge for {game}</Text>
                        </Box>
                        <br/>
                        <Box w={"90%"} m={"auto"}>
                            <Text fontWeight={"bold"} fontSize={"22px"} color={"white"}>Team : {teamName}</Text>
                        <PlayerGrid eventData={event.data} />;
                        </Box>
                        <Box w={"100%"} >
                            <List w={"100%"} spacing={3}>
                                {player && (
                                    <Flex bg={"white"} borderRadius={"20px"} flex={1} border={"1px solid black"} flexWrap={"wrap"} gap={"1%"} w={"90%"} m={"auto"} p={"30px"}>
                                        <ListItem key={teamName} borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"} w={{ base: "80%", md: "100%", lg: "48%" }} textAlign={"start"} >
                                            <Text m={"auto"} fontWeight={"bold"} fontSize={"22px"}>Team name: {teamName}</Text>
                                            
                                            <Text fontSize={"20px"}><b>Status:</b> {player.status}</Text>
                                            <Text fontSize={"20px"}><b>Final A Score:</b> {calculateScoreWithTolerance([
                                                Number(player.scores.a1),
                                                Number(player.scores.a2),
                                                Number(player.scores.a3),
                                                Number(player.scores.a4)
                                            ].filter(score => score !== undefined))}</Text>

                                    <Text fontSize={"20px"}><b>Final E Score:</b> {calculateScoreWithTolerance([
                                        Number(player.scores.judge1),
                                        Number(player.scores.judge2),
                                        Number(player.scores.judge3),
                                        Number(player.scores.judge4)
                                    ].filter(score => score !== undefined))}</Text>

                                    {/* <Text fontSize={"20px"}><b>Final D1 Score:</b>{DAvg([player.scores.d3, player.scores.d2, player.scores.dividedby])}</Text>
                                    <Text fontSize={"20px"}><b>Final D2 Score:</b> {player.scores.d2}</Text>
                                    <Text fontSize={"20px"}><b>Final D dived Score:</b> {player.scores.dividedby}</Text> */}
                                    <Text fontSize={"20px"}><b>Final D Score:</b> {((Number(player.scores.d3)+Number(player.scores.d2))/Number(player.scores.dividedby)).toFixed(3)}</Text>



                                            <Text fontSize={"20px"}><b>Penalty:</b> {player.scores.penalty || '0'} </Text>
                                            <Text fontSize={"20px"}><b>CJP Deduction:</b> {player.scores.cjp} </Text>
                                            <Text fontSize={"20px"}><b>Total Edit Count:</b> {totalEditD1} </Text>
                                            {/* <Text>Total Score: {player.scores.total ? player.scores.total.toFixed(3) : 'N/A'}</Text> */}
                                            <Text fontSize={"20px"}><b>Total Score:</b> {
                                (() => {
                                    // Parse scores to numbers, defaulting to 0 if not provided or not a number
                                    const dScore = parseFloat(((Number(player.scores.d3)+Number(player.scores.d2))/Number(player.scores.dividedby)).toFixed(3));
                                    // const dScore = parseFloat(player.scores.d1) || 0;
                                    const penalty = parseFloat(player.scores.penalty) || 0;
                                    const cjp = parseFloat(player.scores.cjp) || 0;

                                    // Calculate the average E score
                                    const eScores = [
                                        Number(player.scores.judge1),
                                        Number(player.scores.judge2),
                                        Number(player.scores.judge3),
                                        Number(player.scores.judge4)
                                    ].filter(score => score !== undefined).map(score => parseFloat(score));

                                    // Calculate the average E score
                                    const aScores = [
                                        Number(player.scores.a1),
                                        Number(player.scores.a2),
                                        Number(player.scores.a3),
                                        Number(player.scores.a4)
                                    ].filter(score => score !== undefined).map(score => parseFloat(score));


                                    const avgEScore = calculateScoreWithTolerance(eScores);
                                    const avgAScore = calculateScoreWithTolerance(aScores);

                                    // Debugging logs
                                    console.log("D Score: ", dScore);
                                    console.log("Average E Score: ", avgEScore);
                                    console.log("Average A Score: ", avgAScore);
                                    console.log("Penalty: ", penalty);

                                    // Calculate total score
                                    const totalScore = dScore + Number(avgAScore) + Number(avgEScore) - penalty - cjp;

                                    console.log("Total Score: ", totalScore);

                                    // Ensure the totalScore is a valid number
                                    return isNaN(totalScore) ? 'N/A' : totalScore.toFixed(3);
                                })()
                            }</Text>
                            <br/>
                                            <Box onMouseEnter={() => setIsHoveredD1(true)}
                                                onMouseLeave={() => setIsHoveredD1(false)}>
                                                {status === 'playing' && (
                                                    <Button
                                                        position={"absolute"}
                                                        isDisabled={isScoreBtnDisabledD1 || player.status === "completed"}
                                                        sx={{
                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                            color: "white",
                                                            _hover: {
                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                            },
                                                            _active: {
                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                            },
                                                        }} onClick={() => openD1Modal(player)}>Add CJP Deduction</Button>
                                                )}
                                            </Box>
                                            {isHoveredD1 && (
                                                <Flex alignItems={"center"} position={"relative"} gap={"10px"}
                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCountD1 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                    top={50}
                                                    right={50}
                                                >
                                                    <BsInfoCircleFill style={{ color: editCountD1 === 0 || totalEditD1 === 2 ? "white" : "" }} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditD1 === 2 ? "" : editCountD1 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditD1} time only`}
                                                </Flex>
                                            )}
                                            <br />
                                            <br />
                                            <Button mt={"10px"} mr={"10px"} border={"1px solid gray"} isDisabled={startBtnDisabled} onClick={() => handleStartPlaying(teamName)}><FaPowerOff style={{ marginRight: "10px" }} />Start Playing</Button>


                                            <Button isDisabled={player.approve === true} mt={"10px"} colorScheme='green' onClick={() => openApproveModal(player)} >{player.approve === true ? "Approved" : "Approve"}</Button>
                                            {/* <Button onClick={() => openScoreModal(player, 'd1')}>Add score as a D judge</Button> */}
                                        </ListItem>
                                        <ListItem ml={{ base: "0px", md: "0px", lg: "10%" }} minHeight={"380px"} borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"70px"} mt={{ base: "10px", md: "20px", lg: "0px" }} w={{ base: "80%", md: "100%", lg: "80%", xl: "40%" }} textAlign={"start"}>
                                            <Flex>
                                                <Box>
                                                    <Text fontSize={"18px"} mt={"8px"} mb={"20px"}>E1 score - {player.scores.judge1 || 'N/A'}</Text>
                                                    <Text fontSize={"18px"} mt={"32px"} mb={"20px"}>E2 score - {player.scores.judge2 || 'N/A'}</Text>
                                                    <Text fontSize={"18px"} mt={"32px"} mb={"20px"}>E3 score - {player.scores.judge3 || 'N/A'}</Text>
                                                    <Text fontSize={"18px"} mt={"32px"} mb={"20px"}>E4 score - {player.scores.judge4 || 'N/A'}</Text>
                                                </Box>
                                                <Box >
                                                    {['judge1', 'judge2', 'judge3', 'judge4'].map(judge => (
                                                        <Box key={judge} onMouseEnter={() => handleMouseEnter(judge)} onMouseLeave={() => handleMouseLeave(judge)}>

                                                            <Button
                                                                isDisabled={editCounts[judge] >= 1 || player.status === "completed"}
                                                                onClick={() => openScoreModal(player, judge)}
                                                                mb={"20px"}
                                                            >
                                                                <MdEdit style={{ fontSize: "22px", color: "green" }} />
                                                            </Button>
                                                            {isHoveredE[judge] && editCounts[judge] >= 1 ? <Text fontSize={"13px"} fontWeight={"bold"} mt={"-20px"} color="red">You have already edited this score once</Text> : <Text></Text>
                                                            }
                                                        </Box>
                                                    ))}

                                                </Box>

                                            </Flex>
                                            <br />

                                            <Flex>
                                                <Box>
                                                    <Text fontSize={"18px"} mt={"8px"} mb={"20px"}>A1 score - {player.scores.a1 || 'N/A'}</Text>
                                                    <Text fontSize={"18px"} mt={"32px"} mb={"20px"}>A2 score - {player.scores.a2 || 'N/A'}</Text>
                                                    <Text fontSize={"18px"} mt={"32px"} mb={"20px"}>A3 score - {player.scores.a3 || 'N/A'}</Text>
                                                    <Text fontSize={"18px"} mt={"32px"} mb={"20px"}>A4 score - {player.scores.a4 || 'N/A'}</Text>
                                                </Box>
                                                <Box >
                                                    {['a1', 'a2', 'a3', 'a4'].map(judge => (
                                                        <Box key={judge} onMouseEnter={() => handleMouseEnter(judge)} onMouseLeave={() => handleMouseLeave(judge)}>

                                                            <Button
                                                                isDisabled={editCounts[judge] >= 1 || player.status === "completed"}
                                                                onClick={() => openScoreModal(player, judge)}
                                                                mb={"20px"}
                                                            >
                                                                <MdEdit style={{ fontSize: "22px", color: "green" }} />
                                                            </Button>
                                                            {isHoveredE[judge] && editCounts[judge] >= 1 ? <Text fontSize={"13px"} fontWeight={"bold"} mt={"-20px"} color="red">You have already edited this score once</Text> : <Text></Text>
                                                            }
                                                        </Box>
                                                    ))}

                                                </Box>

                                            </Flex>

                                            <br/>
                                    <Flex>
                                                <Box>
                                                    <Text fontSize={"18px"} mt={"8px"} mb={"20px"}>D1 score - {player.scores.d3 || 'N/A'}</Text>
                                                    <Text fontSize={"18px"} mt={"32px"} mb={"20px"}>D2 score - {player.scores.d2 || 'N/A'}</Text>
                                                    <Text fontSize={"18px"} mt={"32px"} mb={"20px"}>Divided By - {player.scores.dividedby || 'N/A'}</Text>
                                                    <Text fontSize={"18px"} mt={"32px"} mb={"20px"}>Penalty - {player.scores.penalty || 'N/A'}</Text>
                                                </Box>
                                                <Box >
                                                    {['d3', 'd2','dividedby', 'penalty'].map(judge => (
                                                        <Box key={judge} onMouseEnter={() => handleMouseEnter(judge)} onMouseLeave={() => handleMouseLeave(judge)}>

                                                            <Button
                                                                isDisabled={editCounts[judge] >= 1 || player.status === "completed"}
                                                                onClick={() => openScoreModal(player, judge)}
                                                                mb={"20px"}
                                                            >
                                                                <MdEdit style={{ fontSize: "22px", color: "green" }} />
                                                            </Button>
                                                            {isHoveredE[judge] && editCounts[judge] >= 1 ? <Text fontSize={"13px"} fontWeight={"bold"} mt={"-20px"} color="red">You have already edited this score once</Text> : <Text></Text>
                                                            }
                                                        </Box>
                                                    ))}

                                                </Box>

                                    </Flex>


                                        </ListItem>
                                    </Flex>
                                )}
                            </List>
                        </Box>
                    </>
                )}
                {(userRole === 'd3' || userRole === 'all' || userRole === 'j123d3' || userRole === 'j124d3' || userRole === 'j124d3' || userRole === 'j234d3' || userRole === 'j12d3' || userRole === 'j13d3' || userRole === 'j14d3' || userRole === 'j23d3' || userRole === 'j24d3' || userRole === 'j34d3' || userRole === 'j1d3' || userRole === 'j2d3' || userRole === 'j3d3' || userRole === 'j4d3' || userRole === "a1d3" || userRole === "a2d3" || userRole === "a3d3" || userRole === "a4d3" || userRole === "d1d3" || userRole === "d2d3" || userRole === "d123" ) && (
                    <>
                        <Box  w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="22px" fontWeight={"bold"} color={"white"} textAlign={"center"}>Difficulty Judge (D1) for {game}</Text>
                        </Box>
                        <br/>
                        <Box w={"90%"} m={"auto"}>
                        <PlayerGrid eventData={event.data} />;
                        </Box>
                        <br/>
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{base:"400px", md:"300px", lg:"300px"}} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>
                        <List spacing={3}>
                            {player && (
                                <ListItem key={teamName}>
                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                    <Box  borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"}  w={{base:"80%", md:"50%", lg:"40%"}}  textAlign={"start"}>

                                        <Text fontSize={"lg"} fontWeight={"bold"} color={"#26263c"}>Team Name: {teamName}</Text>
                                        <Text fontSize={"lg"}><b>Given D1 Score: </b>{player.scores.d3}</Text>
                                        <Text fontSize={"lg"}><b>Given Penalty: </b>{player.scores.penalty}</Text>
                                        <Text fontSize={"lg"}><b>D score divisor: </b>{player.scores.dividedby}</Text>
                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                        {/* <Text>New Edit count: {newEditCountJudge1}</Text> */}
                                    </Box>
                                    <Box p={"20px"} w={{base:"51%", md:"34%",lg:"34%"}} >
                                        <Box ml={{base:"0%", md:"30%", lg:"30%"}}>

                                            <Box onMouseEnter={() => setIsHoveredD3(true)}
                                                onMouseLeave={() => setIsHoveredD3(false)}>
                                                {status === 'playing' && (
                                                    <Button
                                                        p={"25px"}
                                                        position={"absolute"}
                                                        onClick={() => openD3Modal(player)}
                                                        isDisabled={isScoreBtnDisabledD3 || player.status === "completed"}
                                                        sx={{
                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                            color: "white",
                                                            _hover: {
                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                            },
                                                            _active: {
                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                            },
                                                        }}

                                                    >
                                                        {editCountD3 === 0 || player.status === "completed" ? 'Add Difficulty 1 Score' : 'Edit Difficulty 1 Score'}
                                                    </Button>
                                                )}
                                            </Box>
                                            {isHoveredD3 && (
                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCountD3 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                    top={50}
                                                    right={50}
                                                >
                                                    <BsInfoCircleFill style={{color: editCountD3 === 0 || totalEditD3 === 2 ? "white":""}} />{player.status === "completed" ? "After approved by CJP judge you cannot edit score" : totalEditD3 === 2 ? "" :  editCountD3 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditD3} time only`}
                                                </Flex>
                                            )}

                                        </Box>
                                    </Box>



                                </Flex>


                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                            </ListItem>
                            )}
                        </List>
                        </Box>
                            </Box>

                        </Flex>
                    </>
                )}
                {(userRole === 'd2' || userRole === 'all' || userRole === 'j123d2' || userRole === 'j124d2' || userRole === 'j124d2' || userRole === 'j234d2' || userRole === 'j12d2' || userRole === 'j13d2' || userRole === 'j14d2' || userRole === 'j23d2' || userRole === 'j24d2' || userRole === 'j34d2' || userRole === 'j1d2' || userRole === 'j2d2' || userRole === 'j3d2' || userRole === 'j4d2' || userRole === "a1d2" || userRole === "a2d2" || userRole === "a3d2" || userRole === "a4d2" || userRole === "d1d2" || userRole === "d2d3" || userRole === "d123") && (
                    <>
                        <Box  w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="22px" fontWeight={"bold"} color={"white"} textAlign={"center"}>Difficulty Judge (D2) for {game}</Text>
                        </Box>
                        <br/>
                        <Box w={"90%"} m={"auto"}>
                        <PlayerGrid eventData={event.data} />;
                        </Box>
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{base:"400px", md:"300px", lg:"300px"}} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>
                        <List spacing={3}>
                            {player && (
                                <ListItem key={teamName}>
                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                    <Box  borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"}  w={{base:"80%", md:"50%", lg:"40%"}}  textAlign={"start"}>

                                        <Text fontSize={"lg"} fontWeight={"bold"} color={"#26263c"}>Team Name: {teamName}</Text>
                                        
                                        <Text fontSize={"lg"}><b>Given D2 Score: </b>{player.scores.d2}</Text>
                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                        {/* <Text>New Edit count: {newEditCountJudge1}</Text> */}
                                    </Box>
                                    <Box p={"20px"} w={{base:"51%", md:"34%",lg:"34%"}} >
                                        <Box ml={{base:"0%", md:"30%", lg:"30%"}}>

                                            <Box onMouseEnter={() => setIsHoveredD2(true)}
                                                onMouseLeave={() => setIsHoveredD2(false)}>
                                                {status === 'playing' && (
                                                    <Button
                                                        p={"25px"}
                                                        position={"absolute"}
                                                        onClick={() => openD2Modal(player)}
                                                        isDisabled={isScoreBtnDisabledD2 || player.status === "completed"}
                                                        sx={{
                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                            color: "white",
                                                            _hover: {
                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                            },
                                                            _active: {
                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                            },
                                                        }}

                                                    >
                                                        {editCountD2 === 0 || player.status === "completed" ? 'Add D2 Score' : 'Edit D2 Score'}
                                                    </Button>
                                                )}
                                            </Box>
                                            {isHoveredD2 && (
                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCountD2 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                    top={50}
                                                    right={50}
                                                >
                                                    <BsInfoCircleFill style={{color: editCountD2 === 0 || totalEditD2 === 2 ? "white":""}} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditD2 === 2 ? "" :  editCountD2 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditD2} time only`}
                                                </Flex>
                                            )}

                                        </Box>
                                    </Box>



                                </Flex>


                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                            </ListItem>
                            )}
                        </List>
                        </Box>
                            </Box>

                        </Flex>
                    </>
                )}
                {(userRole === 'judge1' || userRole === 'all' || userRole === 'j1234' || userRole === 'j123d1' || userRole === 'j124d1' || userRole === 'j134d1' || userRole === 'j123' || userRole === 'j124' || userRole === 'j12d1' || userRole === 'j134' || userRole === 'j13d1' || userRole === 'j14d1' || userRole === 'j12' || userRole === 'j13' || userRole === 'j14' || userRole === 'j1d1' || userRole === "j1a1" || userRole === "j1a2" || userRole === "j1a3" || userRole === "j1a4") && (
                    <>
                        <Box  w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="22px" fontWeight={"bold"} color={"white"} textAlign={"center"}>Execution Judge (E1) for {game}</Text>
                        </Box>
                        <br/>
                        <Box w={"90%"} m={"auto"}>
                        <PlayerGrid eventData={event.data} />;
                        </Box>
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{base:"400px", md:"300px", lg:"300px"}} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>
                        <List spacing={3}>
                            {player && (
                                <ListItem key={teamName}>
                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                    <Box  borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"}  w={{base:"80%", md:"50%", lg:"40%"}}  textAlign={"start"}>

                                        <Text fontSize={"lg"} fontWeight={"bold"} color={"#26263c"}>Team Name: {teamName}</Text>
                                        <Text fontSize={"lg"}><b>Given E1 Score: </b>{player.scores.judge1}</Text>
                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                        {/* <Text>New Edit count: {newEditCountJudge1}</Text> */}
                                    </Box>
                                    <Box p={"20px"} w={{base:"51%", md:"34%",lg:"34%"}} >
                                        <Box ml={{base:"0%", md:"30%", lg:"30%"}}>

                                            <Box onMouseEnter={() => setIsHoveredJudge1(true)}
                                                onMouseLeave={() => setIsHoveredJudge1(false)}>
                                                {status === 'playing' && (
                                                    <Button
                                                        p={"25px"}
                                                        position={"absolute"}
                                                        onClick={() => openJudge1Modal(player)}
                                                        isDisabled={isScoreBtnDisabledJudge1 || player.status === "completed"}
                                                        sx={{
                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                            color: "white",
                                                            _hover: {
                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                            },
                                                            _active: {
                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                            },
                                                        }}

                                                    >
                                                        {editCountJudge1 === 0 || player.status === "completed" ? 'Add E1 Score' : 'Edit E1 Score'}
                                                    </Button>
                                                )}
                                            </Box>
                                            {isHoveredJudge1 && (
                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCountJudge1 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                    top={50}
                                                    right={50}
                                                >
                                                    <BsInfoCircleFill style={{color: editCountJudge1 === 0 || totalEditJudge1 === 2 ? "white":""}} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditJudge1 === 2 ? "" :  editCountJudge1 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditJudge1} time only`}
                                                </Flex>
                                            )}

                                        </Box>
                                    </Box>



                                </Flex>


                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                            </ListItem>
                            )}
                        </List>
                        </Box>
                            </Box>

                        </Flex>
                    </>
                )}
                {(userRole === 'judge2' || userRole === 'all' || userRole === 'j1234' || userRole === 'j123d1' || userRole === 'j124d1' || userRole === 'j234d1' || userRole === 'j123' || userRole === 'j124' || userRole === 'j12d1' || userRole === 'j234' || userRole === 'j23d1' || userRole === 'j24d1' || userRole === 'j12' || userRole === 'j23' || userRole === 'j24' || userRole === 'j2d1' || userRole === "j2a1" || userRole === "j2a2" || userRole === "j2a3" || userRole === "j2a4") && (
                    <>
                        <Box w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="22px" fontWeight={"bold"} color={"white"} textAlign={"center"}>Execution Judge (E2) for {game}</Text>
                        </Box>
                        <br />
                        <Box w={"90%"} m={"auto"}>
                        <PlayerGrid eventData={event.data} />;
                        </Box>
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{base:"400px", md:"300px", lg:"300px"}} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>

                                    <List spacing={3} >
                                        {player && (
                                            <ListItem key={teamName}>
                                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                                    <Box  borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"}  w={{base:"80%", md:"50%", lg:"40%"}}  textAlign={"start"}>

                                                        <Text fontSize={"lg"} fontWeight={"bold"} color={"#26263c"}>Team Name: {teamName}</Text>
                                                        
                                                        <Text fontSize={"lg"}><b>Given E2 Score: </b>{player.scores.judge2}</Text>
                                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                                        {/* <Text>New Edit count: {newEditCount}</Text> */}
                                                    </Box>
                                                    <Box p={"20px"} w={{base:"51%", md:"34%",lg:"34%"}} >
                                                        
                                                        <Box ml={{base:"0%", md:"30%", lg:"30%"}}>

                                                            <Box onMouseEnter={() => setIsHovered(true)}
                                                                onMouseLeave={() => setIsHovered(false)}>
                                                                {status === 'playing' && (
                                                                    <Button
                                                                        p={"25px"}
                                                                        position={"absolute"}
                                                                        onClick={() => openJudge2Modal(player)}
                                                                        isDisabled={isScoreBtnDisabled || player.status === "completed"}
                                                                        sx={{
                                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                                            color: "white",
                                                                            _hover: {
                                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                                            },
                                                                            _active: {
                                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                                            },
                                                                        }}

                                                                    >
                                                                        {editCount === 0 || player.status === "completed" ? 'Add E2 Score' : 'Edit E2 Score'}
                                                                    </Button>
                                                                )}
                                                            </Box>
                                                            {isHovered && (
                                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCount === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                                    top={50}
                                                                    right={50}
                                                                >
                                                                    <BsInfoCircleFill style={{color: editCount === 0 || totalEdit === 2 ? "white":""}} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : editCount === 0 ? '' : editCount === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEdit} time only`}
                                                                </Flex>
                                                            )}

                                                        </Box>
                                                    </Box>



                                                </Flex>


                                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                                            </ListItem>
                                        )}
                                    </List>

                                </Box>
                            </Box>

                        </Flex>

                    </>
                )}
                {(userRole === 'judge3' || userRole === 'all' || userRole === 'j1234' || userRole === 'j123d1' || userRole === 'j134d1' || userRole === 'j234d1' || userRole === 'j123' || userRole === 'j134' || userRole === 'j13d1' || userRole === 'j234' || userRole === 'j23d1' || userRole === 'j34d1' || userRole === 'j13' || userRole === 'j23' || userRole === 'j34' || userRole === 'j3d1' || userRole === "j3a1" || userRole === "j3a2" || userRole === "j3a3" || userRole === "j3a4") && (
                    <>
                        <Box w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="22px" fontWeight={"bold"} color={"white"} textAlign={"center"}>Execution Judge (E3) for {game}</Text>
                        </Box>
                        <br/>
                        <Box w={"90%"} m={"auto"}>
                        <PlayerGrid eventData={event.data} />;
                        </Box>
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{base:"400px", md:"300px", lg:"300px"}} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>
                                <List spacing={3}>
                            {player && (
                                <ListItem key={teamName}>
                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                    <Box  borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"}  w={{base:"80%", md:"50%", lg:"40%"}}  textAlign={"start"}>

                                        <Text fontSize={"lg"} fontWeight={"bold"} color={"#26263c"}>Team Name: {teamName}</Text>
                                        <Text fontSize={"lg"}><b>Given E3 Score: </b>{player.scores.judge3}</Text>
                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                        {/* <Text>New Edit count: {newEditCountJudge1}</Text> */}
                                    </Box>
                                    <Box p={"20px"} w={{base:"51%", md:"34%",lg:"34%"}} >
                                        <Box ml={{base:"0%", md:"30%", lg:"30%"}}>

                                            <Box onMouseEnter={() => setIsHoveredJudge3(true)}
                                                onMouseLeave={() => setIsHoveredJudge3(false)}>
                                                {status === 'playing' && (
                                                    <Button
                                                        p={"25px"}
                                                        position={"absolute"}
                                                        onClick={() => openJudge3Modal(player)}
                                                        isDisabled={isScoreBtnDisabledJudge3 || player.status === "completed"}
                                                        sx={{
                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                            color: "white",
                                                            _hover: {
                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                            },
                                                            _active: {
                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                            },
                                                        }}

                                                    >
                                                        {editCountJudge3 === 0 || player.status === "completed" ? 'Add E3 Score' : 'Edit E3 Score'}
                                                    </Button>
                                                )}
                                            </Box>
                                            {isHoveredJudge3 && (
                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCountJudge3 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                    top={50}
                                                    right={50}
                                                >
                                                    <BsInfoCircleFill style={{color: editCountJudge3 === 0 || totalEditJudge3 === 2 ? "white":""}} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditJudge3 === 2 ? "" :  editCountJudge3 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditJudge3} time only`}
                                                </Flex>
                                            )}

                                        </Box>
                                    </Box>



                                </Flex>


                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                            </ListItem>
                            )}
                        </List>
                        </Box>
                            </Box>

                        </Flex>
                    </>
                )}
                {(userRole === 'judge4' || userRole === 'all' || userRole === 'j1234' || userRole === 'j124d1' || userRole === '134d1' || userRole === 'j234d1' || userRole === 'j14d1' || userRole === 'j124' || userRole === 'j134' || userRole === 'j234' || userRole === 'j24d1' || userRole === 'j34d1' || userRole === 'j14' || userRole === 'j24' || userRole === 'j34' || userRole === 'j4d1' || userRole === "j4a1" || userRole === "j4a2" || userRole === "j4a3" || userRole === "j4a4") && (
                    <>
                        <Box w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="22px" fontWeight={"bold"} color={"white"} textAlign={"center"}>Execution Judge (E4) for {game}</Text>
                        </Box>
                        <br/>
                        <Box w={"90%"} m={"auto"}>
                        <PlayerGrid eventData={event.data} />;
                        </Box>
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{base:"400px", md:"300px", lg:"300px"}} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>
                                <List spacing={3}>
                            {player && (
                                <ListItem key={teamName}>
                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                    <Box  borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"}  w={{base:"80%", md:"50%", lg:"40%"}}  textAlign={"start"}>

                                        <Text fontSize={"lg"} fontWeight={"bold"} color={"#26263c"}>Team Name: {teamName}</Text>
                                        <Text fontSize={"lg"}><b>Given E4 Score: </b>{player.scores.judge4}</Text>
                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                        {/* <Text>New Edit count: {newEditCountJudge1}</Text> */}
                                    </Box>
                                    <Box p={"20px"} w={{base:"51%", md:"34%",lg:"34%"}} >
                                        <Box ml={{base:"0%", md:"30%", lg:"30%"}}>

                                            <Box onMouseEnter={() => setIsHoveredJudge4(true)}
                                                onMouseLeave={() => setIsHoveredJudge4(false)}>
                                                {status === 'playing' && (
                                                    <Button
                                                        p={"25px"}
                                                        position={"absolute"}
                                                        onClick={() => openJudge4Modal(player)}
                                                        isDisabled={isScoreBtnDisabledJudge4 || player.status === "completed"}
                                                        sx={{
                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                            color: "white",
                                                            _hover: {
                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                            },
                                                            _active: {
                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                            },
                                                        }}

                                                    >
                                                        {editCountJudge4 === 0 || player.status === "completed" ? 'Add E4 Score' : 'Edit E4 Score'}
                                                    </Button>
                                                )}
                                            </Box>
                                            {isHoveredJudge4 && (
                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCountJudge4 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                    top={50}
                                                    right={50}
                                                >
                                                    <BsInfoCircleFill style={{color: editCountJudge4 === 0 || totalEditJudge4 === 2 ? "white":""}} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditJudge4 === 2 ? "" :  editCountJudge4 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditJudge4} time only`}
                                                </Flex>
                                            )}

                                        </Box>
                                    </Box>



                                </Flex>


                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                            </ListItem>
                            )}
                        </List>
                        </Box>
                            </Box>

                        </Flex>
                    </>
                )}
                
                {(userRole === 'a1' || userRole === 'all' || userRole === 'a1234' || userRole === 'a123d1' || userRole === 'a124d1' || userRole === 'a134d1' || userRole === 'a123' || userRole === 'a124' || userRole === 'a12d1' || userRole === 'a134' || userRole === 'a13d1' || userRole === 'a14d1' || userRole === 'a12' || userRole === 'a13' || userRole === 'a14' || userRole === 'a1d1' || userRole === "j1a1" || userRole === "j2a1" || userRole === "j3a1" || userRole === "j4a1") && (
                    <>
                        <Box w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="22px" fontWeight={"bold"} color={"white"} textAlign={"center"}>Execution Judge (A1) for {game}</Text>
                        </Box>
                        <br />
                        <Box w={"90%"} m={"auto"}>
                        <PlayerGrid eventData={event.data} />;
                        </Box>
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{ base: "400px", md: "300px", lg: "300px" }} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>
                                    <List spacing={3}>
                                        {player && (
                                            <ListItem key={teamName}>
                                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                                    <Box borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"} w={{ base: "80%", md: "50%", lg: "40%" }} textAlign={"start"}>

                                                        <Text fontSize={"lg"} fontWeight={"bold"} color={"#26263c"}>Team Name: {teamName}</Text>
                                                    
                                                        <Text fontSize={"lg"}><b>Given A1 Score: </b>{player.scores.a1}</Text>
                                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                                    </Box>
                                                    <Box p={"20px"} w={{ base: "51%", md: "34%", lg: "34%" }} >
                                                        <Box ml={{ base: "0%", md: "30%", lg: "30%" }}>

                                                            <Box onMouseEnter={() => setIsHoveredA1(true)}
                                                                onMouseLeave={() => setIsHoveredA1(false)}>
                                                                {status === 'playing' && (
                                                                    <Button
                                                                        p={"25px"}
                                                                        position={"absolute"}
                                                                        onClick={() => openA1Modal(player)}
                                                                        isDisabled={isScoreBtnDisabledA1 || player.status === "completed"}
                                                                        sx={{
                                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                                            color: "white",
                                                                            _hover: {
                                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                                            },
                                                                            _active: {
                                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                                            },
                                                                        }}

                                                                    >
                                                                        {editCountA1 === 0 || player.status === "completed" ? 'Add E1 Score' : 'Edit E1 Score'}
                                                                    </Button>
                                                                )}
                                                            </Box>
                                                            {isHoveredA1 && (
                                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCountA1 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                                    top={50}
                                                                    right={50}
                                                                >
                                                                    <BsInfoCircleFill style={{ color: editCountA1 === 0 || totalEditA1 === 2 ? "white" : "" }} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditA1 === 2 ? "" : editCountA1 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditA1} time only`}
                                                                </Flex>
                                                            )}

                                                        </Box>
                                                    </Box>



                                                </Flex>


                                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                                            </ListItem>
                                        )}
                                    </List>
                                </Box>
                            </Box>

                        </Flex>
                    </>
                )}
                {(userRole === 'a2' || userRole === 'all' || userRole === 'a1234' || userRole === 'a123d1' || userRole === 'a124d1' || userRole === 'a234d1' || userRole === 'a123' || userRole === 'a124' || userRole === 'a12d1' || userRole === 'a234' || userRole === 'a23d1' || userRole === 'a24d1' || userRole === 'a12' || userRole === 'a23' || userRole === 'a24' || userRole === 'a2d1' || userRole === "j1a2" || userRole === "j2a2" || userRole === "j3a2" || userRole === "j4a2") && (
                    <>
                            <Box w={"100%"} style={{ textAlign: "center" }} >
                                <Text fontSize="22px" fontWeight={"bold"} color={"white"} textAlign={"center"}>Execution Judge (A2) for {game}</Text>
                            </Box>
                            <br />
                            <Box w={"90%"} m={"auto"}>
                        <PlayerGrid eventData={event.data} />;
                        </Box>
                            <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{ base: "400px", md: "300px", lg: "300px" }} maxHeight={"1000px"} m={"auto"} >
                                <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                    <Box>
                                        <List spacing={3}>
                                            {player && (
                                                <ListItem key={teamName}>
                                                    <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                                        <Box borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"} w={{ base: "80%", md: "50%", lg: "40%" }} textAlign={"start"}>
    
                                                            <Text fontSize={"lg"} fontWeight={"bold"} color={"#26263c"}>Team Name: {teamName}</Text>
                                                            
                                                            <Text fontSize={"lg"}><b>Age: </b>{player?.age || "N/A"}</Text>
                                                            <Text fontSize={"lg"}><b>Given A2 Score: </b>{player.scores.a2}</Text>
                                                            <Text fontSize={"lg"}>Status: {player.status}</Text>
                                                            {/* <Text>New Edit count: {newEditCountJudge1}</Text> */}
                                                        </Box>
                                                        <Box p={"20px"} w={{ base: "51%", md: "34%", lg: "34%" }} >
                                                            <Box ml={{ base: "0%", md: "30%", lg: "30%" }}>
    
                                                                <Box onMouseEnter={() => setIsHoveredA2(true)}
                                                                    onMouseLeave={() => setIsHoveredA2(false)}>
                                                                    {status === 'playing' && (
                                                                        <Button
                                                                            p={"25px"}
                                                                            position={"absolute"}
                                                                            onClick={() => openA2Modal(player)}
                                                                            isDisabled={isScoreBtnDisabledA2 || player.status === "completed"}
                                                                            sx={{
                                                                                bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                                                color: "white",
                                                                                _hover: {
                                                                                    bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                                                },
                                                                                _active: {
                                                                                    backgroundColor: "rgba(255, 255, 255, 0.)",
                                                                                },
                                                                            }}
    
                                                                        >
                                                                            {editCountA2 === 0 || player.status === "completed" ? 'Add E1 Score' : 'Edit E1 Score'}
                                                                        </Button>
                                                                    )}
                                                                </Box>
                                                                {isHoveredA2 && (
                                                                    <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                                        style={{ fontWeight: "bold", fontSize: "14px", color: editCountA2 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                                        top={50}
                                                                        right={50}
                                                                    >
                                                                        <BsInfoCircleFill style={{ color: editCountA2 === 0 || totalEditA2 === 2 ? "white" : "" }} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditA2 === 2 ? "" : editCountA2 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditA2} time only`}
                                                                    </Flex>
                                                                )}
    
                                                            </Box>
                                                        </Box>
    
    
    
                                                    </Flex>
    
    
                                                    {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}
    
                                                </ListItem>
                                            )}
                                        </List>
                                    </Box>
                                </Box>
    
                            </Flex>
                        </>
                )}
                {(userRole === 'a3' || userRole === 'all' || userRole === 'a1234' || userRole === 'a123d1' || userRole === 'a134d1' || userRole === 'a234d1' || userRole === 'a123' || userRole === 'a134' || userRole === 'a13d1' || userRole === 'a234' || userRole === 'a23d1' || userRole === 'a34d1' || userRole === 'a13' || userRole === 'a23' || userRole === 'a34' || userRole === 'a3d1' || userRole === "j1a3" || userRole === "j2a3" || userRole === "j3a3" || userRole === "j4a3") && (
                    <>
                        <Box w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="22px" fontWeight={"bold"} color={"white"} textAlign={"center"}>Execution Judge (A3) for {game}</Text>
                        </Box>
                        <br />
                        <Box w={"90%"} m={"auto"}>
                        <PlayerGrid eventData={event.data} />;
                        </Box>
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{ base: "400px", md: "300px", lg: "300px" }} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>
                                    <List spacing={3}>
                                        {player && (
                                            <ListItem key={teamName}>
                                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                                    <Box borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"} w={{ base: "80%", md: "50%", lg: "40%" }} textAlign={"start"}>

                                                        <Text fontSize={"lg"} fontWeight={"bold"} color={"#26263c"}>Team Name: {teamName}</Text>
                                                        
                                                        <Text fontSize={"lg"}><b>Age: </b>{player?.age || "N/A"}</Text>
                                                        <Text fontSize={"lg"}><b>Given A3 Score: </b>{player.scores.a3}</Text>
                                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                                        {/* <Text>New Edit count: {newEditCountJudge1}</Text> */}
                                                    </Box>
                                                    <Box p={"20px"} w={{ base: "51%", md: "34%", lg: "34%" }} >
                                                        <Box ml={{ base: "0%", md: "30%", lg: "30%" }}>

                                                            <Box onMouseEnter={() => setIsHoveredA3(true)}
                                                                onMouseLeave={() => setIsHoveredA3(false)}>
                                                                {status === 'playing' && (
                                                                    <Button
                                                                        p={"25px"}
                                                                        position={"absolute"}
                                                                        onClick={() => openA3Modal(player)}
                                                                        isDisabled={isScoreBtnDisabledA3 || player.status === "completed"}
                                                                        sx={{
                                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                                            color: "white",
                                                                            _hover: {
                                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                                            },
                                                                            _active: {
                                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                                            },
                                                                        }}

                                                                    >
                                                                        {editCountA3 === 0 || player.status === "completed" ? 'Add E1 Score' : 'Edit E1 Score'}
                                                                    </Button>
                                                                )}
                                                            </Box>
                                                            {isHoveredA3 && (
                                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCountA3 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                                    top={50}
                                                                    right={50}
                                                                >
                                                                    <BsInfoCircleFill style={{ color: editCountA3 === 0 || totalEditA3 === 2 ? "white" : "" }} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditA3 === 2 ? "" : editCountA3 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditA3} time only`}
                                                                </Flex>
                                                            )}

                                                        </Box>
                                                    </Box>



                                                </Flex>


                                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                                            </ListItem>
                                        )}
                                    </List>
                                </Box>
                            </Box>

                        </Flex>
                    </>
                )}
                {(userRole === 'a4' || userRole === 'all' || userRole === 'a1234' || userRole === 'a124d1' || userRole === 'a134d1' || userRole === 'a234d1' || userRole === 'a14d1' || userRole === 'a124' || userRole === 'a134' || userRole === 'a234' || userRole === 'a24d1' || userRole === 'a34d1' || userRole === 'a14' || userRole === 'a24' || userRole === 'a34' || userRole === 'a4d1' || userRole === "j1a4" || userRole === "j2a4" || userRole === "j3a4" || userRole === "j4a4") && (
                    <>
                        <Box w={"100%"} style={{ textAlign: "center" }} >
                            <Text fontSize="22px" fontWeight={"bold"} color={"white"} textAlign={"center"}>Execution Judge (A4) for {game}</Text>
                        </Box>
                        <br />
                        <Box w={"90%"} m={"auto"}>
                        {/* <PlayerGrid eventData={event.data} />; */}
                        </Box>
                        <Flex bg={"white"} borderRadius={"20px"} flexWrap={"wrap"} boxShadow={"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} w={"90%"} h={{ base: "400px", md: "300px", lg: "300px" }} maxHeight={"1000px"} m={"auto"} >
                            <Box w={"100%"} margin={"auto"} padding={"auto"}>
                                <Box>
                                    <List spacing={3}>
                                        {player && (
                                            <ListItem key={teamName}>
                                                <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-evenly"}>
                                                    <Box borderRadius={"10px"} boxShadow={"rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"} bg={"gray.100"} p={"20px"} pl={"30px"} w={{ base: "80%", md: "50%", lg: "40%" }} textAlign={"start"}>

                                                        <Text fontSize={"lg"} fontWeight={"bold"} color={"#26263c"}>Team Name: {teamName}</Text>
                                                        
                                                        <Text fontSize={"lg"}><b>Age: </b>{player?.age || "N/A"}</Text>
                                                        <Text fontSize={"lg"}><b>Given A4 Score: </b>{player.scores.a4}</Text>
                                                        <Text fontSize={"lg"}>Status: {player.status}</Text>
                                                        {/* <Text>New Edit count: {newEditCountJudge1}</Text> */}
                                                    </Box>
                                                    <Box p={"20px"} w={{ base: "51%", md: "34%", lg: "34%" }} >
                                                        <Box ml={{ base: "0%", md: "30%", lg: "30%" }}>

                                                            <Box onMouseEnter={() => setIsHoveredA4(true)}
                                                                onMouseLeave={() => setIsHoveredA4(false)}>
                                                                {status === 'playing' && (
                                                                    <Button
                                                                        p={"25px"}
                                                                        position={"absolute"}
                                                                        onClick={() => openA4Modal(player)}
                                                                        isDisabled={isScoreBtnDisabledA4 || player.status === "completed"}
                                                                        sx={{
                                                                            bgGradient: "linear(to-r, yellow.400, yellow.500, yellow.600)",
                                                                            color: "white",
                                                                            _hover: {
                                                                                bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                                                                            },
                                                                            _active: {
                                                                                backgroundColor: "rgba(255, 255, 255, 0.)",
                                                                            },
                                                                        }}

                                                                    >
                                                                        {editCountA4 === 0 || player.status === "completed" ? 'Add E1 Score' : 'Edit E1 Score'}
                                                                    </Button>
                                                                )}
                                                            </Box>
                                                            {isHoveredA4 && (
                                                                <Flex alignItems={"center"} gap={"10px"} position={"relative"}
                                                                    style={{ fontWeight: "bold", fontSize: "14px", color: editCountA4 === 2 || player.status === "completed" ? 'red' : 'orange' }}
                                                                    top={50}
                                                                    right={50}
                                                                >
                                                                    <BsInfoCircleFill style={{ color: editCountA4 === 0 || totalEditA4 === 2 ? "white" : "" }} />{player.status === "completed" ? "After approved by D judge you cannot edit score" : totalEditA4 === 2 ? "" : editCountA4 === 2 ? 'You have already edited the score 1 time' : `You can only edit a score ${totalEditA4} time only`}
                                                                </Flex>
                                                            )}

                                                        </Box>
                                                    </Box>



                                                </Flex>


                                                {/* <Button onClick={() => openJudge2Modal(player)}>Give E2 score</Button> */}

                                            </ListItem>
                                        )}
                                    </List>
                                </Box>
                            </Box>

                        </Flex>
                    </>
                )}

            </VStack>

            {/* Judge1 modal */}
            <Modal isOpen={isJudge1ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {teamName} as Judge1</ModalHeader>
                    <ModalHeader>{editCountJudge1 === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setScore(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setScore(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setScore(10);
                                }
                            }}                            
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleJudge1ScoreSubmit}>
                            {editCountJudge1 === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Judge2 modal */}
            <Modal isOpen={isJudge2ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {selectedPlayer?.name} as Judge2</ModalHeader>
                    <ModalHeader>{editCount === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setScore(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setScore(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setScore(10);
                                }
                            }}
                            
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleJudge2ScoreSubmit}>
                            {editCount === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Judge3 modal */}
            <Modal isOpen={isJudge3ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {selectedPlayer?.name} as Judge3</ModalHeader>
                    <ModalHeader>{editCountJudge3 === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setScore(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setScore(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setScore(10);
                                }
                            }}
                            
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleJudge3ScoreSubmit}>
                            {editCountJudge3 === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Judge4 modal */}
            <Modal isOpen={isJudge4ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {selectedPlayer?.name} as Judge4</ModalHeader>
                    <ModalHeader>{editCountJudge4 === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setScore(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setScore(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setScore(10);
                                }
                            }}
                            
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleJudge4ScoreSubmit}>
                            {editCountJudge4 === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* A1 modal */}
            <Modal isOpen={isA1ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {teamName} as A1</ModalHeader>
                    <ModalHeader>{editCountA1 === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setScore(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setScore(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setScore(10);
                                }
                            }}
                            
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleA1ScoreSubmit}>
                            {editCountA1 === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* A2 modal */}
            <Modal isOpen={isA2ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {teamName} as A2</ModalHeader>
                    <ModalHeader>{editCountA2 === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setScore(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setScore(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setScore(10);
                                }
                            }}
                            
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleA2ScoreSubmit}>
                            {editCountA2 === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* A3 modal */}
            <Modal isOpen={isA3ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {teamName} as A3</ModalHeader>
                    <ModalHeader>{editCountA3 === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setScore(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setScore(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setScore(10);
                                }
                            }}
                            
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleA3ScoreSubmit}>
                            {editCountA3 === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* A4 modal */}
            <Modal isOpen={isA4ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {teamName} as A4</ModalHeader>
                    <ModalHeader>{editCountA4 === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setScore(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setScore(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setScore(10);
                                }
                            }}
                            
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleA4ScoreSubmit}>
                            {editCountA4 === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* D Judge modal */}
            <Modal isOpen={isD1ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>CJP Judge </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* <Input
                            type="number"
                            value={s1}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (value >= 0 && value <= 10) {
                                    setS1(value);
                                } else if (value < 0) {
                                    setS1(0);
                                } else if (value > 10) {
                                    setS1(10);
                                }
                            }}
                            placeholder="Enter score D1"
                            min="0"
                            max="10"
                            
                        /> */}
                        {/* <Input
                            type="number"
                            value={s2}
                            onChange={(e) => setS2(parseFloat(e.target.value))}
                            placeholder="Enter score D2"
                        /> */}
                        
                        <Text fontWeight={"bold"}>Enter CJP Deduction value</Text>
                        <Input
                            type="number"
                            value={cjp}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setCJP(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setCJP(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setCJP(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setCJP(10);
                                }
                            }}
                            
                            placeholder="Enter Penalty"
                            min="0"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleD1ScoreSubmit}>
                            Submit Score
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isD2ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Score for {selectedPlayer?.name} as D2</ModalHeader>
                    <ModalHeader>{editCountD2 === 0 ? 'Add Score' : 'Edit Score'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setScore(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setScore(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setScore(10);
                                }
                            }}
                            
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleD2ScoreSubmit}>
                        {editCountD2 === 0 ? 'Save' : 'Update'}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isD3ModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Judge D1 Score</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text size={"md"} fontWeight={"bold"}>D1 Score</Text>
                        <Input
                            type="number"
                            value={score}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setScore(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setScore(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setScore(10);
                                }
                            }}
                            
                            placeholder="Enter score Difficulty 1"
                            min="0"
                            max="10"
                            
                        />
                        <br/>
                        <Text mt={"10px"} fontSize={"md"} fontWeight={"bold"}>Divided by 2 / 1.9 / 1.8</Text>
                        <Input
                            type="number"
                            value={dividedby}
                            onChange={(e) => setDividedBy(parseFloat(e.target.value))}
                            placeholder="Divided by 2 / 1.9 / 1.8"
                        />
                        <br />
                        <br />
                        <Text fontWeight={"bold"}>Penalty</Text>
                        <Input
                            type="number"
                            value={penalty}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setPenalty(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setPenalty(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setPenalty(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setPenalty(10);
                                }
                            }}
                            
                            placeholder="Enter Penalty"
                            min="0"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleD3ScoreSubmit}>
                            Submit Score
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isScoreModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Score for {selectedPlayer?.name} ({judgeToEdit})</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            value={score}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty value or partial input
                                if (inputValue === "" || inputValue === "." || inputValue === "-") {
                                    setScore(inputValue); // Allow user to type freely
                                    return;
                                }
                            
                                const value = parseFloat(inputValue);
                                // Ensure value is a valid number and within range
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    setScore(value);
                                } else if (!isNaN(value) && value < 0) {
                                    setScore(0);
                                } else if (!isNaN(value) && value > 10) {
                                    setScore(10);
                                }
                            }}
                            
                            placeholder="Enter score"
                            type="number"
                            min="0"
                            max="10"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={() => handleEScoreSubmit({judgeToEdit})}>
                            Save
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isApproveModalOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Are you sure for approve {selectedPlayer?.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={approve}>
                            Yes
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default TeamDetailsPage;
