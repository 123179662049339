import { Button , Flex} from '@chakra-ui/react'
import React from 'react'
import { Route, Router, Routes, useNavigate } from 'react-router-dom'
import SideBar from "./Sidebar/SideBar";
import AthletesList from "./AthletesList"
import DashboardGrid from './Sidebar/DashboardGrid';
import VisitorsChart from './Charts/VisitorsChart';
import ProfitChart from './Charts/ProfitCharts';

const SuperAdminDashboard = () => {
  const navigate = useNavigate();
  return (
    // <div>
    //   <Button bg={"#ffbf00"} onClick={() => navigate('/create-event')}>
    //     Create Compitition +
    //   </Button>
    //   <Button
    //     bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
    //     sx={{
    //       backgroundColor: "#ffbf00",
    //       color: "white",
    //       _hover: {
    //         bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
    //       },
    //       _active: {
    //         backgroundColor: "rgba(255, 255, 255, 0.)",
    //       },
    //     }}
    //     onClick={() => navigate('/create-post')}>Create Post</Button>

    //   <Button bg={"#ffbf00"} onClick={() => navigate('/admin/superadmin/dashboard/athlete/athletesearch')}>
    //     Search Athletes
    //   </Button>

    //   <Button bg={"#ffbf00"} onClick={() => navigate('/create-event')}>
    //         All Athletes
    //       </Button>
    // </div>

    <>
      <div className="main-page">
        <div className="main-side">

          <SideBar>
            <br/>
            <DashboardGrid />
            <br/>
            <br/>
            <Flex w={"80%"} m={"auto"} bg={"gray.800"} minHeight={"30vh"} flex={1} justifyContent={"space-evenly"} alignItems={"center"}>
              <Button bg={"#ffbf00"} onClick={() => navigate('/create-event')}>
                Create Compitition +
              </Button>
              <br/>
              <Button bg={"#ffbf00"} onClick={() => navigate('/create-event-aerobic')}>
                Create Aerobic Compitition +
              </Button>
              <Button
                bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                sx={{
                  backgroundColor: "#ffbf00",
                  color: "white",
                  _hover: {
                    bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)"
                  },
                  _active: {
                    backgroundColor: "rgba(255, 255, 255, 0.)",
                  },
                }}
                onClick={() => navigate('/create-post')}>Create Post</Button>
            </Flex>
            <br/>
            <br/>
            <Flex w={"100%"} m={"auto"} flexWrap={"wrap"}>
            <VisitorsChart/>
            <ProfitChart/>
            </Flex>
          </SideBar>
        </div>
      </div>
    </>
  )
}

export default SuperAdminDashboard