import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import JudgeDashboardAerobics from '../Aerobics/JudgeDashboard';
import SupervisorDashboard from '../SupervisorDashboard';
import SupervisorDashboardAerobics from '../Aerobics/Supervisors/SupervisorDashboard';

const SupervisorDashboardTab = () => {
  return (
    <div>
        <Tabs>
            <TabList>
            <Tab>Aerobic</Tab>
                <Tab>Artistic</Tab>
                
            </TabList>
            <TabPanels>
            <TabPanel>
                    <SupervisorDashboardAerobics/>
                </TabPanel>
                <TabPanel>
                    <SupervisorDashboard/>
                </TabPanel>
                
            </TabPanels>
        </Tabs>
    </div>
  )
}

export default SupervisorDashboardTab