import React, { useState, useEffect } from "react";
import { API_URL } from "../../../utils/apiConfig";
import maleathleteimg from "../../../Images/Screenshot (2).png";


const AthletePhoto = ({ email }) => {
  const [photoUrl, setPhotoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAthletePhoto = async () => {
      try {
        const response = await fetch(`${API_URL}/api/athlete/athletes/${email}`);
        if (!response.ok) {
          throw new Error("Failed to fetch athlete data");
        }

        const athlete = await response.json();

        setPhotoUrl(
          athlete.aadhaarData?.photoUrl
            ? `data:image/jpeg;base64,${athlete.aadhaarData.photoUrl}`
            : null
        );
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAthletePhoto();
  }, [email]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      {photoUrl ? (
        <img src={photoUrl} alt="Athlete Profile" style={{ width: "150px", margin:"auto", borderRadius:"50%", height: "150px" }} />
      ) : (
        <img src={maleathleteimg} alt="Athlete Profile" style={{ width: "150px", margin:"auto", borderRadius:"50%", height: "150px" }} />
      )}
    </div>
  );
};

export default AthletePhoto;
