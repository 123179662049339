import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../../utils/apiConfig';
import PlayerPhoto from './PlayerPhoto';
import { Box, Flex, Grid, GridItem, Heading, HStack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
const LiveDisplay = () => {
    const { eventId, game } = useParams();
    const [players, setPlayers] = useState([]);
    const [listPlayers, setListPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [displayedScores, setDisplayedScores] = useState({});
    const socketRef = useRef();

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const livePlayersResponse = await axios.get(`${API_URL}/api/events-aerobic/${eventId}/sports/${game}/live-display`);
                setPlayers(livePlayersResponse.data.livePlayers);

                const approvedPlayersResponse = await axios.get(`${API_URL}/api/approved-players-spot-aerobic/${eventId}`);
                const eventData = approvedPlayersResponse.data;

                if (eventData && eventData.sports[game]) {
                    const gamePlayers = eventData.sports[game].filter(player => player.approve);
                    const sortedAndRankedPlayers = sortAndRankPlayers(gamePlayers);
                    setListPlayers(sortedAndRankedPlayers);
                } else {
                    setListPlayers([]);
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();

        // Initialize Socket.IO
        const socket = io(`${API_URL}`);
        socketRef.current = socket;

        // Listen for real-time updates
        socket.on('playerStatusUpdated', (data) => {
            if (data.sportName === game && data.eventId === eventId) {
                const updatedPlayer = data.updatedPlayer;
                setPlayers((prevPlayers) => {
                    const exists = prevPlayers.some((player) => player._id === updatedPlayer._id);
                    if (exists) {
                        return prevPlayers.map((player) =>
                            player._id === updatedPlayer._id ? updatedPlayer : player
                        );
                    }
                    return [...prevPlayers, updatedPlayer];
                });
            }
        });

        socket.on('displayScores', (data) => {
            if (data.sportName === game && data.eventId === eventId) {
                const updatedPlayer = data.updatedPlayer;
                setDisplayedScores((prev) => ({
                    ...prev,
                    [updatedPlayer._id]: updatedPlayer.scores,
                }));

                setTimeout(() => {
                    setDisplayedScores((prev) => {
                        const newScores = { ...prev };
                        delete newScores[updatedPlayer._id];
                        return newScores;
                    });
                }, 30000);
            }
        });

        socket.on('removePlayer', (data) => {
            if (data.sportName === game && data.eventId === eventId) {
                setPlayers((prevPlayers) =>
                    prevPlayers.filter((player) => player._id !== data.playerId)
                );
            }
        });

        socket.on('playerUpdated', (updatedPlayers) => {
            if (updatedPlayers[eventId] && updatedPlayers[eventId].sports[game]) {
                const gamePlayers = updatedPlayers[eventId].sports[game].filter(player => player.approve);
                const sortedAndRankedPlayers = sortAndRankPlayers(gamePlayers);
                setListPlayers(sortedAndRankedPlayers);
            }
        });

        return () => socket.disconnect();
    }, [eventId, game]);

    const sortAndRankPlayers = (players) => {
        const sortedPlayers = players.sort((a, b) => {
            if ((b.scores.total || 0) !== (a.scores.total || 0)) {
                return (b.scores.total || 0) - (a.scores.total || 0);
            }
            if ((b.scores.e || 0) !== (a.scores.e || 0)) {
                return (b.scores.e || 0) - (a.scores.e || 0);
            }
            if ((b.scores.d1 || 0) !== (a.scores.d1 || 0)) {
                return (b.scores.d1 || 0) - (a.scores.d1 || 0);
            }
            return (a.scores.penalty || 0) - (b.scores.penalty || 0);
        });

        let currentRank = 1;
        let currentTotalScore = sortedPlayers[0]?.scores.total || 0;

        sortedPlayers.forEach((player, index) => {
            if (player.scores.total !== currentTotalScore) {
                currentRank = index + 1;
                currentTotalScore = player.scores.total;
            }
            player.rank = currentRank;
        });

        return sortedPlayers;
    };

    const firstFiveRows = listPlayers.slice(0, 5);
    const lastFiveRows = listPlayers.slice(5, 10);

    return (
        <Box backgroundColor={"black"}>
        <Box bgGradient="linear(to-b, #AD0026, #A30033, #990040, #8F004C, #850059, #7A0066, #700073, #660080, #5C008C, #520099, #4700A6, #3D00B2, #3300BF, #2900CC, #1F00D9, #1400E6)" w={"130vh"} height={"100vh"} border={"1px solid black"} m={"auto"}>
            <Box w={"98%"} borderRadius={"10px"} m={"auto"} mb={2}border={"1px solid gray"} boxShadow = {"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"}>
                <Text textAlign={"center"} fontWeight={"bold"} fontSize={"3xl"} color={"white"}>AEROBICS NATIONALS 2025</Text>
                <Text textAlign={"center"}  color={"white"}>19TH Aerobics National Champitionship</Text>
                <Text textAlign={"center"} color={"white"}>MA Stadium, Jammu & Kashmir</Text>
            </Box>
            <Flex flex={1} justifyContent={"space-evenly"}>
            <Box border={"1px solid gray"} boxShadow = {"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} borderRadius={"10px"} w={"48%"}>
                <Box mb={4} mt={1}>
                    <Text textAlign={"center"} color={"white"} fontSize={"2xl"} fontWeight={"bold"}>{game === "MensPair" ? "INDIVIDUAL MEN" : game === "WomensPair"?"INDIVIDUAL WOMEN": game}</Text>
                </Box>

            {players.length > 0 ? (
                <ul>
                    {players.map(player => (
                        <>
                <Grid mt={2}
        templateColumns="repeat(3, 1fr)" // 3 columns in the first row
        gap={4} // Space between boxes
      >
        {/* 1st row (3 boxes) */}
        <GridItem colSpan={1} >
            <Text textAlign={"center"} color={"white"} size={"md"} fontWeight={"bold"}>DIFFICULTY</Text>
          <Box fontWeight={"bold"}  p={"auto"} color={"white"} textAlign="center"  >
          {displayedScores[player._id]?.d || 'ND'}
          </Box>
        </GridItem>
        <GridItem colSpan={1} >
            <Text textAlign={"center"} color={"white"} size={"md"} fontWeight={"bold"}>ARTISTRY</Text>
          <Box fontWeight={"bold"} p={"auto"} color={"white"} textAlign="center"  >
          {displayedScores[player._id]?.a || 'ND'}
          </Box>
        </GridItem>
        <GridItem colSpan={1} >
            <Text textAlign={"center"} color={"white"} size={"md"} fontWeight={"bold"}>EXECUTION</Text>
          <Box fontWeight={"bold"} p={"auto"} color={"white"} textAlign="center"  >
          {displayedScores[player._id]?.e || 'ND'}
          </Box>
        </GridItem>

        {/* 2nd row (2 boxes centered) */}
        
                </Grid>
                <Grid templateColumns="repeat(3, 1fr)" >
        <GridItem colSpan={1} mt={5} >
            <Text textAlign={"center"} color={"white"} size={"md"}  fontWeight={"bold"}>PENALTY</Text>
          <Box fontWeight={"bold"} p={"auto"} color={"white"} textAlign="center"  >
          {displayedScores[player._id]?.penalty || 'ND'}
          </Box>
        </GridItem>
        <GridItem colSpan={1} mt={5} >
            <Text textAlign={"center"} color={"white"} size={"md"}  fontWeight={"bold"}>CJP P</Text>
          <Box fontWeight={"bold"} p={"auto"} color={"white"} textAlign="center"  >
          {displayedScores[player._id]?.cjp || 'ND'}
          </Box>
        </GridItem>
        <GridItem colSpan={1} mt={5} >
            <Text textAlign={"center"} color={"white"} size={"md"}  fontWeight={"bold"}>TOTAL</Text>
          <Box fontWeight={"bold"} p={"auto"} color={"white"} textAlign="center"  >
          {displayedScores[player._id]?.total || 'ND'}
          </Box>
        </GridItem>
        </Grid>
                </>
                    ))}
                </ul>
            ) : (
                <p>No players are currently playing.</p>
            )}
            </Box>
            <Box border={"1px solid gray"} boxShadow = {"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} borderRadius={"10px"} backgroundColor={""} w={"48%"}  >
            {players.length > 0 ? (
                <ul>
                    {players.map(player => (
                        <>
                        
                        <Box >
                            <PlayerPhoto email={player.email}/>
                        </Box>
                        <Box m={"auto"}>
                            <Text fontSize={"3xl"} textAlign={"center"} color={"white"} key={player._id}>
                            {player.name}</Text>
                        </Box>
                        </>
                    ))}
                </ul>
            ) : (
                <p>No players are currently playing.</p>
            )}
            </Box>
            </Flex>
            <br/>
            <Box>
            <HStack spacing={8} align="start">
        {/* Left side Table for first 5 rows */}
        <Box width="49%">
          <Table borderRadius={"10px"}  boxShadow = {"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} variant="simple" width="100%">
            <Thead>
              <Tr>
                <Th color={"white"}>Rank</Th>
                <Th color={"white"}>Name</Th>
                {/* <Th color={"white"}>Age</Th> */}
                <Th color={"white"}>Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {firstFiveRows.map((row, index) => (
                <Tr key={row.id}>
                  <Td fontSize={"12px"} color={"white"}>{index+1}</Td>
                  <Td fontSize={"12px"} color={"white"}>{row.name}</Td>
                  {/* <Td color={"white"}>{row.age}</Td> */}
                  <Td fontSize={"12px"} color={"white"}>{row.scores.total}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        {/* Right side Table for last 5 rows */}
        <Box width="49%">
          <Table borderRadius={"10px"} boxShadow = {"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} variant="simple" width="100%">
            <Thead>
              <Tr>
                <Th color={"white"}>Rank</Th>
                <Th color={"white"}>Name</Th>
                {/* <Th color={"white"}>Age</Th> */}
                <Th color={"white"}>Country</Th>
              </Tr>
            </Thead>
            <Tbody>
              {lastFiveRows.map((row, index) => (
                <Tr key={row.id}>
                  <Td fontSize={"12px"} color={"white"}>{6+index}</Td>
                  <Td fontSize={"12px"} color={"white"}>{row.name}</Td>
                  {/* <Td color={"white"}>{row.age}</Td> */}
                  <Td fontSize={"12px"} color={"white"}>{row.scores.total}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </HStack>
            </Box>
        </Box>
        </Box>
    );
};

export default LiveDisplay;
