import React, { useState, useEffect } from 'react';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {Modal,ModalOverlay,ModalContent,ModalHeader,ModalFooter,ModalBody,ModalCloseButton,Button,VStack,HStack,
  Input,Text,Switch,Box,List,ListItem,useDisclosure,useToast,Flex,Avatar,Tabs,TabList,Tab,TabPanels,TabPanel
} from '@chakra-ui/react';
import {Popover,PopoverTrigger,PopoverContent,PopoverHeader,PopoverBody,PopoverArrow,PopoverCloseButton,
} from '@chakra-ui/react'
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { RiMore2Fill } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineUserAdd } from "react-icons/ai";
import {API_URL} from "../../utils/apiConfig";

const ManageEventModal = ({ isOpen, onClose, eventId, fetchEvents }) => {
  const [step, setStep] = useState(1);
  const [eventName, setEventName] = useState('');
  const [switches, setSwitches] = useState({
    IM: false,
    IW: false,
});
 const [players, setPlayers] = useState({});
  const [playerName, setPlayerName] = useState('');
  const [playerMobile, setPlayerMobile] = useState('');
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const [isPlayerModalOpenIW, setIsPlayerModalOpenIW] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const { isOpen: isConfirmModalOpen, onOpen: onConfirmModalOpen, onClose: onConfirmModalClose } = useDisclosure();
  const { isOpen: isConfirmModalOpenIW, onOpen: onConfirmModalOpenIW, onClose: onConfirmModalCloseIW } = useDisclosure();
  const toast = useToast();
  const [teams, setTeams] = useState({});
  const [assignedGymnasts, setAssignedGymnasts] = useState([]);
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/events-aerobic/${eventId}`);
        const event = response.data;
        setEventName(event.eventName);
        setSwitches(event.sports);
        setPlayers(event.players || {});
        setTeams(event.teams || {});

        // Collect assigned gymnasts from teams
        let assigned = [];
        Object.values(event.teams || {}).forEach(team => {
          assigned = assigned.concat(team.gymnasts || []);
        });

        setAssignedGymnasts(assigned);
        
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };

    if (eventId) {
      fetchEvent();
    }
  }, [eventId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        // Fetch the latest event data before submitting
        const response = await axios.get(`${API_URL}/api/events-aerobic/${eventId}`);
        const latestEvent = response.data;
        // Merge the new players with the existing players
        const mergedPlayers = { ...latestEvent.players };

        Object.keys(players).forEach(game => {
            if (!mergedPlayers[game]) {
                mergedPlayers[game] = [];
            }
            // Merge new players into existing ones, ensuring no duplication
            players[game].forEach(newPlayer => {
                const existingPlayerIndex = mergedPlayers[game].findIndex(
                    player => player.mobile === newPlayer.mobile
                );
                if (existingPlayerIndex === -1) {
                    mergedPlayers[game].push(newPlayer);
                }
            });
        });

        // Update the event data with merged players
        const eventData = {
            eventName,
            players: mergedPlayers, // Use merged players here
        };
          // Loop through each team to update players and ensure uniqueness
        await axios.put(`${API_URL}/api/events-aerobic/${eventId}`, eventData);
        fetchEvents();
        onClose();

        toast({
            title: 'Event Updated',
            description: 'Event has been updated successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });

    } catch (error) {
        console.error('Error updating event:', error);
        toast({
            title: 'Error',
            description: 'There was an error updating the event.',
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    }
};

const handleAddPlayer = async () => {
  const newPlayer = { name: playerName, mobile: playerMobile };

  const updatedPlayers = { ...players };
  let isDuplicate = false;

  for (const game in switches) {
      if (switches[game]) {
          if (!updatedPlayers[game]) {
              updatedPlayers[game] = [];
          }
          const duplicatePlayer = updatedPlayers[game].find(
              player => player.mobile === newPlayer.mobile || (player.name === newPlayer.name && player.mobile === newPlayer.mobile)
          );
          if (duplicatePlayer) {
              isDuplicate = true;
              break;
          }
      }
  }

  if (isDuplicate) {
      toast({
          title: 'Duplicate Player',
          description: 'This player is already added. Please choose another one.',
          status: 'error',
          duration: 3000,
          isClosable: true,
      });
  } else {
      for (const game in switches) {
          if (switches[game]) {
              updatedPlayers[game].push({ ...newPlayer, });
          }
      }
      setPlayers(updatedPlayers);
      setPlayerName('');
      setPlayerMobile('');
      setIsPlayerModalOpen(false);
      onConfirmModalClose();

      toast({
          title: 'Player Added',
          description: 'Player added successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
      });

      try {
          await axios.put(`${API_URL}/api/events/${eventId}/players`, updatedPlayers);
      } catch (error) {
          console.error('Error updating players:', error);
      }
  }
};
const removePlayer = async (sport, index) => {
  setPlayers(prevPlayers => {
    const updatedPlayers = { ...prevPlayers };
    if (updatedPlayers[sport]) {
      updatedPlayers[sport].splice(index, 1);
      if (updatedPlayers[sport].length === 0) {
        delete updatedPlayers[sport];
      }
    }
    return updatedPlayers;
  });

  try {
    // Send updated players to the backend
    const updatedPlayers = { ...players };
    updatedPlayers[sport] = [...(updatedPlayers[sport] || [])];
    updatedPlayers[sport].splice(index, 1);
    if (updatedPlayers[sport].length === 0) {
      delete updatedPlayers[sport];
    }

    const eventData = {
      eventName,
      players: updatedPlayers,
    };

    await axios.put(`${API_URL}/api/events-aerobic/${eventId}`, eventData);
    toast({
      title: "Player Removed",
      description: "Player removed successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  } catch (error) {
    console.error("Error removing player:", error);
    toast({
      title: "Error",
      description: "There was an error removing the player.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  }
};


  const handleSearchPlayer = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 2) { // Only perform search if query length is greater than 2
      try {
        console.log(`Searching for: ${query}`);
        const response = await axios.get(`${API_URL}/api/athlete/athletes?search=${query}`);
        const athletes = response.data;
        console.log('Search results:', athletes);

        if (athletes.length > 0) {
          setSearchResults(athletes);
        } else {
          setSearchResults([{ id: 'no_result', name: 'No athlete found', email: '' }]); // Placeholder for no result
        }
      } catch (error) {
        console.error('Error searching players:', error);
      }
    } else {
      setSearchResults([]);
    }
  };
  const handleSelectPlayer = (player) => {
    setSelectedPlayer(player);
    onConfirmModalOpen();
  };

  const handleSelectPlayerIW = (player) => {
    setSelectedPlayer(player);
    onConfirmModalOpenIW();
  };

  const handleConfirmAddPlayer = async () => {
    if (selectedPlayer) {
      const updatedPlayers = { ...players };
      let isDuplicate = false;
      // Check for duplicate player in any active game
      for (const game in switches) {
        if (switches["MensPair"]) {
          if (!updatedPlayers["MensPair"]) {
            updatedPlayers["MensPair"] = [];
          }
          const gamePlayers = updatedPlayers["MensPair"];
          const existingPlayer = gamePlayers.find((p) => p.mobile === selectedPlayer.mobile);
          if (existingPlayer) {
            isDuplicate = true;
            break;
          }
        }
      }

      if (isDuplicate) {
        toast({
          title: 'Duplicate Player',
          description: 'This player is already added. Please choose another one.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Add player to all active games
          if (switches["MensPair"]) {
            updatedPlayers["MensPair"].push({
              name: `${selectedPlayer.name}`,
              mobile: selectedPlayer.mobile,
              email: selectedPlayer.email,
              athleteCode: selectedPlayer.athleteCode,
              city: selectedPlayer.city,
              club: selectedPlayer.club
            });
          }
        setPlayers(updatedPlayers);
        setSearchResults(searchResults.filter(player => player.id !== selectedPlayer.id));
        onConfirmModalClose();

        toast({
          title: 'Player Added',
          description: 'Player added successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleConfirmAddPlayerIW = async () => {
    if (selectedPlayer) {
      const updatedPlayers = { ...players };
      let isDuplicate = false;
      // Check for duplicate player in any active game
      for (const game in switches) {
        if (switches["WomensPair"]) {
          if (!updatedPlayers["WomensPair"]) {
            updatedPlayers["WomensPair"] = [];
          }
          const gamePlayers = updatedPlayers["WomensPair"];
          const existingPlayer = gamePlayers.find((p) => p.mobile === selectedPlayer.mobile);
          if (existingPlayer) {
            isDuplicate = true;
            break;
          }
        }
      }

      if (isDuplicate) {
        toast({
          title: 'Duplicate Player',
          description: 'This player is already added. Please choose another one.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Add player to all active games
          if (switches["WomensPair"]) {
            updatedPlayers["WomensPair"].push({
              name: `${selectedPlayer.name}`,
              mobile: selectedPlayer.mobile,
              email: selectedPlayer.email,
              athleteCode: selectedPlayer.athleteCode,
              city: selectedPlayer.city,
              club: selectedPlayer.club
            });
          }
        setPlayers(updatedPlayers);
        setSearchResults(searchResults.filter(player => player.id !== selectedPlayer.id));
        onConfirmModalClose();

        toast({
          title: 'Player Added',
          description: 'Player added successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const [popoverOpen, setPopoverOpen] = useState({});

  const togglePopover = (sport, index, isOpen) => {
    setPopoverOpen(prev => ({
      ...prev,
      [`${sport}-${index}`]: isOpen
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" backgroundColor={"#edf2f7"}>
      <ModalOverlay />
      <ModalContent backgroundColor={"#edf2f7"}>
        <ModalHeader>Manage Compitition</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {step === 1 && (
              <VStack spacing={4} backgroundColor={"#edf2f7"} w={"100%"}>
                <Text fontSize="2xl">Manage Compitition</Text>
                <Text>Compitition Name: {eventName}</Text>
                <HStack spacing={4}>
                  <Button onClick={() => setStep(1)} colorScheme="gray">
                    Back
                  </Button>
                  <Button onClick={handleSubmit} colorScheme="green">
                    Save Changes
                  </Button>
                </HStack>
                <Tabs>
                <TabList >
                    <Tab fontSize={"20px"} bgClip="text" bgGradient="linear(to-r, yellow.500, yellow.600, yellow.700)" style={{ color: 'black' }}>Add Gymnasts IM</Tab>
                    <Tab fontSize={"20px"} bgClip="text" bgGradient="linear(to-r, yellow.500, yellow.600, yellow.700)" style={{ color: 'black' }}>Add Gymnasts IW</Tab>
                
                </TabList>
                <TabPanels>
                <TabPanel>
  <Button onClick={() => setIsPlayerModalOpen(true)} colorScheme="green">
    Add Gymnasts <AiOutlineUserAdd style={{ fontSize: "20px" }} />
  </Button>
  {switches['MensPair'] && players['MensPair'] && (
    <Box
      mt={5}
      p={3}
      style={{ overflowX: 'auto', width: '100%', padding: '20px 20px', backgroundColor: "white" }}
      borderWidth="1px"
      borderRadius="20px"
      w="100%"
    >
      <Text fontSize="xl" fontWeight="bold">Individual Men</Text>
      <List spacing={3}>
        {players['MensPair'].map((player, index) => (
          <ListItem key={index}>
            <Flex
              style={{ border: "1px solid #ffbf00", marginTop: "10px" }}
              justifyContent={"space-between"}
              borderRadius={"10px"}
              alignItems={"center"}
              backgroundColor={"white"}
              width={"100%"}
            >
              <Flex padding={"10px"} alignItems={"center"} w={"82%"}>
                <Flex
                  alignItems={"center"}
                  style={{ backgroundColor: "black", padding: "5px", color: "white", borderRadius: "50%" }}
                >
                  <Text><FaRegUser /></Text>
                </Flex>
                <Text
                  colorScheme="red"
                  style={{ marginLeft: "10px", fontWeight: "bold" }}
                >
                  {player.name}
                </Text>
              </Flex>
              <Flex w={"15%"} justifyContent={"end"}>
                <Popover isOpen={popoverOpen[`MensPair-${index}`]} onClose={() => togglePopover('MensPair', index, false)}>
                  <PopoverTrigger>
                    <Box
                      as="button"
                      borderRadius="50%"
                      width="30px"
                      height="30px"
                      display="flex"
                      backgroundColor="white"
                      alignItems="end"
                      padding="0"
                      onClick={() => togglePopover('MensPair', index, true)}
                    >
                      <RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} />
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent style={{ textAlign: "start" }}>
                    <PopoverArrow />
                    <PopoverCloseButton fontWeight={"bold"} />
                    <PopoverHeader fontWeight={"bold"}>{player.name}</PopoverHeader>
                    <PopoverBody>
                      <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {player.email}</Text>
                      <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {player.mobile}</Text>
                      <Box
                        as='button'
                        onClick={() => {
                          removePlayer('MensPair', index);
                          togglePopover('MensPair', index, false);
                        }}
                        color={"red"}
                        fontWeight={"bold"}
                      >
                        Remove Gymnast
                      </Box>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Flex>
            </Flex>
          </ListItem>
        ))}
      </List>
    </Box>
  )}
</TabPanel>

<TabPanel>
  <Button onClick={() => setIsPlayerModalOpenIW(true)} colorScheme="green">
    Add Gymnasts IW <AiOutlineUserAdd style={{ fontSize: "20px" }} />
  </Button>
  {switches['WomensPair'] && players['WomensPair'] && (
    <Box
      mt={5}
      p={3}
      style={{ overflowX: 'auto', width: '100%', padding: '20px 20px', backgroundColor: "white" }}
      borderWidth="1px"
      borderRadius="20px"
      w="100%"
    >
      <Text fontSize="xl" fontWeight="bold">Individual Women</Text>
      <List spacing={3}>
        {players['WomensPair'].map((player, index) => (
          <ListItem key={index}>
            <Flex
              style={{ border: "1px solid #ffbf00", marginTop: "10px" }}
              justifyContent={"space-between"}
              borderRadius={"10px"}
              alignItems={"center"}
              backgroundColor={"white"}
              width={"100%"}
            >
              <Flex padding={"10px"} alignItems={"center"} w={"82%"}>
                <Flex
                  alignItems={"center"}
                  style={{ backgroundColor: "black", padding: "5px", color: "white", borderRadius: "50%" }}
                >
                  <Text><FaRegUser /></Text>
                </Flex>
                <Text
                  colorScheme="red"
                  style={{ marginLeft: "10px", fontWeight: "bold" }}
                >
                  {player.name}
                </Text>
              </Flex>
              <Flex w={"15%"} justifyContent={"end"}>
                <Popover isOpen={popoverOpen[`WomensPair-${index}`]} onClose={() => togglePopover('WomensPair', index, false)}>
                  <PopoverTrigger>
                    <Box
                      as="button"
                      borderRadius="50%"
                      width="30px"
                      height="30px"
                      display="flex"
                      backgroundColor="white"
                      alignItems="end"
                      padding="0"
                      onClick={() => togglePopover('WomensPair', index, true)}
                    >
                      <RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} />
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent style={{ textAlign: "start" }}>
                    <PopoverArrow />
                    <PopoverCloseButton fontWeight={"bold"} />
                    <PopoverHeader fontWeight={"bold"}>{player.name}</PopoverHeader>
                    <PopoverBody>
                      <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {player.email}</Text>
                      <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {player.mobile}</Text>
                      <Box
                        as='button'
                        onClick={() => {
                          removePlayer('WomensPair', index);
                          togglePopover('WomensPair', index, false);
                        }}
                        color={"red"}
                        fontWeight={"bold"}
                      >
                        Remove Gymnast
                      </Box>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Flex>
            </Flex>
          </ListItem>
        ))}
      </List>
    </Box>
  )}
</TabPanel>

                </TabPanels>
                </Tabs>
              </VStack>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>

      <Modal isOpen={isPlayerModalOpen} onClose={() => setIsPlayerModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Player</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <ModalBody>
                <Input
                  placeholder="Search by name..."
                  value={searchQuery}
                  onChange={handleSearchPlayer}
                />
                <List spacing={2} mt={2}>
                  {searchResults.map((player) => (
                    player.id === 'no_result' ? (
                      <ListItem key={player.id}>
                        <Flex alignItems={"center"} justifyContent={"center"}>
                          <Box>
                            <Text>{player.name}</Text>
                          </Box>
                        </Flex>
                      </ListItem>
                    ) : (
                      <ListItem
                        key={player.id}
                        cursor="pointer"
                        onClick={() => handleSelectPlayer(player)}
                      >
                        <Flex alignItems={"center"} gap={"5px"}>
                          <Box>
                            <Avatar size='md' name={player.name} src={`${API_URL}/${player.profileImage}`} />
                          </Box>
                          <Box>
                            <Text fontWeight={"bold"}>{player.name}</Text>
                            <Text>{player.athleteCode}</Text>
                          </Box>
                        </Flex>
                      </ListItem>
                    )
                  ))}
                </List>

              </ModalBody>
            </VStack>
            <VStack spacing={4}>
              <Input
                placeholder="Player Name"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
              />
              <PhoneInput
                placeholder="Player Mobile"
                value={playerMobile}
                required country={"in"}
                onChange={setPlayerMobile}/>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddPlayer}>
              Add Player
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isPlayerModalOpenIW} onClose={() => setIsPlayerModalOpenIW(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Player IW</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <ModalBody>
                <Input
                  placeholder="Search by name..."
                  value={searchQuery}
                  onChange={handleSearchPlayer}
                />
                <List spacing={2} mt={2}>
                  {searchResults.map((player) => (
                    player.id === 'no_result' ? (
                      <ListItem key={player.id}>
                        <Flex alignItems={"center"} justifyContent={"center"}>
                          <Box>
                            <Text>{player.name}</Text>
                          </Box>
                        </Flex>
                      </ListItem>
                    ) : (
                      <ListItem
                        key={player.id}
                        cursor="pointer"
                        onClick={() => handleSelectPlayerIW(player)}
                      >
                        <Flex alignItems={"center"} gap={"5px"}>
                          <Box>
                            <Avatar size='md' name={player.name} src={`${API_URL}/${player.profileImage}`} />
                          </Box>
                          <Box>
                            <Text fontWeight={"bold"}>{player.name}</Text>
                            <Text>{player.athleteCode}</Text>
                          </Box>
                        </Flex>
                      </ListItem>
                    )
                  ))}
                </List>

              </ModalBody>
            </VStack>
            <VStack spacing={4}>
              <Input
                placeholder="Player Name"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
              />
              <PhoneInput
                placeholder="Player Mobile"
                value={playerMobile}
                required country={"in"}
                onChange={setPlayerMobile}/>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddPlayer}>
              Add Player
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isConfirmModalOpen} onClose={onConfirmModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Add Player</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Do you want to add {selectedPlayer?.name}?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleConfirmAddPlayer}>
              Yes
            </Button>
            <Button variant="ghost" onClick={onConfirmModalClose}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isConfirmModalOpenIW} onClose={onConfirmModalCloseIW}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Add Player IW</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Do you want to add {selectedPlayer?.name}?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleConfirmAddPlayerIW}>
              Yes
            </Button>
            <Button variant="ghost" onClick={onConfirmModalClose}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Modal>
  );
};

export default ManageEventModal;
