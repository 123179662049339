import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { API_URL } from "../../../utils/apiConfig";
import { useState, useEffect, useMemo } from "react";

function PlayerGridDisplay({ eventData }) {
  const [avatars, setAvatars] = useState({});

  // Memoize the list of players to prevent unnecessary re-renders
  const players = useMemo(() => {
    return Object.keys(eventData)
      .filter(key => key.startsWith("player"))
      .map(key => eventData[key]);
  }, [eventData]);

  useEffect(() => {
    // Fetch avatar data for all players
    async function fetchAvatars() {
      const avatarPromises = players.map(async (player) => {
        try {
          const response = await fetch(`${API_URL}/api/athlete/athletes/${player.email}`);
          const athlete = await response.json(); // Assuming response.data contains the athlete object
          return {
            [player.email]: athlete.aadhaarData?.photoUrl
              ? `data:image/jpeg;base64,${athlete.aadhaarData.photoUrl}`
              : null, // Set to null if no photoUrl
          };
        } catch (error) {
          console.error(`Failed to fetch avatar for ${player.email}`, error);
          return { [player.email]: null }; // Fallback in case of error
        }
      });

      // Resolve all promises and update the avatars state
      const avatarData = await Promise.all(avatarPromises);
      const avatarMap = avatarData.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      setAvatars(avatarMap);
    }

    // Fetch avatars only if there are players
    if (players.length > 0) {
      fetchAvatars();
    }
  }, [players]);

  // Determine dynamic width based on the number of players
  const getPlayerWidth = (count) => {
    if (count === 2) return "45%";
    if (count === 3) return "30%";
    if (count === 4) return "22%"; // Adjusted for spacing and max players
    return "100%"; // Default for one player or edge cases
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: "100%" }}>
      {players.map((player, index) => (
        <div
          key={index}
          style={{
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "2px",
            textAlign: "center",
            width: getPlayerWidth(players.length <= 4 ? players.length : 4), // Limit width calculation to max 4 players per row
            margin: "10px 0", // Spacing between rows
          }}
        >
          <Flex flex={1} flexWrap={"wrap"} gap={"1rem"}>
            <Box margin={"auto"} >
              <Avatar
                size="md"
                m={"auto"}
                name={player.name}
                src={
                  avatars[player.email] ||
                  `https://via.placeholder.com/150?text=No+Photo` // Placeholder image if no photo is available
                }
                bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
              /><Text color={"white"} fontSize={"10px"} fontWeight={"bold"}>{player.name.split(' ')[0]}</Text>
            </Box>
          </Flex>
        </div>
      ))}
    </div>
  );
}

export default PlayerGridDisplay;
