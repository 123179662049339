import React, { useState } from "react";
import { Box, Heading, FormControl, FormLabel, Input, Button, Select, VStack, useToast } from "@chakra-ui/react";
import axios from "axios";
import { API_URL } from "../../utils/apiConfig";
import { useParams, useNavigate } from 'react-router-dom';

const AddEventDetails = () => {
    const { eventId } = useParams();
  const [teamName, setTeamName] = useState("");
  const [supervisorName, setSupervisorName] = useState("");
  const [supervisorEmail, setSupervisorEmail] = useState("");
  const [judgeName, setJudgeName] = useState("");
  const [judgeEmail, setJudgeEmail] = useState("");
  const [category, setCategory] = useState(""); // E.g., "MensPair", "WomensPair"
  const toast = useToast();

  const handleAddTeam = async () => {
    try {
      const payload = {
        teams: [{ teamName }],
      };
      await axios.patch(`${API_URL}/api/events-aerobic/${eventId}`, payload);
      toast({ title: "Team added successfully!", status: "success", duration: 3000 });
      setTeamName("");
    } catch (error) {
      toast({ title: "Error adding team", description: error.message, status: "error", duration: 3000 });
    }
  };

  const handleAddSupervisor = async () => {
    try {
      const payload = {
        supervisors: [
          {
            name: supervisorName,
            email: supervisorEmail,
          },
        ],
      };
      await axios.patch(`${API_URL}/api/events-aerobic/${eventId}`, payload);
      toast({ title: "Supervisor added successfully!", status: "success", duration: 3000 });
      setSupervisorName("");
      setSupervisorEmail("");
    } catch (error) {
      toast({ title: "Error adding supervisor", description: error.message, status: "error", duration: 3000 });
    }
  };

  const handleAddJudge = async () => {
    try {
      const payload = {
        judges: {
          [category]: [
            {
              name: judgeName,
              email: judgeEmail,
            },
          ],
        },
      };
      await axios.patch(`${API_URL}/api/events-aerobic/${eventId}`, payload);
      toast({ title: "Judge added successfully!", status: "success", duration: 3000 });
      setJudgeName("");
      setJudgeEmail("");
      setCategory("");
    } catch (error) {
      toast({ title: "Error adding judge", description: error.message, status: "error", duration: 3000 });
    }
  };

  return (
    <Box maxW="600px" mx="auto" mt={8}>
      <Heading size="lg" mb={4}>
        Add Teams, Supervisors, and Judges
      </Heading>

      {/* Add Team Section */}
      <VStack spacing={4} align="stretch" mb={8}>
        <FormControl>
          <FormLabel>Team Name</FormLabel>
          <Input
            placeholder="Enter team name"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
          />
        </FormControl>
        <Button colorScheme="blue" onClick={handleAddTeam}>
          Add Team
        </Button>
      </VStack>

      {/* Add Supervisor Section */}
      <VStack spacing={4} align="stretch" mb={8}>
        <FormControl>
          <FormLabel>Supervisor Name</FormLabel>
          <Input
            placeholder="Enter supervisor name"
            value={supervisorName}
            onChange={(e) => setSupervisorName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Supervisor Email</FormLabel>
          <Input
            placeholder="Enter supervisor email"
            value={supervisorEmail}
            onChange={(e) => setSupervisorEmail(e.target.value)}
          />
        </FormControl>
        <Button colorScheme="blue" onClick={handleAddSupervisor}>
          Add Supervisor
        </Button>
      </VStack>

      {/* Add Judge Section */}
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel>Judge Name</FormLabel>
          <Input
            placeholder="Enter judge name"
            value={judgeName}
            onChange={(e) => setJudgeName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Judge Email</FormLabel>
          <Input
            placeholder="Enter judge email"
            value={judgeEmail}
            onChange={(e) => setJudgeEmail(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Category</FormLabel>
          <Select
            placeholder="Select category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="MensPair">Mens Pair</option>
            <option value="WomensPair">Womens Pair</option>
            <option value="MixedPair">Mixed Pair</option>
            <option value="Trio">Trio</option>
            <option value="Group">Group</option>
            <option value="AerobicDance">Aerobic Dance</option>
            <option value="AerobicStep">Aerobic Step</option>
          </Select>
        </FormControl>
        <Button colorScheme="blue" onClick={handleAddJudge}>
          Add Judge
        </Button>
      </VStack>
    </Box>
  );
};

export default AddEventDetails;
