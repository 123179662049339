import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../../utils/apiConfig';
import PlayerPhoto from './PlayerPhoto';
import { Box, Flex, Grid, GridItem, Heading, HStack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import PlayerGridDisplay from './PlayerGrid2';
const LiveDisplay = () => {
    const { eventId, game } = useParams();

    const [teams, setTeams] = useState([]);
    const [event, setEvent] = useState([]);
    const [listPlayers, setListPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [displayedScores, setDisplayedScores] = useState({});
    const socketRef = useRef();

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const approvedPlayersResponse = await axios.get(`${API_URL}/api/approved-players-spot-aerobic/${eventId}/aeroTypes/${game}`);
                const eventData = approvedPlayersResponse.data;
                console.log("eventData", eventData)
                
                const sortedAndRankedPlayers = sortAndRankPlayers(eventData.filteredTeams);
                    setListPlayers(sortedAndRankedPlayers);

                const livePlayersResponse = await axios.get(`${API_URL}/api/events-aerobic/${eventId}/aeroTypes/${game}/live-display`);
                setTeams(livePlayersResponse.data.liveTeams);
                setEvent(livePlayersResponse.data.liveTeams[0]);
                console.log("Live res", livePlayersResponse.data.liveTeams[0])

                
                // setListPlayers(eventData.filteredTeams)
            } catch (error) {
                console.error('Error fetching initial data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();

        // Initialize Socket.IO
        const socket = io(`${API_URL}`);
        socketRef.current = socket;

        socket.on('displayTeam', ({ eventId: updatedEventId, teamName, aeroType, updatedTeam }) => {
            if (updatedEventId === eventId && aeroType === game) {
                setTeams((prevTeams) => {
                    const teamExists = prevTeams.some((team) => team.teamName === teamName);
                    if (teamExists) {
                        // Update existing team
                        return prevTeams.map((team) =>
                            team.teamName === teamName ? { ...team, ...updatedTeam } : team
                        );
                    } else {
                        // Add new team
                        return [...prevTeams, updatedTeam];
                    }
                });
                fetchInitialData();
            }
        });
        

        // socket.on('teamStatusUpdated', ({ eventId: updatedEventId, teamName, aeroType, updatedTeam }) => {
        //     if (updatedEventId === eventId && aeroType === game) {
        //         setTeams((prevTeams) =>
        //             prevTeams.map((team) =>
        //                 team.teamName === teamName ? { ...team, ...updatedTeam } : team
        //             )
        //         );
        //     }
        // });

        socket.on('teamStatusUpdated', ({ eventId: updatedEventId, aeroType }) => {
            if (updatedEventId === eventId && aeroType === game) {
                fetchInitialData();
            }
        });
        

        socket.on('displayTeamScores', ({ eventId: updatedEventId, teamName, aeroType, updatedTeam }) => {
            if (updatedEventId === eventId && aeroType === game) {
                setTeams((prevTeams) =>
                    prevTeams.map((team) =>
                        team.teamName === teamName ? { ...team, ...updatedTeam } : team
                    )
                );
            }
        });

        socket.on('removeTeam', ({ eventId: updatedEventId, teamName, aeroType }) => {
            if (updatedEventId === eventId && aeroType === game) {
                setTeams((prevTeams) => prevTeams.filter((team) => team.teamName !== teamName));
            }
        });

    // Handle approved team updates
    // socket.on('teamApproved', (data) => {
    //     if (data.eventId === eventId && data.aeroType === game) {
    //         const teamName = data.teamName;
    //         setPlayers((prevPlayers) => {
    //             const teamExists = prevPlayers.some(
    //                 (team) => team.teamName === data.teamName
    //             );
    //             if (teamExists) {
    //                 return prevPlayers.map((team) =>
    //                     team.teamName === data.teamName
    //                         ? data.updatedTeam
    //                         : team
    //                 );
    //             }
    //             return [...prevPlayers, data.updatedTeam, data.teamName];
    //         });
    //     }
    // });
        
        return () => socket.disconnect();
    }, [eventId, game]);

    const sortAndRankPlayers = (players) => {
        const sortedPlayers = players.sort((a, b) => {
            if ((b.aeroTypeData.scores.total || 0) !== (a.aeroTypeData.scores.total || 0)) {
                return (b.aeroTypeData.scores.total || 0) - (a.aeroTypeData.scores.total || 0);
            }
            if ((b.aeroTypeData.scores.e || 0) !== (a.aeroTypeData.scores.e || 0)) {
                return (b.aeroTypeData.scores.e || 0) - (a.aeroTypeData.scores.e || 0);
            }
            if ((b.aeroTypeData.scores.d1 || 0) !== (a.aeroTypeData.scores.d1 || 0)) {
                return (b.aeroTypeData.scores.d1 || 0) - (a.aeroTypeData.scores.d1 || 0);
            }
            return (a.aeroTypeData.scores.penalty || 0) - (b.aeroTypeData.scores.penalty || 0);
        });

        let currentRank = 1;
        let currentTotalScore = sortedPlayers[0]?.aeroTypeData.scores.total || 0;

        sortedPlayers.forEach((player, index) => {
            if (player.aeroTypeData.scores.total !== currentTotalScore) {
                currentRank = index + 1;
                currentTotalScore = player.aeroTypeData.scores.total;
            }
            player.rank = currentRank;
        });

        return sortedPlayers;
    };

    const firstFiveRows = listPlayers.slice(0, 5);
    const lastFiveRows = listPlayers.slice(5, 10);

    return (
        <Box backgroundColor={"black"}>
        <Box bgGradient="linear(to-b, #AD0026, #A30033, #990040, #8F004C, #850059, #7A0066, #700073, #660080, #5C008C, #520099, #4700A6, #3D00B2, #3300BF, #2900CC, #1F00D9, #1400E6)" w={"130vh"} height={"100vh"} border={"1px solid black"} m={"auto"}>
            <Box w={"98%"} borderRadius={"10px"} m={"auto"} mb={2}border={"1px solid gray"} boxShadow = {"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"}>
                <Text textAlign={"center"} fontWeight={"bold"} fontSize={"3xl"} color={"white"}>AEROBIC NATIONAL 2025</Text>
                <Text textAlign={"center"}  color={"white"}>19TH Aerobic National Champitionship</Text>
                <Text textAlign={"center"} color={"white"}>MA Stadium, Jammu & Kashmir</Text>
            </Box>
            <Flex flex={1} height={"30vh"} justifyContent={"space-evenly"}>
            <Box border={"1px solid gray"} boxShadow = {"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} borderRadius={"10px"} w={"35%"}>
                <Box mb={4} mt={1}>
                    <Text textAlign={"center"} color={"white"} fontSize={"2xl"} fontWeight={"bold"}>{game === "MensPair" ? "INDIVIDUAL MEN" : game === "WomensPair"?"INDIVIDUAL WOMEN": game}</Text>
                </Box>

            {teams.length > 0 ? (
                <ul>
                    {teams.map(player => (
                        <>
                <Grid mt={2}
        templateColumns="repeat(3, 1fr)" // 3 columns in the first row
        gap={4} // Space between boxes
      >
        {/* 1st row (3 boxes) */}
        <GridItem colSpan={1} >
            <Text textAlign={"center"} color={"white"} size={"md"} fontWeight={"bold"}>DIFFICULTY</Text>
          <Box fontWeight={"bold"}  p={"auto"} color={"white"} textAlign="center"  >
          {player.scores?.d || 'ND'}
          </Box>
        </GridItem>
        <GridItem colSpan={1} >
            <Text textAlign={"center"} color={"white"} size={"md"} fontWeight={"bold"}>ARTISTRY</Text>
          <Box fontWeight={"bold"} p={"auto"} color={"white"} textAlign="center"  >
          {player.scores?.a || 'ND'}
          </Box>
        </GridItem>
        <GridItem colSpan={1} >
            <Text textAlign={"center"} color={"white"} size={"md"} fontWeight={"bold"}>EXECUTION</Text>
          <Box fontWeight={"bold"} p={"auto"} color={"white"} textAlign="center"  >
          {player.scores?.e || 'ND'}
          </Box>
        </GridItem>

        {/* 2nd row (2 boxes centered) */}
        
                </Grid>
                <Grid templateColumns="repeat(3, 1fr)" >
        <GridItem colSpan={1} mt={5} >
            <Text textAlign={"center"} color={"white"} size={"md"}  fontWeight={"bold"}>PENALTY</Text>
          <Box fontWeight={"bold"} p={"auto"} color={"white"} textAlign="center"  >
          {player.scores?.penalty || 'ND'}
          </Box>
        </GridItem>
        <GridItem colSpan={1} mt={5} >
            <Text textAlign={"center"} color={"white"} size={"md"}  fontWeight={"bold"}>CJP P</Text>
          <Box fontWeight={"bold"} p={"auto"} color={"white"} textAlign="center"  >
          {player.scores?.cjp || 'ND'}
          </Box>
        </GridItem>
        <GridItem colSpan={1} mt={5} >
            <Text textAlign={"center"} color={"white"} size={"md"}  fontWeight={"bold"}>TOTAL</Text>
          <Box fontWeight={"bold"} p={"auto"} color={"white"} textAlign="center"  >
          {player.scores?.total || 'ND'}
          </Box>
        </GridItem>
        </Grid>
                </>
                    ))}
                </ul>
            ) : (
                <p>No players are currently playing.</p>
            )}
            </Box>
            <Box border={"1px solid gray"} boxShadow = {"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} borderRadius={"10px"} backgroundColor={""} w={"63%"}  >
            {teams.length > 0 ? (
                <ul>
                    {teams.map(player => (
                        <>
                        
                        <Box >
                            {/* <PlayerPhoto email={player.email}/> */}
                        </Box>
                        <Box m={"auto"}>
                            <Text fontSize={"3xl"} textAlign={"center"} color={"white"} key={player._id}>
                            {player.teamName}</Text>
                        </Box>
                        <Box>
                            <PlayerGridDisplay eventData={event}/>
                        </Box>
                        </>
                    ))}
                </ul>
            ) : (
                <p>No players are currently playing.</p>
            )}
            </Box>
            </Flex>
            <br/>
            <Box>
            <HStack spacing={8} align="start">
        {/* Left side Table for first 5 rows */}
        <Box width="49%">
          <Table borderRadius={"10px"}  boxShadow = {"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} variant="simple" width="100%">
            <Thead>
              <Tr>
                <Th color={"white"}>Rank</Th>
                <Th color={"white"}>Name</Th>
                {/* <Th color={"white"}>Age</Th> */}
                <Th color={"white"}>Final Score</Th>
              </Tr>
            </Thead>
            <Tbody>
              {firstFiveRows.map((row, index) => (
                <Tr key={row.id}>
                  <Td fontSize={"12px"} color={"white"}>{index+1}</Td>
                  <Td fontSize={"12px"} color={"white"}>{row.teamName}</Td>
                  {/* <Td color={"white"}>{row.age}</Td> */}
                  <Td fontSize={"12px"} color={"white"}>{row.aeroTypeData.scores.total}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        {/* Right side Table for last 5 rows */}
        <Box width="49%">
        <Table borderRadius={"10px"}  boxShadow = {"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"} variant="simple" width="100%">
            <Thead>
              <Tr>
                <Th color={"white"}>Rank</Th>
                <Th color={"white"}>Name</Th>
                {/* <Th color={"white"}>Age</Th> */}
                <Th color={"white"}>Final Score</Th>
              </Tr>
            </Thead>
            <Tbody>
              {lastFiveRows.map((row, index) => (
                <Tr key={row.id}>
                  <Td fontSize={"12px"} color={"white"}>{index+1}</Td>
                  <Td fontSize={"12px"} color={"white"}>{row.teamName}</Td>
                  {/* <Td color={"white"}>{row.age}</Td> */}
                  <Td fontSize={"12px"} color={"white"}>{row.aeroTypeData.scores.total}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </HStack>
            </Box>
        </Box>
        </Box>
    );
};

export default LiveDisplay;
