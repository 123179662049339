// // AuthContext.js
// import React, { createContext, useContext, useEffect, useState } from 'react';
// import { auth } from '../firebase.config';
// import { onAuthStateChanged, signOut } from 'firebase/auth';
// import axios from 'axios'; // Assuming you use axios to fetch events data
// import {API_URL} from "../utils/apiConfig";

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [userRole, setUserRole] = useState(null); //Track the user role
//   const [userSupervisorRole, setSupervisorRole] = useState(null);
//   const [userCompetitionAdmin, setUserCompetitionAdmin] = useState(null);
//   const [athleteRole, setAthleteRole] = useState(null);
//   const [profileImageUrl, setProfileImageUrl] = useState('');
//   const [isAdmin, setIsAdmin] = useState(false); 

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (user) => {
//       setLoading(true);
//       if (user) {
//         setUser(user);
//         const adminStatus = await checkIfUserIsAdmin(user);
//         setIsAdmin(adminStatus);
//         const isJudge = await checkIfUserIsJudge(user);
//         const isSupervisor =  await checkIfUserIsSupervisor(user);
//         const isJudgeAerobic = await checkIfUserIsJudgeAerobics(user);
//         const isSupervisorAerobic = await checkIfUserIsSupervisorAerobics(user)
//         const isCompetitionAdmin = await checkIfUserIsCompetitionAdmin(user);
//         const isAthlete = await checkIfUserIsAthlete(user);
//         const isUserJudge = isJudge || isJudgeAerobic;
//         const isUserSupervisor = isSupervisor || isSupervisorAerobic;
//         setUserRole(isUserJudge ? 'judge' : 'player');
//         setSupervisorRole(isUserSupervisor ? 'supervisor' : 'player');
        

//         setUserCompetitionAdmin(isCompetitionAdmin? 'competitionAdmin' : 'player');
//         setAthleteRole(isAthlete ? 'athlete' : 'player');
//         const userData = await fetchUserData(user);
//         setUser(userData);
//         setProfileImageUrl(userData.profileImage);
//       } else {
//         setUser(null);
//         setUserRole(null);
//         setSupervisorRole(null);
//         setUserCompetitionAdmin(null);
//         setAthleteRole(null);
//         setIsAdmin(false);
//         setProfileImageUrl('');
//       }
//       setLoading(false);
//     });

//     return () => unsubscribe();
//   }, []);


//   // Function to check if the user is a judge based on their email or mobile number
//   const checkIfUserIsJudge = async (user) => {
//     try {
//       const response = await axios.get(`${API_URL}/api/events-judges`); // Update with your API endpoint
//       const events = response.data;

//       for (const event of events) {
//         if (event.judges) { // Check if the judges property exists
//           const judgeKeys = Object.keys(event.judges);
//           for (const key of judgeKeys) {
//             const judges = event.judges[key];
//             if (judges) {
//               const judgeValues = Object.values(judges);
//               for (const judge of judgeValues) {
//                 if (judge && (judge.mobile === user.phoneNumber || judge.email === user.email)) {
//                   return true;
//                 }
//               }
//             }
//           }
//         }
//       }
//     } catch (error) {
//       console.error('Error fetching events:', error);
//     }
//     return false;
//   };

//   // Function to check if the user is a judge based on their email or mobile number
//   const checkIfUserIsJudgeAerobics = async (user) => {
//     try {
//       const response = await axios.get(`${API_URL}/api/events-judges-aerobic`); // Update with your API endpoint
//       const events = response.data;

//       for (const event of events) {
//         if (event.judges) { // Check if the judges property exists
//           const judgeKeys = Object.keys(event.judges);
//           for (const key of judgeKeys) {
//             const judges = event.judges[key];
//             if (judges) {
//               const judgeValues = Object.values(judges);
//               for (const judge of judgeValues) {
//                 if (judge && (judge.mobile === user.phoneNumber || judge.email === user.email)) {
//                   return true;
//                 }
//               }
//             }
//           }
//         }
//       }
//     } catch (error) {
//       console.error('Error fetching events:', error);
//     }
//     return false;
//   };


//   // Function to check if the user is a judge based on their email or mobile number
//   const checkIfUserIsSupervisor = async (user) => {
//     try {
//       const response = await axios.get(`${API_URL}/api/events-supervisor`); // Update with your API endpoint
//       const events = response.data;

//       for (const event of events) {
//         if (event.supervisors) { // Check if the judges property exists
//           const supervisorKeys = Object.keys(event.supervisors);
//           for (const key of supervisorKeys) {
//             const supervisors = event.supervisors[key];
//             if (supervisors) {
//               const supervisorValues = Object.values(supervisors);
//               for (const supervisor of supervisorValues) {
//                 if (supervisor && (supervisor.mobile === user.phoneNumber || supervisor.email === user.email)) {
//                   return true;
//                 }
//               }
//             }
//           }
//         }
//       }
//     } catch (error) {
//       console.error('Error fetching events:', error);
//     }
//     return false;
//   };

//   // Function to check if the user is a judge based on their email or mobile number
//   const checkIfUserIsSupervisorAerobics = async (user) => {
//     try {
//       const response = await axios.get(`${API_URL}/api/events-supervisor-aerobic`); // Update with your API endpoint
//       const events = response.data;

//       for (const event of events) {
//         if (event.supervisors) { // Check if the judges property exists
//           const supervisorKeys = Object.keys(event.supervisors);
//           for (const key of supervisorKeys) {
//             const supervisors = event.supervisors[key];
//             if (supervisors) {
//               const supervisorValues = Object.values(supervisors);
//               for (const supervisor of supervisorValues) {
//                 if (supervisor && (supervisor.mobile === user.phoneNumber || supervisor.email === user.email)) {
//                   return true;
//                 }
//               }
//             }
//           }
//         }
//       }
//     } catch (error) {
//       console.error('Error fetching events:', error);
//     }
//     return false;
//   };

//   const checkIfUserIsCompetitionAdmin = async (user) => {
//     try {
//       const response = await axios.get(`${API_URL}/api/events-compadmin`); // Update with your API endpoint
//       const events = response.data;

//       for (const event of events) {
//         if (event.event_admin && (event.event_admin === user.email)) {
//           return true;
//         }
//       }
//     } catch (error) {
//       console.error('Error fetching events:', error);
//     }
//     return false;
//   };

//   const checkIfUserIsAthlete = ()=>{
    
//   }

//   const checkIfUserIsAdmin = async (user) => {
//     try {
//       const response = await axios.get(`${API_URL}/api/admin/superadmin/admins`);
//       const admins = response.data;
//       return admins.some(admin => admin.email === user.email);
//     } catch (error) {
//       console.error('Error fetching admin data:', error);
//       return false;
//     }
//   };



//   const logout = async () => {
//     setLoading(true);
//     try {
//       await signOut(auth);
//       setUser(null);
//       setUserRole(null);
//       setSupervisorRole(null);
//       setUserCompetitionAdmin(null);
//       setProfileImageUrl('');
//       setIsAdmin(false);
//     } catch (error) {
//       console.error('Error logging out:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchUserData = async (user) => {
//     try {
//       // First, try to fetch the user data from the "auth" endpoint
//       let response = await axios.get(`${API_URL}/api/auth/users/${user.email}`);
//       if (response.data) {
//         return response.data;
//       }
//     } catch (error) {
//       console.error('User not found in auth endpoint:', error);
//     }
  
//     try {
//       // If not found, try to fetch from the "athletes" endpoint
//       let response = await axios.get(`${API_URL}/api/athlete/athletes/${user.email}`);
//       if (response.data) {
//         return response.data;
//       }
//     } catch (error) {
//       console.error('User not found in athletes endpoint:', error);
//     }
  
//     try {
//       // If not found, try to fetch from the "admins/superadmins" endpoint
//       let response = await axios.get(`${API_URL}/api/admin/superadmin/admins/${user.email}`);
//       if (response.data) {
//         return response.data;
//       }
//     } catch (error) {
//       console.error('User not found in admins/superadmins endpoint:', error);
//       return null;
//     }
//   };
  

//   return (
//     <AuthContext.Provider value={{ user, loading, logout, userRole, userSupervisorRole, userCompetitionAdmin, profileImageUrl , isAdmin}}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   return useContext(AuthContext);
// };













import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase.config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios'; // Assuming you use axios to fetch events data
import { API_URL } from "../utils/apiConfig";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null); // Track the user role
  const [userSupervisorRole, setSupervisorRole] = useState(null);
  const [userCompetitionAdmin, setUserCompetitionAdmin] = useState(null);
  const [athleteRole, setAthleteRole] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  // Load user data from localStorage on initialization
  useEffect(() => {
    const savedUser = localStorage.getItem('user');
    const savedUserRole = localStorage.getItem('userRole');
    const savedSupervisorRole = localStorage.getItem('userSupervisorRole');
    const savedCompetitionAdmin = localStorage.getItem('userCompetitionAdmin');
    const savedAthleteRole = localStorage.getItem('athleteRole');
    const savedIsAdmin = localStorage.getItem('isAdmin') === 'true';
    const savedProfileImageUrl = localStorage.getItem('profileImageUrl');

    if (savedUser) {
      setUser(JSON.parse(savedUser));
      setUserRole(savedUserRole);
      setSupervisorRole(savedSupervisorRole);
      setUserCompetitionAdmin(savedCompetitionAdmin);
      setAthleteRole(savedAthleteRole);
      setIsAdmin(savedIsAdmin);
      setProfileImageUrl(savedProfileImageUrl);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        const userData = await fetchUserData(user);
        const adminStatus = await checkIfUserIsAdmin(user);
        const isJudge = await checkIfUserIsJudge(user);
        const isSupervisor = await checkIfUserIsSupervisor(user);
        const isCompetitionAdmin = await checkIfUserIsCompetitionAdmin(user);
        const isAthlete = await checkIfUserIsAthlete(user);

        setUser(userData);
        setUserRole(isJudge ? 'judge' : 'player');
        setSupervisorRole(isSupervisor ? 'supervisor' : 'player');
        setUserCompetitionAdmin(isCompetitionAdmin ? 'competitionAdmin' : 'player');
        setAthleteRole(isAthlete ? 'athlete' : 'player');
        setIsAdmin(adminStatus);
        setProfileImageUrl(userData.profileImage);

        // Save to localStorage
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('userRole', isJudge ? 'judge' : 'player');
        localStorage.setItem('userSupervisorRole', isSupervisor ? 'supervisor' : 'player');
        localStorage.setItem('userCompetitionAdmin', isCompetitionAdmin ? 'competitionAdmin' : 'player');
        localStorage.setItem('athleteRole', isAthlete ? 'athlete' : 'player');
        localStorage.setItem('isAdmin', adminStatus);
        localStorage.setItem('profileImageUrl', userData.profileImage);
      } else {
        clearLocalStorage();
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);
    // Function to check if the user is a judge based on their email or mobile number
  const checkIfUserIsJudge = async (user) => {
    try {
      const response = await axios.get(`${API_URL}/api/events-judges`); // Update with your API endpoint
      const events = response.data;

      for (const event of events) {
        if (event.judges) { // Check if the judges property exists
          const judgeKeys = Object.keys(event.judges);
          for (const key of judgeKeys) {
            const judges = event.judges[key];
            if (judges) {
              const judgeValues = Object.values(judges);
              for (const judge of judgeValues) {
                if (judge && (judge.mobile === user.phoneNumber || judge.email === user.email)) {
                  return true;
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
    return false;
  };

  // Function to check if the user is a judge based on their email or mobile number
  const checkIfUserIsJudgeAerobics = async (user) => {
    try {
      const response = await axios.get(`${API_URL}/api/events-judges-aerobic`); // Update with your API endpoint
      const events = response.data;

      for (const event of events) {
        if (event.judges) { // Check if the judges property exists
          const judgeKeys = Object.keys(event.judges);
          for (const key of judgeKeys) {
            const judges = event.judges[key];
            if (judges) {
              const judgeValues = Object.values(judges);
              for (const judge of judgeValues) {
                if (judge && (judge.mobile === user.phoneNumber || judge.email === user.email)) {
                  return true;
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
    return false;
  };


  // Function to check if the user is a judge based on their email or mobile number
  const checkIfUserIsSupervisor = async (user) => {
    try {
      const response = await axios.get(`${API_URL}/api/events-supervisor`); // Update with your API endpoint
      const events = response.data;

      for (const event of events) {
        if (event.supervisors) { // Check if the judges property exists
          const supervisorKeys = Object.keys(event.supervisors);
          for (const key of supervisorKeys) {
            const supervisors = event.supervisors[key];
            if (supervisors) {
              const supervisorValues = Object.values(supervisors);
              for (const supervisor of supervisorValues) {
                if (supervisor && (supervisor.mobile === user.phoneNumber || supervisor.email === user.email)) {
                  return true;
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
    return false;
  };

  // Function to check if the user is a judge based on their email or mobile number
  const checkIfUserIsSupervisorAerobics = async (user) => {
    try {
      const response = await axios.get(`${API_URL}/api/events-supervisor-aerobic`); // Update with your API endpoint
      const events = response.data;

      for (const event of events) {
        if (event.supervisors) { // Check if the judges property exists
          const supervisorKeys = Object.keys(event.supervisors);
          for (const key of supervisorKeys) {
            const supervisors = event.supervisors[key];
            if (supervisors) {
              const supervisorValues = Object.values(supervisors);
              for (const supervisor of supervisorValues) {
                if (supervisor && (supervisor.mobile === user.phoneNumber || supervisor.email === user.email)) {
                  return true;
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
    return false;
  };

  const checkIfUserIsCompetitionAdmin = async (user) => {
    try {
      const response = await axios.get(`${API_URL}/api/events-compadmin`); // Update with your API endpoint
      const events = response.data;

      for (const event of events) {
        if (event.event_admin && (event.event_admin === user.email)) {
          return true;
        }
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
    return false;
  };

  const checkIfUserIsAthlete = ()=>{
    
  }

  const checkIfUserIsAdmin = async (user) => {
    try {
      const response = await axios.get(`${API_URL}/api/admin/superadmin/admins`);
      const admins = response.data;
      return admins.some(admin => admin.email === user.email);
    } catch (error) {
      console.error('Error fetching admin data:', error);
      return false;
    }
  };

  const fetchUserData = async (user) => {
    try {
      // First, try to fetch the user data from the "auth" endpoint
      let response = await axios.get(`${API_URL}/api/auth/users/${user.email}`);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.error('User not found in auth endpoint:', error);
    }
  
    try {
      // If not found, try to fetch from the "athletes" endpoint
      let response = await axios.get(`${API_URL}/api/athlete/athletes/${user.email}`);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.error('User not found in athletes endpoint:', error);
    }
  
    try {
      // If not found, try to fetch from the "admins/superadmins" endpoint
      let response = await axios.get(`${API_URL}/api/admin/superadmin/admins/${user.email}`);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.error('User not found in admins/superadmins endpoint:', error);
      return null;
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userSupervisorRole');
    localStorage.removeItem('userCompetitionAdmin');
    localStorage.removeItem('athleteRole');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('profileImageUrl');
  };

  const logout = async () => {
    setLoading(true);
    try {
      await signOut(auth);
      setUser(null);
      setUserRole(null);
      setSupervisorRole(null);
      setUserCompetitionAdmin(null);
      setProfileImageUrl('');
      setIsAdmin(false);
      clearLocalStorage();
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      setLoading(false);
    }
  };




  return (
    <AuthContext.Provider value={{ user, loading, logout, userRole, userSupervisorRole, userCompetitionAdmin, profileImageUrl, isAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
