import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import JudgeDashboard from '../JudgeDashboard'
import JudgeDashboardAerobics from '../Aerobics/JudgeDashboard';

const JudgeDashboardTab = () => {
  return (
    <div>
        <Tabs>
            <TabList>
            <Tab>Aerobic</Tab>
                <Tab>Artistic</Tab>
                
            </TabList>
            <TabPanels>
            <TabPanel>
                    <JudgeDashboardAerobics/>
                </TabPanel>
                <TabPanel>
                    <JudgeDashboard/>
                </TabPanel>
                
            </TabPanels>
        </Tabs>
    </div>
  )
}

export default JudgeDashboardTab