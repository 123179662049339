import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { API_URL } from "../../../utils/apiConfig";
import { useState, useEffect, useMemo } from "react";

function PlayerGrid({ eventData }) {
  const [avatars, setAvatars] = useState({});
  const [fetchedEmails, setFetchedEmails] = useState(new Set()); // Tracks fetched emails

  // Memoize the players list to avoid unnecessary recalculations
  const players = useMemo(() => {
    return Object.keys(eventData)
      .filter(key => key.startsWith("player")) // Find keys starting with "player"
      .map(key => eventData[key]); // Return the corresponding player objects
  }, [eventData]);

  useEffect(() => {
    // Fetch avatar data for players that don't already have an avatar
    async function fetchAvatars() {
      const emailsToFetch = players
        .filter(player => !fetchedEmails.has(player.email)) // Only fetch if not already fetched
        .map(player => player.email);

      if (emailsToFetch.length === 0) return; // No new emails to fetch

      // Update fetchedEmails immediately to prevent duplicate requests
      setFetchedEmails(prev => new Set([...prev, ...emailsToFetch]));

      const avatarPromises = emailsToFetch.map(async (email) => {
        try {
          const response = await fetch(`${API_URL}/api/athlete/athletes/${email}`);
          const athlete = await response.json(); // Assuming response contains the athlete object
          return {
            [email]: athlete.aadhaarData?.photoUrl
              ? `data:image/jpeg;base64,${athlete.aadhaarData.photoUrl}`
              : null, // Set to null if no photoUrl
          };
        } catch (error) {
          console.error(`Failed to fetch avatar for ${email}`, error);
          return { [email]: null }; // Fallback in case of error
        }
      });

      // Resolve all promises and update the avatars state
      const avatarData = await Promise.all(avatarPromises);
      setAvatars(prev => ({
        ...prev,
        ...avatarData.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      }));
    }

    fetchAvatars();
  }, [players, fetchedEmails]);

  // Determine dynamic width based on the number of players
  const getPlayerWidth = (count) => {
    if (count === 2) return "45%";
    if (count === 3) return "30%";
    if (count === 4) return "22%"; // Adjusted for spacing and max players
    return "100%"; // Default for one player or edge cases
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: "100%" }}>
      {players.map((player, index) => (
        <div
          key={index}
          style={{
            border: "1px solid #ccc",
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "10px",
            textAlign: "center",
            width: getPlayerWidth(players.length <= 4 ? players.length : 4), // Limit width calculation to max 4 players per row
            margin: "10px 0", // Spacing between rows
          }}
        >
          <Flex flex={1} flexWrap={"wrap"} gap={"1rem"}>
            <Box>
              <Avatar
                size="md"
                name={player.name}
                src={
                  avatars[player.email] ||
                  `https://via.placeholder.com/150?text=No+Photo` // Placeholder image if no photo is available
                }
                bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
              />
            </Box>
            <Box textAlign={"start"}>
              <Text fontSize={"lg"} fontWeight={"bold"}>{player.name}</Text>
              <Text>Athlete Code: {player.athleteCode}</Text>
            </Box>
          </Flex>
        </div>
      ))}
    </div>
  );
}

export default PlayerGrid;
