import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../utils/apiConfig";
import { 
  Box, Text, Flex, Table, Th, Tr, Thead, Tbody, Td 
} from '@chakra-ui/react';

const TeamListPage = () => {
  const { eventId, game } = useParams();
  const navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [event, setEvent] = useState({});

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/events-aerobic/${eventId}`);
        const eventDetails = response.data;

        setEvent(eventDetails);

        const allTeams = eventDetails.teams || [];
        const filteredTeams = allTeams.filter(
          (team) => team.aeroTypes?.[game]?.status // Ensures the nested property exists
        );

        setTeams(filteredTeams);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    fetchTeams();
  }, [eventId, game]);

  const handleTeamClick = (teamName) => {
    navigate(`/eventAerobics/teamDetails/${eventId}/${game}/${teamName}`);
  };

  return (
    <Box p={4} minHeight={"100vh"}>
      <Box
        w={"95%"}
        background={"gray.700"}
        color={"white"}
        boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
        m={"auto"}
        borderRadius={"20px"}
      >
        <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-between"} alignItems={"center"}>
          <Box m={4} ml={"50px"}>
            <Text fontSize="lg"><b>Competition Name:</b> {event.eventName}</Text>
            <Text fontSize="lg"><b>Type:</b> {game}</Text>
          </Box>
          <Box mr={"50px"}>
            <Text fontSize="lg"><b>Competition Level:</b> {event.competitionLevel}</Text>
            <Text fontSize="lg"><b>Date:</b> {new Date(event.dateTime).toLocaleString()}</Text>
          </Box>
        </Flex>
      </Box>
      <br/>

      <Text fontSize={"22px"} textAlign={"center"} fontWeight={"bold"} >Teams in {game}</Text>
      <br/>
      <Table
        color={"black"}
        variant="striped"
        width={"90%"}
        margin={"auto"}
        borderRadius={"20px"}
        bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
        colorScheme="gray"
      >
        <Thead>
          <Tr>
            <Th color={"white"}>SR</Th>
            <Th color={"white"}>Name</Th>
            <Th color={"white"}>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {teams.map((team, index) => (
            <Tr
              key={team.teamName}
              onClick={() => handleTeamClick(team.teamName)}
              style={{ cursor: 'pointer' }}
            >
              <Td>{index + 1}</Td>
              <Td>{team.teamName}</Td>
              <Td
                style={{
                  color: team.aeroTypes?.[game]?.status === 'completed' 
                    ? 'green' 
                    : team.aeroTypes?.[game]?.status === 'playing' 
                    ? 'yellow' 
                    : 'red',
                  fontWeight: "bold",
                }}
              >
                {team.aeroTypes?.[game]?.status || "N/A"}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default TeamListPage;
