
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text, VStack, Spinner, Table, Th, Tr, Thead, Tbody, Td, Flex, useToast, Input } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../../../context/AuthContext';
import {API_URL} from "../../../utils/apiConfig";

const JudgePanelPlayerListAerobic = () => {
  const { eventId, game } = useParams();
  const { user } = useAuth();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [athletes, setAthletes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const toast = useToast();
  
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/eventss-aerobic/${eventId}/${game}`);
        const eventDetails = response.data;
        if (eventDetails) {
          setEvent(eventDetails.event);
          setAthletes(eventDetails.players);
        } else {
          console.error('Event not found');
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  useEffect(() => {
    if (event && game) {
      const players = event.players || [];
      setFilteredPlayers(
        players.filter(player =>
          player.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, event, game]);


  const getUserRoleForGame = (judges, game) => {
    const userEmail = user?.email;

    if (userEmail) {
        if (judges[game]) {
            const judge1Email = judges[game].judge1 ? extractEmail(judges[game].judge1.name) : null;
            console.log("judge1emaillllllllllllllllll", judge1Email)
            const judge2Email = judges[game].judge2 ? extractEmail(judges[game].judge2.name) : null;
            console.log("judge2emaillllllllllllllllll", judge2Email)
            const judge3Email = judges[game].judge3 ? extractEmail(judges[game].judge3.name) : null;
            console.log("judge3emaillllllllllllllllll", judge3Email)
            const judge4Email = judges[game].judge4 ? extractEmail(judges[game].judge4.name) : null;
            console.log("judge4emaillllllllllllllllll", judge4Email)
            const a1Email = judges[game].a1 ? extractEmail(judges[game].a1.name) : null;
            const a2Email = judges[game].a2 ? extractEmail(judges[game].a2.name) : null;
            const a3Email = judges[game].a3 ? extractEmail(judges[game].a3.name) : null;
            const a4Email = judges[game].a4 ? extractEmail(judges[game].a4.name) : null;
            const d1Email = judges[game].d1 ? extractEmail(judges[game].d1.name) : null;
            console.log("d1", d1Email)
            const d2Email = judges[game].d2 ? extractEmail(judges[game].d2.name) : null;
            console.log("d2", d2Email);
            const d3Email = judges[game].d3 ? extractEmail(judges[game].d3.name) : null;
            console.log("d3", d3Email)


            if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && a1Email === userEmail && a2Email === userEmail && a3Email === userEmail && a4Email === userEmail && d1Email === userEmail && d2Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge2, judge3, judge4, a1, a2, a3, a4, D1, D2 and Difficulty 1 Judge for game ${game}`);
                return 'all';
            }else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail && d2Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge2, judge3, judge4, D1, D2 and Difficulty 1  Judge for game ${game}`);
                return 'all-ejudge';
            }else if (a1Email === userEmail && a2Email === userEmail && a3Email === userEmail && a4Email === userEmail && d1Email === userEmail && d2Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is a1, a2, a3, a4, D1 ,D2 and Difficulty 1 Judge for game ${game}`);
                return 'all-ajudge';
            } else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge2, judge3, judge4 for game ${game}`);
                return 'j1234';
            } else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge2, judge3, D Judge for game ${game}`);
                return 'j123d1';
            } else if (judge1Email === userEmail && judge2Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge2, judge4, D Judge for game ${game}`);
                return 'j124d1';
            } else if (judge1Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge3, judge4, D Judge for game ${game}`);
                return 'j134d1';
            } else if (judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge2, judge3, judge4, D Judge for game ${game}`);
                return 'j234d1';
            }else if (a1Email === userEmail && a2Email === userEmail && a3Email === userEmail && a4Email === userEmail) {
                console.log(`User ${userEmail} is a1, a2, a3, a4 for game ${game}`);
                return 'a1234';
            } else if (a1Email === userEmail && a2Email === userEmail && a3Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a1, a2, a3, D Judge for game ${game}`);
                return 'a123d1';
            } else if (a1Email === userEmail && a2Email === userEmail && a4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a1, a2, a4, D Judge for game ${game}`);
                return 'a124d1';
            } else if (a1Email === userEmail && a3Email === userEmail && a4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a1, a3, a4, D Judge for game ${game}`);
                return 'a134d1';
            } else if (a2Email === userEmail && a3Email === userEmail && a4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a2, a3, a4, D Judge for game ${game}`);
                return 'a234d1';
            } else if (judge1Email === userEmail && judge2Email === userEmail && judge3Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge2, judge3 for game ${game}`);
                return 'j123';
            } else if (judge1Email === userEmail && judge2Email === userEmail && judge4Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge2, judge4 for game ${game}`);
                return 'j124';
            } else if (judge1Email === userEmail && judge2Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge2, D Judge for game ${game}`);
                return 'j12d1';
            } else if (judge1Email === userEmail && judge3Email === userEmail && judge4Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge3, judge4 for game ${game}`);
                return 'j134';
            } else if (judge1Email === userEmail && judge3Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge3, D Judge for game ${game}`);
                return 'j13d1';
            } else if (judge1Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge4, D Judge for game ${game}`);
                return 'j14d1';
            } else if (judge2Email === userEmail && judge3Email === userEmail && judge4Email === userEmail) {
                console.log(`User ${userEmail} is judge2, judge3, judge4 for game ${game}`);
                return 'j234';
            } else if (judge2Email === userEmail && judge3Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge2, judge3, D Judge for game ${game}`);
                return 'j23d1';
            } else if (judge2Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge2, judge4, D Judge for game ${game}`);
                return 'j24d1';
            } else if (judge3Email === userEmail && judge4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge3, judge4, D Judge for game ${game}`);
                return 'j34d1';
            }else if (d1Email === userEmail && d2Email === userEmail && d3Email === userEmail){
                console.log(`User ${userEmail} is D1, D2, CJP Judge for game ${game}`);
                return 'd123';
            }
              else if (a1Email === userEmail && a2Email === userEmail && a3Email === userEmail) {
                console.log(`User ${userEmail} is a1, a2, a3 for game ${game}`);
                return 'a123';
            } else if (a1Email === userEmail && a2Email === userEmail && a4Email === userEmail) {
                console.log(`User ${userEmail} is a1, a2, a4 for game ${game}`);
                return 'a124';
            } else if (a1Email === userEmail && a2Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a1, a2, D Judge for game ${game}`);
                return 'a12d1';
            } else if (a1Email === userEmail && a3Email === userEmail && a4Email === userEmail) {
                console.log(`User ${userEmail} is a1, a3, a4 for game ${game}`);
                return 'a134';
            } else if (a1Email === userEmail && a3Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a1, a3, D Judge for game ${game}`);
                return 'a13d1';
            } else if (a1Email === userEmail && a4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a1, a4, D Judge for game ${game}`);
                return 'a14d1';
            } else if (a2Email === userEmail && a3Email === userEmail && a4Email === userEmail) {
                console.log(`User ${userEmail} is a2, a3, a4 for game ${game}`);
                return 'a234';
            } else if (a2Email === userEmail && a3Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a2, a3, D Judge for game ${game}`);
                return 'a23d1';
            } else if (a2Email === userEmail && a4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a2, a4, D Judge for game ${game}`);
                return 'a24d1';
            } else if (a3Email === userEmail && a4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a3, a4, D Judge for game ${game}`);
                return 'a34d1';
            } else if (judge1Email === userEmail && judge2Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge2 for game ${game}`);
                return 'j12';
            } else if (judge1Email === userEmail && judge3Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge3 for game ${game}`);
                return 'j13';
            } else if (judge1Email === userEmail && judge4Email === userEmail) {
                console.log(`User ${userEmail} is judge1, judge4 for game ${game}`);
                return 'j14';
            } else if (judge1Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge1, D Judge for game ${game}`);
                return 'j1d1';
            }else if (judge1Email === userEmail && d2Email === userEmail) {
                console.log(`User ${userEmail} is judge1, D2 Judge for game ${game}`);
                return 'j1d2'; //////////d2/////////////
            }else if (judge1Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is judge1, D3 Judge for game ${game}`);
                return 'j1d3'; //////////d3/////////////
            } else if (judge2Email === userEmail && judge3Email === userEmail) {
                console.log(`User ${userEmail} is judge2, judge3 for game ${game}`);
                return 'j23';
            } else if (judge2Email === userEmail && judge4Email === userEmail) {
                console.log(`User ${userEmail} is judge2, judge4 for game ${game}`);
                return 'j24';
            } else if (judge2Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge2, D Judge for game ${game}`);
                return 'j2d1';
            } else if (judge2Email === userEmail && d2Email === userEmail) {
                console.log(`User ${userEmail} is judge2, D2 Judge for game ${game}`);
                return 'j2d2'; /////////////d2/////////////
            } else if (judge2Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is judge2, D3 Judge for game ${game}`);
                return 'j2d3'; /////////////d3/////////////
            } else if (judge3Email === userEmail && judge4Email === userEmail) {
                console.log(`User ${userEmail} is judge3, judge4 for game ${game}`);
                return 'j34';
            } else if (judge3Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge3, D Judge for game ${game}`);
                return 'j3d1';
            }else if (judge3Email === userEmail && d2Email === userEmail) {
                console.log(`User ${userEmail} is judge3, D2 Judge for game ${game}`);
                return 'j3d2'; ///////////d2///////////////
            }else if (judge3Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is judge3, D3 Judge for game ${game}`);
                return 'j3d3'; ///////////d3///////////////
            } else if (judge4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is judge4, D Judge for game ${game}`);
                return 'j4d1';
            }else if (judge4Email === userEmail && d2Email === userEmail) {
                console.log(`User ${userEmail} is judge4, D2 Judge for game ${game}`);
                return 'j4d2'; ///////////d2/////////////
            }else if (judge4Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is judge4, D3 Judge for game ${game}`);
                return 'j4d3'; ///////////d3/////////////
            }else if (d1Email === userEmail && d2Email === userEmail) {
                console.log(`User ${userEmail} is D1, D2 Judge for game ${game}`);
                return 'd1d2'; ///////////////d2///////////////
            }else if (d1Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is D1, D3 Judge for game ${game}`);
                return 'd1d3'; ///////////////d3///////////////
            }else if (d2Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is D2, D3 Judge for game ${game}`);
                return 'd2d3'; ///////////////d3///////////////
            }else if (a1Email === userEmail && a2Email === userEmail) {
                console.log(`User ${userEmail} is a1, a2 for game ${game}`);
                return 'a12';
            } else if (a1Email === userEmail && a3Email === userEmail) {
                console.log(`User ${userEmail} is a1, a3 for game ${game}`);
                return 'a13';
            } else if (a1Email === userEmail && a4Email === userEmail) {
                console.log(`User ${userEmail} is a1, a4 for game ${game}`);
                return 'a14';
            } else if (a1Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a1, D Judge for game ${game}`);
                return 'a1d1';
            }else if (a1Email === userEmail && d2Email === userEmail) {
                console.log(`User ${userEmail} is a1, D2 Judge for game ${game}`);
                return 'a1d2'; //////////////d2///////////////
            }else if (a1Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is a1, D3 Judge for game ${game}`);
                return 'a1d3'; //////////////d3///////////////
            } else if (a2Email === userEmail && a3Email === userEmail) {
                console.log(`User ${userEmail} is a2, a3 for game ${game}`);
                return 'a23';
            } else if (a2Email === userEmail && a4Email === userEmail) {
                console.log(`User ${userEmail} is a2, a4 for game ${game}`);
                return 'a24';
            } else if (a2Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a2, D Judge for game ${game}`);
                return 'a2d1';
            } else if (a2Email === userEmail && d2Email === userEmail) {
                console.log(`User ${userEmail} is a2, D2 Judge for game ${game}`);
                return 'a2d2';/////////////d2/////////////////
            }else if (a2Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is a2, D3 Judge for game ${game}`);
                return 'a2d3';/////////////d3/////////////////
            } else if (a3Email === userEmail && a4Email === userEmail) {
                console.log(`User ${userEmail} is a3, a4 for game ${game}`);
                return 'a34';
            } else if (a3Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a3, D Judge for game ${game}`);
                return 'a3d1';
            } else if (a3Email === userEmail && d2Email === userEmail) {
                console.log(`User ${userEmail} is a3, D2 Judge for game ${game}`);
                return 'a3d2'; ////////////d2//////////////////
            }else if (a3Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is a3, D3 Judge for game ${game}`);
                return 'a3d3'; ////////////d3//////////////////
            } else if (a4Email === userEmail && d1Email === userEmail) {
                console.log(`User ${userEmail} is a4, D Judge for game ${game}`);
                return 'a4d1';
            }else if (a4Email === userEmail && d2Email === userEmail) {
                console.log(`User ${userEmail} is a4, D2 Judge for game ${game}`);
                return 'a4d2'; ////////////d2/////////////////
            }else if (a4Email === userEmail && d3Email === userEmail) {
                console.log(`User ${userEmail} is a4, D3 Judge for game ${game}`);
                return 'a4d3'; ////////////d3/////////////////
            }else if (judge1Email === userEmail && a1Email === userEmail){
                console.log(`User ${userEmail} is judge1, a1 for game ${game}`);
                return 'j1a1';
            }
            else if (judge1Email === userEmail && a2Email === userEmail){
                console.log(`User ${userEmail} is judge1, a2 for game ${game}`);
                return 'j1a2'; 
            }
            else if (judge1Email === userEmail && a3Email === userEmail){
                console.log(`User ${userEmail} is judge1, a3 for game ${game}`);
                return 'j1a3';
            }
            else if (judge1Email === userEmail && a4Email === userEmail){
                console.log(`User ${userEmail} is judge1, a4 for game ${game}`);
                return 'j1a4';
            }
            else if (judge2Email === userEmail && a1Email === userEmail){
                console.log(`User ${userEmail} is judge2, a1 for game ${game}`);
                return 'j2a1';
            }
            else if (judge2Email === userEmail && a2Email === userEmail){
                console.log(`User ${userEmail} is judge2, a2 for game ${game}`);
                return 'j2a2';
            }
            else if (judge2Email === userEmail && a3Email === userEmail){
                console.log(`User ${userEmail} is judge2, a3 for game ${game}`);
                return 'j2a3';
            }
            else if (judge2Email === userEmail && a4Email === userEmail){
                console.log(`User ${userEmail} is judge2, a4 for game ${game}`);
                return 'j2a4';
            }
            else if (judge3Email === userEmail && a1Email === userEmail){
                console.log(`User ${userEmail} is judge3, a1 for game ${game}`);
                return 'j3a1';
            }
            else if (judge3Email === userEmail && a2Email === userEmail){
                console.log(`User ${userEmail} is judge3, a2 for game ${game}`);
                return 'j3a2';
            }
            else if (judge3Email === userEmail && a3Email === userEmail){
                console.log(`User ${userEmail} is judge3, a3 for game ${game}`);
                return 'j3a3';
            }
            else if (judge3Email === userEmail && a4Email === userEmail){
                console.log(`User ${userEmail} is judge3, a4 for game ${game}`);
                return 'j3a4';
            }
            else if (judge4Email === userEmail && a1Email === userEmail){
                console.log(`User ${userEmail} is judge4, a1 for game ${game}`);
                return 'j4a1';
            }
            else if (judge4Email === userEmail && a2Email === userEmail){
                console.log(`User ${userEmail} is judge4, a2 for game ${game}`);
                return 'j4a2';
            }
            else if (judge4Email === userEmail && a3Email === userEmail){
                console.log(`User ${userEmail} is judge4, a3 for game ${game}`);
                return 'j4a3';
            }
            else if (judge4Email === userEmail && a4Email === userEmail){
                console.log(`User ${userEmail} is judge4, a4 for game ${game}`);
                return 'j4a4';
            } else if (judge1Email === userEmail) {
                console.log(`User ${userEmail} is judge1 for game ${game}`);
                return 'judge1';
            } else if (judge2Email === userEmail) {
                console.log(`User ${userEmail} is judge2 for game ${game}`);
                return 'judge2';
            } else if (judge3Email === userEmail) {
                console.log(`User ${userEmail} is judge3 for game ${game}`);
                return 'judge3';
            } else if (judge4Email === userEmail) {
                console.log(`User ${userEmail} is judge4 for game ${game}`);
                return 'judge4';
            } else if (d1Email === userEmail) {
                console.log(`User ${userEmail} is D Judge for game ${game}`);
                return 'd1';
            } else if (d2Email === userEmail) {
                console.log(`User ${userEmail} is D2 Judge for game ${game}`);
                return 'd2';
            } else if (d3Email === userEmail) {
                console.log(`User ${userEmail} is D3 Judge for game ${game}`);
                return 'd3';
            } else if (a1Email === userEmail) {
                console.log(`User ${userEmail} is a1 for game ${game}`);
                return 'a1';
            } else if (a2Email === userEmail) {
                console.log(`User ${userEmail} is a2 for game ${game}`);
                return 'a2';
            } else if (a3Email === userEmail) {
                console.log(`User ${userEmail} is a3 for game ${game}`);
                return 'a3';
            } else if (a4Email === userEmail) {
                console.log(`User ${userEmail} is a4 for game ${game}`);
                return 'a4';
            }
            

        }
    }
    console.log(`User ${userEmail} is not judge1 or judge2 or judge3 or judge4 for game ${game}. Assigning as New judge.`);
    return 'newjudge';
};

  const extractEmail = (nameString) => {
    const emailMatch = nameString.match(/\(([^)]+)\)/);
    return emailMatch ? emailMatch[1] : null;
  };

  // const handleRowClick = (playerId) => {
  //   navigate(`/event/${eventId}/${game}/${playerId}`);
    
  // };

  const handleRowClick = (playerId) => {
    if (event.eventStatus === 'upcoming') {
      toast({
        title: 'Access Denied',
        description: 'You cannot access this event before it starts.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else if (event.eventStatus === 'ended') {
      toast({
        title: 'Access Denied',
        description: 'You cannot access this event after it has ended.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      navigate(`/event/${eventId}/imw/${game}/${playerId}`);
    }
  };

  const matchAthleteCode = (player) => {
    const matchedAthlete = athletes.find(athlete =>
      athlete.name === player.name
    );
    return matchedAthlete ? matchedAthlete.athleteCode : player.athleteCode || "N/A";
};

const matchAthleteCity = (player) => {
    const matchedAthlete = athletes.find(athlete =>
      athlete.name === player.name
    );
    return matchedAthlete ? matchedAthlete.city : player.city || "N/A";
}

const matchAthleteClub = (player) => {
    const matchedAthlete = athletes.find(athlete =>
      athlete.name === player.name
    );
    return matchedAthlete ? matchedAthlete.club : player.club || "N/A";
}

const matchAthleteState = (player) => {
    const matchedAthlete = athletes.find(athlete =>
      athlete.name === player.name
    );
    return matchedAthlete ? matchedAthlete.state : player.state || "N/A";
}


  
  
  
  

  if (loading) {
    return <Spinner />;
  }
  if (!event) {
    return <Text>Event not found</Text>;
  }

  const gamePlayers = game && event.players ? event.players : [];
  const userRole = getUserRoleForGame(event.judges, game);

  return (
    <Box p={4} minHeight={"100vh"}>
        <Box w={"95%"} background={"gray.700"} color={"white"} boxShadow= "rgba(0, 0, 0, 0.24) 0px 3px 8px" m={"auto"} borderRadius={"20px"}>
            <Flex flex={1} flexWrap={"wrap"} justifyContent={"space-between"} alignItems={"center"}>
                <Box  m={4} ml={"50px"}>
                <Text fontSize="lg"><b>Competition Name:</b> {event.eventName}</Text>
                <Text fontSize="lg"><b>Event:</b> {game === "MensPair"? "Individual Men": game === "WomensPair"?"Individual Women": game}</Text>

                </Box>
                <Box mr={"50px"}>
                <Text fontSize="lg"><b>Age Group:</b> {event.ageGroup}</Text>
                <Text fontSize="lg"><b>Date:</b> {new Date(event.dateTime).toLocaleString()}</Text>

                </Box>

            </Flex>

        </Box>
    
    
    <VStack align="start" mt={4}>
    {(userRole === 'd1' || userRole === 'all' || userRole === 'j123d1' || userRole === 'j124d1' || userRole === 'j124d1' || userRole === 'j234d1' || userRole === 'j12d1' || userRole === 'j13d1' || userRole === 'j14d1' || userRole === 'j23d1' || userRole === 'j24d1' || userRole === 'j34d1' || userRole === 'j1d1' || userRole === 'j2d1' || userRole === 'j3d1' || userRole === 'j4d1' || userRole === "a1d1" || userRole === "a2d1" || userRole === "a3d1" || userRole === "a4d1" || userRole === "d1d2" || userRole === "d1d3" || userRole === "d123") && (
        <>
        <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>CJP Judge for {game == "MensPair"?"Individual Men (IM)" : game === "WomensPair"?"Individual Women (IW)": game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>State</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteState(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      {(userRole === 'd3' || userRole === 'all' || userRole === 'j123d3' || userRole === 'j124d3' || userRole === 'j124d3' || userRole === 'j234d3' || userRole === 'j12d3' || userRole === 'j13d3' || userRole === 'j14d3' || userRole === 'j23d3' || userRole === 'j24d3' || userRole === 'j34d3' || userRole === 'j1d3' || userRole === 'j2d3' || userRole === 'j3d3' || userRole === 'j4d3' || userRole === "a1d3" || userRole === "a2d3" || userRole === "a3d3" || userRole === "a4d3" || userRole === "d1d3" || userRole === "d2d3" || userRole === "d123" ) && (
        <>
          <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Difficulty Judge (D1 ) for {game == "MensPair"?"Individual Men (IM)" : game === "WomensPair"?"Individual Women (IW)": game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>State</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteState(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      {(userRole === 'd2' || userRole === 'all' || userRole === 'j123d2' || userRole === 'j124d2' || userRole === 'j124d2' || userRole === 'j234d2' || userRole === 'j12d2' || userRole === 'j13d2' || userRole === 'j14d2' || userRole === 'j23d2' || userRole === 'j24d2' || userRole === 'j34d2' || userRole === 'j1d2' || userRole === 'j2d2' || userRole === 'j3d2' || userRole === 'j4d2' || userRole === "a1d2" || userRole === "a2d2" || userRole === "a3d2" || userRole === "a4d2" || userRole === "d1d2" || userRole === "d2d3" || userRole === "d123") && (
        <>
          <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Difficulty Judge (D2) for {game == "MensPair"?"Individual Men (IM)" : game === "WomensPair"?"Individual Women (IW)": game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>State</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteState(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      
    {(userRole === 'judge1' || userRole === 'all' || userRole === 'all-ejudge' || userRole === 'j1234' || userRole === 'j123d1' || userRole === 'j124d1' || userRole === 'j134d1' || userRole === 'j123' || userRole === 'j124' || userRole === 'j12d1' || userRole === 'j134' || userRole === 'j13d1' || userRole === 'j14d1' || userRole === 'j12' || userRole === 'j13' || userRole === 'j14' || userRole === 'j1d1' || userRole === "j1a1" || userRole === "j1a2" || userRole === "j1a3" || userRole === "j1a4" || userRole === "j1d2" || userRole ==="j1d3") && (
        <>
          <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Execution Judge (E1) for {game == "MensPair"?"Individual Men (IM)" : game === "WomensPair"?"Individual Women (IW)": game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>State</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteState(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      {(userRole === 'judge2' || userRole === 'all' || userRole === 'all-ejudge' || userRole === 'j1234' || userRole === 'j123d1' || userRole === 'j124d1' || userRole === 'j234d1' || userRole === 'j123' || userRole === 'j124' || userRole === 'j12d1' || userRole === 'j234' || userRole === 'j23d1' || userRole === 'j24d1' || userRole === 'j12' || userRole === 'j23' || userRole === 'j24' || userRole === 'j2d1' || userRole === "j2a1" || userRole === "j2a2" || userRole === "j2a3" || userRole === "j2a4" || userRole === "j2d2" || userRole ==="j2d3") && (
        <>
          <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Execution Judge (E2) for {game == "MensPair"?"Individual Men (IM)" : game === "WomensPair"?"Individual Women (IW)": game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>State</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteState(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      {(userRole === 'judge3' || userRole === 'all' || userRole === 'all-ejudge' || userRole === 'j1234' || userRole === 'j123d1' || userRole === 'j134d1' || userRole === 'j234d1' || userRole === 'j123' || userRole === 'j134' || userRole === 'j13d1' || userRole === 'j234' || userRole === 'j23d1' || userRole === 'j34d1' || userRole === 'j13' || userRole === 'j23' || userRole === 'j34' || userRole === 'j3d1' || userRole === "j3a1" || userRole === "j3a2" || userRole === "j3a3" || userRole === "j3a4" || userRole === "j3d2" || userRole ==="j3d3") && (
        <>
          <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Execution Judge (E3) for {game == "MensPair"?"Individual Men (IM)" : game === "WomensPair"?"Individual Women (IW)": game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>State</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteState(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      {(userRole === 'judge4' || userRole === 'all' || userRole === 'all-ejudge' || userRole === 'j1234' || userRole === 'j124d1' || userRole === '134d1' || userRole === 'j234d1' || userRole === 'j14d1' || userRole === 'j124' || userRole === 'j134' || userRole === 'j234' || userRole === 'j24d1' || userRole === 'j34d1' || userRole === 'j14' || userRole === 'j24' || userRole === 'j34' || userRole === 'j4d1' || userRole === "j4a1" || userRole === "j4a2" || userRole === "j4a3" || userRole === "j4a4" || userRole === "j4d2" || userRole ==="j4d3") && (
        <>
        <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Execution Judge (E4) for {game == "MensPair"?"Individual Men (IM)" : game === "WomensPair"?"Individual Women (IW)": game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>State</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteState(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      

      {(userRole === 'a1' || userRole === 'all' || userRole === 'all-ajudge' || userRole === 'a1234' || userRole === 'a123d1' || userRole === 'a124d1' || userRole === 'a134d1' || userRole === 'a123' || userRole === 'a124' || userRole === 'a12d1' || userRole === 'a134' || userRole === 'a13d1' || userRole === 'a14d1' || userRole === 'a12' || userRole === 'a13' || userRole === 'a14' || userRole === 'a1d1' || userRole === "j1a1" || userRole === "j2a1" || userRole === "j3a1" || userRole === "j4a1" || userRole === "a1d2" || userRole === "a1d3") && (
        <>
          <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Execution Judge (A1) for {game == "MensPair"?"Individual Men (IM)" : game === "WomensPair"?"Individual Women (IW)": game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>State</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteState(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      {(userRole === 'a2' || userRole === 'all' || userRole === 'all-ajudge' || userRole === 'a1234' || userRole === 'a123d1' || userRole === 'a124d1' || userRole === 'a234d1' || userRole === 'a123' || userRole === 'a124' || userRole === 'a12d1' || userRole === 'a234' || userRole === 'a23d1' || userRole === 'a24d1' || userRole === 'a12' || userRole === 'a23' || userRole === 'a24' || userRole === 'a2d1' || userRole === "j1a2" || userRole === "j2a2" || userRole === "j3a2" || userRole === "j4a2" || userRole === "a2d2" || userRole === "a2d3") && (
        <>
          <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Execution Judge (A2) for {game == "MensPair"?"Individual Men (IM)" : game === "WomensPair"?"Individual Women (IW)": game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>State</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteState(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      {(userRole === 'a3' || userRole === 'all' || userRole === 'all-ajudge' || userRole === 'a1234' || userRole === 'a123d1' || userRole === 'a134d1' || userRole === 'a234d1' || userRole === 'a123' || userRole === 'a134' || userRole === 'a13d1' || userRole === 'a234' || userRole === 'a23d1' || userRole === 'a34d1' || userRole === 'a13' || userRole === 'a23' || userRole === 'a34' || userRole === 'a3d1' || userRole === "j1a3" || userRole === "j2a3" || userRole === "j3a3" || userRole === "j4a3" || userRole === "a3d2" || userRole === "a3d3") && (
        <>
          <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Execution Judge (A3) for {game == "MensPair"?"Individual Men (IM)" : game === "WomensPair"?"Individual Women (IW)": game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>State</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteState(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
      {(userRole === 'a4' || userRole === 'all' || userRole === 'all-ajudge' || userRole === 'a1234' || userRole === 'a124d1' || userRole === 'a134d1' || userRole === 'a234d1' || userRole === 'a14d1' || userRole === 'a124' || userRole === 'a134' || userRole === 'a234' || userRole === 'a24d1' || userRole === 'a34d1' || userRole === 'a14' || userRole === 'a24' || userRole === 'a34' || userRole === 'a4d1' || userRole === "j1a4" || userRole === "j2a4" || userRole === "j3a4" || userRole === "j4a4" || userRole === "a4d2" || userRole === "a4d3") && (
        <>
          <Box w={"100%"} style={{textAlign:"center"}} >
        <Text fontSize="lg" fontWeight={"bold"} textAlign={"center"}>Execution Judge (A4) for {game == "MensPair"?"Individual Men (IM)" : game === "WomensPair"?"Individual Women (IW)": game}</Text>
        </Box>
        <Box mb={4} w="100%" textAlign="center">
          <Input
            placeholder="Search athlete by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            w={"80%"} m={"auto"}
          />
        </Box>
          <Table color={"black"} variant="striped" width={"90%"} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray" >
          <Thead>
              <Tr>
                <Th color={"white"}>SR</Th>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Athlete Code</Th>
                <Th color={"white"}>City</Th>
                <Th color={"white"}>State</Th>
                <Th color={"white"}>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
            {filteredPlayers.map((player,index) => (
                <Tr key={index}
                onClick={() => handleRowClick(player._id)}
                // _hover={{ backgroundColor: 'gray.900' }}
                style={{ cursor: 'pointer' }}>
                    <Td>{index+1}</Td>
                  <Td>{player.name}</Td>
                  <Td>{matchAthleteCode(player)}</Td>
                  <Td>{matchAthleteCity(player)}</Td>
                  <Td>{matchAthleteState(player)}</Td>
                    <Td style={{ color: player.status === 'completed' ? 'green' : player.status === 'playing' ? 'yellow' : 'red', fontWeight:"bold" }}>{player.status}</Td>
                </Tr> 
            ))}
            </Tbody>
          </Table>
        </>
      )}
    </VStack>
  </Box>
);
};

export default JudgePanelPlayerListAerobic;