import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import AdminEventDetails from './AdminEventDetails'
import AdminEvents from './AdminEvents'
import AdminEventsAerobics from './AdminEventsAerobics'

const AdminEventsTab = () => {
  return (
    <div>
        <Tabs>
            <TabList>
            <Tab>
                    Aerobics
                </Tab>
    
                <Tab>
                    Artistic
                </Tab>
                
            </TabList>
            <TabPanels>
                
                <TabPanel>
                    <AdminEventsAerobics/>
                </TabPanel>
                <TabPanel>
                    <AdminEvents/>
                </TabPanel>
            </TabPanels>
        </Tabs>
    </div>
  )
}

export default AdminEventsTab