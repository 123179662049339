// src/pages/PlayerListPrintPage.js
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Text, VStack, Table, Thead, Tr, Th, Tbody, Td, Spinner, Heading, Button, Flex, Input,Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import { useAuth } from '../../../../context/AuthContext';
import { API_URL } from "../../../../utils/apiConfig";
import { GiCheckMark } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { IoMdPlay } from "react-icons/io";

const PlayerListPrintPageAerobics = () => {
  const { eventId, game } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customHeading, setCustomHeading] = useState(''); // State for custom heading
  const navigate = useNavigate();
  const componentPDF = useRef();
  const [activeTab, setActiveTab] = useState(0);
  const playerListBasicRef = useRef(null);
  const playerListDetailedRef = useRef(null);
  const rankwiseBasicRef = useRef(null);
  const rankwiseDetailedRef = useRef(null);
  const [currentDateTime, setCurrentDateTime] = useState('');
  // Function to format the date and time
  // Function to get current IST date and time
  const getISTDateTime = () => {
    const now = new Date();
    const istOffset = 5 * 60 + 30; // IST is UTC+5:30
    const localTime = now.getTime() + now.getTimezoneOffset() * 60000; // Local time in UTC
    const istTime = new Date(localTime + istOffset * 60000); // Adjust for IST offset

    const day = String(istTime.getDate()).padStart(2, '0');
    const month = String(istTime.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = istTime.getFullYear();

    const hours = String(istTime.getHours()).padStart(2, '0');
    const minutes = String(istTime.getMinutes()).padStart(2, '0');
    const seconds = String(istTime.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const updateDateTime = () => {
      setCurrentDateTime(getISTDateTime());
    };

    updateDateTime(); // Initial call
    const interval = setInterval(updateDateTime, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        navigate('/supervisor');
        return;
      }

      const fetchPlayers = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events-aerobic/${eventId}`);
          const eventDetails = response.data;
          const gamePlayers = eventDetails.players[game];
          if (eventDetails) {
            setEvent(eventDetails);
            setPlayers(gamePlayers);
          } else {
            console.error('Event not found');
          }
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchPlayers();
    }
  }, [userLoading, user, userSupervisorRole, eventId, game, navigate]);

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "gymnast-scorelist-gymnazien",
    onAfterPrint: () => alert("Gymnast Scorelist PDF exported successfully"),
  });

  const handlePrint = useReactToPrint({
    content: () => {
        // Dynamically select the content based on the active tab
        switch (activeTab) {
          case 0:
            return playerListBasicRef.current;
          case 1:
            return playerListDetailedRef.current;
          case 2:
            return rankwiseBasicRef.current;
          case 3:
            return rankwiseDetailedRef.current;
          default:
            return null;
        }
      },
    documentTitle: "gymnast-scorelist-gymnazien",
    onAfterPrint: () => alert("Gymnast Scorelist PDF exported successfully"),
  });

  if (loading || userLoading) {
    return <Spinner />;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <Text>You are not authorized to view this page.</Text>;
  }
  // Sort players by total scores for the ranking tab
  const sortedPlayers = [...players].sort((a, b) => b.scores.total - a.scores.total);


  return (
    <>
      <Flex w={"95%"} flex={1} gap={"10px"} justifyContent={"space-between"} m={"auto"} mt={"20px"}>
        <Button onClick={() => navigate(-1)} mb={4}>Back</Button>
        <Input 
              placeholder="Enter custom heading" 
              value={customHeading} 
              onChange={(e) => setCustomHeading(e.target.value)} 
              mb={4} 
            />
        <Button onClick={handlePrint} mb={4}>Export to PDF</Button>
      </Flex>
      <Tabs isFitted variant='enclosed' onChange={(index) => setActiveTab(index)}>
          <TabList>
            <Tab>Player List Basic</Tab>
            <Tab>Player List Detailed</Tab>
            <Tab>Rankedwise Basic</Tab>
            <Tab>Rankedwise Detailed</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
      <Box ref={playerListBasicRef} p={4} border={"1px solid black"}  w={"97%"} m={"auto"} mt={"1vh"} minHeight={"98vh"} bg={"white"} color={"black"}>
        <Box marginBottom={"50px"} border={"1px solid black"}>
        {event && (
          <Box mb={4} textAlign={"center"}>
            
            <Heading size="xl">{customHeading}</Heading>
            <Heading size="lg">{event.eventName}</Heading>
            <Text>Date: {new Date(event.dateTime).toLocaleString()}</Text>
            <Text>Event: {game === "MensPair"? "Individual Men" : game === "WomensPair"? "Individual Women" : game}</Text>
          </Box>
        )}
        <VStack align="start" mt={4}>
          <Box w={"90%"}>
            {players.length > 0 ? (
              <table style={{ width: '111%', borderCollapse: 'collapse', backgroundColor: 'white', tableLayout: 'fixed' }}>
              <thead>
              
                <tr style={{ cursor: 'pointer', border: "1px solid black" }} >
                <th style={{ fontSize:"12px",border: "1px solid black", padding: '10px', width: '7%' }}>SR</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black", width: '20%', }}>Name</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>D</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>A</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>E</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>Pen</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>CJP</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>Total</th>
                  
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>Status</th>
                </tr>
              </thead>
              <tbody style={{ cursor: 'pointer', border: "1px solid black" }}>
              {players.map((player, index) => (
                  <tr key={index} style={{ cursor: 'pointer', border: "1px solid black" }}>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{index + 1}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.name}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.d}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.e}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.a}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.penalty}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.cjp}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.total}</td>
                    {/* <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{index + 1}</td> */}
                    <td style={{ cursor: 'pointer', border: "1px solid black",  }}>{player.status === "completed" ? <GiCheckMark style={{margin:"auto"}} color='green' /> : player.status === "not played" ? <RxCross2 style={{margin:"auto"}} color='red' /> : <IoMdPlay style={{margin:"auto"}} />}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            ) : (
              <div>
                <br />
                <Text>No approved players found.</Text>
              </div>
            )}
          </Box>
        </VStack>
        </Box>
        <Flex flex={1} justifyContent={"space-between"}>
        <Text
                position="absolute"
                bottom="10px"
                left="20px"
                fontSize="sm"
                color="gray.500"
              >
                Gymnazien Tech Pvt.Ltd
              </Text>
        <Text
                position="absolute"
                bottom="10px"
                right="20px"
                fontSize="sm"
                color="gray.500"
              >
                Document Downloaded on {currentDateTime}
              </Text>

        </Flex>
        
      </Box>
      </TabPanel>
      <TabPanel>
      <Box ref={playerListDetailedRef} p={4} border={"1px solid black"} w={"97%"} m={"auto"} mt={"1vh"} minHeight={"98vh"} bg={"white"} color={"black"}>
        <Box marginBottom={"50px"} border={"1px solid black"}>
        {event && (
          <Box mb={4} textAlign={"center"}>
            
            <Heading size="xl">{customHeading}</Heading>
            <Heading size="lg">{event.eventName}</Heading>
            <Text>Date: {new Date(event.dateTime).toLocaleString()}</Text>
            <Text>Event: {game === "MensPair"? "Individual Men" : game === "WomensPair"? "Individual Women" : game}</Text>
          </Box>
        )}
        <VStack align="start" mt={4}>
          <Box w={"90%"}>
            {players.length > 0 ? (
              <table style={{fontSize:"11px", width: '111%', borderCollapse: 'collapse', backgroundColor: 'white', tableLayout: 'fixed' }}>
                <thead>
                
                  <tr style={{ cursor: 'pointer', border: "1px solid black" }} >
                  <th style={{ fontSize:"12px",border: "1px solid black", padding: '10px', width: '7%' }}>SR</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black", width: '20%', }}>Name</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black", width: '10%' }}>D1</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>D2</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>D</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>A1</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>A2</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>A3</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>A4</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>A</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>E1</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>E2</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>E3</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>E4</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>E</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>Pen</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>CJP</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>Total</th>
                    
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>Status</th>
                  </tr>
                </thead>
                <tbody style={{ cursor: 'pointer', border: "1px solid black" }}>
                {players.map((player, index) => (
                    <tr key={index} style={{ cursor: 'pointer', border: "1px solid black" }}>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{index + 1}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.name}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.d3}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.d2}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.d}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.judge1 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.judge2 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.judge3 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.judge4 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.e}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.a1 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.a2 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.a3 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.a4 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.a}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.penalty}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.cjp}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.total}</td>
                      {/* <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{index + 1}</td> */}
                      <td style={{ cursor: 'pointer', border: "1px solid black",  }}>{player.status === "completed" ? <GiCheckMark style={{margin:"auto"}} color='green' /> : player.status === "not played" ? <RxCross2 style={{margin:"auto"}} color='red' /> : <IoMdPlay style={{margin:"auto"}} />}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>
                <br />
                <Text>No approved players found.</Text>
              </div>
            )}
          </Box>
        </VStack>
        </Box>
        <Flex flex={1} justifyContent={"space-between"}>
        <Text
                position="absolute"
                bottom="10px"
                left="20px"
                fontSize="sm"
                color="gray.500"
              >
                Gymnazien Tech Pvt.Ltd
              </Text>
        <Text
                position="absolute"
                bottom="10px"
                right="20px"
                fontSize="sm"
                color="gray.500"
              >
                Document Downloaded on {currentDateTime}
              </Text>

        </Flex>
      </Box>
      </TabPanel>
      <TabPanel>
      <Box ref={rankwiseBasicRef} p={4} border={"1px solid black"} w={"97%"} m={"auto"} mt={"1vh"} minHeight={"98vh"} bg={"white"} color={"black"}>
      <Box marginBottom={"50px"} border={"1px solid black"}>
        {event && (
          <Box mb={4} textAlign={"center"}>
            
            <Heading size="xl">{customHeading}</Heading>
            <Heading size="lg">{event.eventName}</Heading>
            <Text>Date: {new Date(event.dateTime).toLocaleString()}</Text>
            <Text>Event: {game === "MensPair"? "Individual Men" : game === "WomensPair"? "Individual Women" : game}</Text>
          </Box>
        )}
        <VStack align="start" mt={4}>
          <Box w={"90%"}>
          {sortedPlayers.length > 0 ? (
              <table style={{ width: '111%', borderCollapse: 'collapse', backgroundColor: 'white', tableLayout: 'fixed' }}>
              <thead>             
                <tr style={{ cursor: 'pointer', border: "1px solid black" }} >
                
                  <th style={{ cursor: 'pointer', border: "1px solid black", width: '20%', }}>Name</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>D</th>                 
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>A</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>E</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>Pen</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>CJP</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>Total</th>
                  <th style={{ fontSize:"12px",border: "1px solid black", padding: '10px', width: '7%' }}>Rank</th>
                  <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>Status</th>
                </tr>
              </thead>
              <tbody style={{ cursor: 'pointer', border: "1px solid black" }}>
              {sortedPlayers.map((player, index) => (
                  <tr key={index} style={{ cursor: 'pointer', border: "1px solid black" }}>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.name}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.d}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.e}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.a}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.penalty}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.cjp}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.total}</td>
                    <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{index + 1}</td>
                    {/* <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{index + 1}</td> */}
                    <td style={{ cursor: 'pointer', border: "1px solid black",  }}>{player.status === "completed" ? <GiCheckMark style={{margin:"auto"}} color='green' /> : player.status === "not played" ? <RxCross2 style={{margin:"auto"}} color='red' /> : <IoMdPlay style={{margin:"auto"}} />}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            ) : (
              <div>
                <br />
                <Text>No approved players found.</Text>
              </div>
            )}
          </Box>
        </VStack>
        </Box>
        <Flex flex={1} justifyContent={"space-between"}>
        <Text
                position="absolute"
                bottom="10px"
                left="20px"
                fontSize="sm"
                color="gray.500"
              >
                Gymnazien Tech Pvt.Ltd
              </Text>
        <Text
                position="absolute"
                bottom="10px"
                right="20px"
                fontSize="sm"
                color="gray.500"
              >
                Document Downloaded on {currentDateTime}
              </Text>

        </Flex>
      </Box>
      </TabPanel>
      <TabPanel>
      <Box ref={rankwiseDetailedRef} p={4} border={"1px solid black"} w={"97%"} m={"auto"} mt={"1vh"} minHeight={"98vh"} bg={"white"} color={"black"}>
      <Box marginBottom={"50px"} border={"1px solid black"}>
        {event && (
          <Box mb={4} textAlign={"center"}>
            
            <Heading size="xl">{customHeading}</Heading>
            <Heading size="lg">{event.eventName}</Heading>
            <Text>Date: {new Date(event.dateTime).toLocaleString()}</Text>
            <Text>Event: {game === "MensPair"? "Individual Men" : game === "WomensPair"? "Individual Women" : game}</Text>
          </Box>
        )}
        <VStack align="start" mt={4}>
          <Box w={"90%"}>
          {sortedPlayers.length > 0 ? (
              <table style={{fontSize:"11px", width: '110%', borderCollapse: 'collapse', backgroundColor: 'white', tableLayout: 'fixed' }}>
                <thead>
                  <tr style={{ cursor: 'pointer', border: "1px solid black" }} >
                    <th style={{ cursor: 'pointer', border: "1px solid black", width: '20%', }}>Name</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black", width: '10%' }}>D1</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>D2</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>D</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>A1</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>A2</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>A3</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>A4</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>A</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>E1</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>E2</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>E3</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>E4</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>E</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>Pen</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>CJP</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>Total</th>
                    <th style={{ fontSize:"12px",border: "1px solid black", padding: '10px', width: '10%' }}>Rank</th>
                    <th style={{ cursor: 'pointer', border: "1px solid black",width: '10%' }}>Status</th>
                  </tr>
                </thead>
                <tbody style={{ cursor: 'pointer', border: "1px solid black" }}>
                {sortedPlayers.map((player, index) => (
                    <tr key={index} style={{ cursor: 'pointer', border: "1px solid black" }}>
                      {/* <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{index + 1}</td> */}
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.name}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.d3}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.d2}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.d}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.judge1 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.judge2 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.judge3 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.judge4 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.e}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.a1 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.a2 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.a3 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.a4 || "N/A"}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.a}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.penalty}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.cjp}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{player.scores.total}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black", textAlign:"center" }}>{index + 1}</td>
                      <td style={{ cursor: 'pointer', border: "1px solid black",  }}>{player.status === "completed" ? <GiCheckMark style={{margin:"auto"}} color='green' /> : player.status === "not played" ? <RxCross2 style={{margin:"auto"}} color='red' /> : <IoMdPlay style={{margin:"auto"}} />}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>
                <br />
                <Text>No approved players found.</Text>
              </div>
            )}
          </Box>
        </VStack>
        </Box>
        <Flex flex={1} justifyContent={"space-between"}>
        <Text
                position="absolute"
                bottom="10px"
                left="20px"
                fontSize="sm"
                color="gray.500"
              >
                Gymnazien Tech Pvt.Ltd
              </Text>
        <Text
                position="absolute"
                bottom="10px"
                right="20px"
                fontSize="sm"
                color="gray.500"
              >
                Document Downloaded on {currentDateTime}
              </Text>

        </Flex>
      </Box>
      </TabPanel>
      </TabPanels>
      </Tabs>
    </>
  );
};

export default PlayerListPrintPageAerobics;
