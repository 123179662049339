import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { toast, Toaster } from "react-hot-toast";
import { Box, Text, VStack, List, ListItem, Spinner, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, Td, Th, Table, Thead, Tr, Tbody, Flex, } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../../../context/AuthContext';
import { MdEdit } from "react-icons/md";
import {API_URL} from "../../../utils/apiConfig";

const PlayerScoreDetailsPageAerobic = () => {
  const { eventId, game, playerId } = useParams();
  const { user, userSupervisorRole, loading: userLoading } = useAuth();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [score, setScore] = useState('');
  const [penalty, setPenalty] = useState('');
  const [total, setTotal] = useState(0);
  const [isScoreModalOpen, setIsScoreModalOpen] = useState(false);
  const [isPenaltyModalOpen, setIsPenaltyModalOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [judgeToEdit, setJudgeToEdit] = useState('');
  const navigate = useNavigate();
  const [initialScores, setInitialScores] = useState(null); // Track initial scores
  const [isChangesSaved, setIsChangesSaved] = useState(true); // Track if changes are saved
  const [disab, isDisab] = useState(true);

  useEffect(() => {
    if (!userLoading) {
      if (!user || userSupervisorRole !== 'supervisor') {
        // If not a supervisor, redirect to the supervisor dashboard or show a "Not Authorized" page
        navigate('/supervisor');
        return;
      }

      const fetchEvent = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events-aerobic/${eventId}`);
          const eventDetails = response.data;
          setEvent(eventDetails);
          if (eventDetails && eventDetails.supervisors) {
            const supervisors = eventDetails.supervisors[game];
            const isSupervisor = supervisors && Object.values(supervisors).some(
              supervisor => supervisor && (supervisor.email === user.email || supervisor.mobile === user.phoneNumber)
            )
            if (!isSupervisor) {
                navigate('/notaccess'); // Redirect if not authorized
                return;
              }           
            const gamePlayers = eventDetails.players[game];
            const currentPlayer = gamePlayers.find(player => player._id === playerId);
            setSelectedPlayer(currentPlayer);
            if (currentPlayer) {
              updateTotalScore(currentPlayer.scores);
            }
          } else {
            navigate('/notaccess'); // Redirect if the game is not found or not authorized
            console.error('Event not found');
          }
        } catch (error) {
          console.error('Error fetching event:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchEvent();
    }
  }, [userLoading, user, userSupervisorRole, eventId, game, playerId, navigate]);

  useEffect(() => {
    if (selectedPlayer && initialScores) {
      const hasChanges = JSON.stringify(selectedPlayer.scores) !== JSON.stringify(initialScores);
      setIsChangesSaved(!hasChanges); // Enable Save Changes button if there are changes
      isDisab(true)
    }
  }, [selectedPlayer, initialScores]);

  const openScoreModal = (judge) => {
    setJudgeToEdit(judge);
    setScore(selectedPlayer.scores[judge] || '');
    setIsScoreModalOpen(true);
  };

  const openPenaltyModal = () => {
    setPenalty(selectedPlayer.scores.penalty || '');
    setIsPenaltyModalOpen(true);
  };

  const closeModal = () => {
    setScore('');
    setPenalty('');
    setIsScoreModalOpen(false);
    setIsPenaltyModalOpen(false);
    setIsApproveModalOpen(false);
  };

  const openApproveModal = () => {
    setIsApproveModalOpen(true);
  };

  // const handleApprove = async () => {
  //   try {
  //     const updatedEvent = { ...event };
  //     if (!updatedEvent.players[game]) {
  //       console.error('No players for this game');
  //       return;
  //     }
  //     const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
  //     if (playerIndex === -1) {
  //       console.error('Player not found');
  //       return;
  //     }

  //     updatedEvent.players[game][playerIndex].status = 'completed';
  //     updatedEvent.players[game][playerIndex].approve = true;

  //     // Save the updated status to the server
  //     await axios.put(`${API_URL}/api/events-aerobic/${eventId}`, updatedEvent);

  //     setEvent(updatedEvent);
  //     setSelectedPlayer(updatedEvent.players[game][playerIndex]); // Update the selected player with the new status
  //     toast.success("Player status updated to completed");
  //   } catch (error) {
  //     console.error('Error updating status:', error);
  //     toast.error('Error updating status');
  //   } finally {
  //     closeModal();
  //   }
  // };


  const handleApprove = async () => {
    try {
        const playerIndex = event.players[game]?.findIndex(player => player._id === selectedPlayer._id);
        if (playerIndex === -1) {
            console.error('Player not found');
            return;
        }

        // Define the payload for updating player status and approval
        const payload = {
            game,
            playerId: selectedPlayer._id,
            status: 'completed',
            approve: true,
        };

        // Send the minimal payload to the backend
        const response = await axios.patch(`${API_URL}/api/events-aerobic/${eventId}/approve-player`, payload);

        // Update the frontend state with the new player data
        const updatedPlayer = response.data.updatedPlayer;
        setEvent(prevEvent => {
            const updatedEvent = { ...prevEvent };
            updatedEvent.players[game][playerIndex] = updatedPlayer;
            return updatedEvent;
        });

        setSelectedPlayer(updatedPlayer); // Update the selected player with new status
        toast.success("Player status updated to completed");
    } catch (error) {
        console.error('Error updating status:', error);
        toast.error('Error updating status');
    } finally {
        closeModal();
    }
};


  const calculateScoreWithTolerance = (scores) => {
    if (!scores || !Array.isArray(scores) || scores.length <= 2) {
        return 0; // Not enough scores to calculate an average
    }

    // Sort the scores
    scores.sort((a, b) => a - b);

    // Remove the highest and lowest scores
    const filteredScores = scores.slice(1, -1);

    // Calculate the average of the remaining scores
    const avgScore = filteredScores.reduce((acc, score) => acc + score, 0) / filteredScores.length;

    // Determine the allowed tolerance based on the average score
    let allowedTolerance = 0.3; // Default
    if (avgScore >= 8.0 && avgScore <= 10.0) {
        allowedTolerance = 0.3;
    } else if (avgScore >= 7.0 && avgScore < 7.99) {
        allowedTolerance = 0.4;
    } else if (avgScore >= 6.0 && avgScore < 6.99) {
        allowedTolerance = 0.5;
    } else if (avgScore >= 0.0 && avgScore < 5.99) {
        allowedTolerance = 0.6;
    }

    // Calculate the gap (tolerance) between the two remaining scores
    const gap = Math.abs(filteredScores[0] - filteredScores[1]);

    // If the gap exceeds allowed tolerance, recalculate the average using all scores
    if (gap > allowedTolerance) {
        const totalScore = scores.reduce((acc, score) => acc + score, 0);
        return (totalScore / scores.length).toFixed(3);
    }

    // Return the average of the filtered scores if within tolerance
    return avgScore.toFixed(3);
};


  const updateTotalScore = (scores) => {
    const judgeScores = [
      scores.judge1,
      scores.judge2,
      scores.judge3,
      scores.judge4
    ].filter(score => score !== undefined);

    const AScores = [
        scores.judge1,
        scores.judge2,
        scores.judge3,
        scores.judge4
      ].filter(score => score !== undefined);

    const averageE = parseFloat(calculateScoreWithTolerance(judgeScores));
    const averageA = parseFloat(calculateScoreWithTolerance(AScores));
    const dScore = (scores.d3 + scores.d2)/ scores.dividedby;
    const penaltyScore = scores.penalty ? parseFloat(scores.penalty) : 0;
    const cjpScore = scores.cjp ? parseFloat(scores.cjp): 0;
    
    const totalScore = (averageE + averageA + dScore) - penaltyScore - cjpScore;
    setTotal(totalScore.toFixed(3));
  };

  // const handleScoreSubmit = async () => {
  //   if (judgeToEdit === '') {
  //     return;
  //   }
  //   try {
  //     const updatedEvent = { ...event };
  //     if (!updatedEvent.players[game]) {
  //       console.error('No players for this game');
  //       return;
  //     }
  //     const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
  //     if (playerIndex === -1) {
  //       console.error('Player not found');
  //       return;
  //     }
      
  //     updatedEvent.players[game][playerIndex].scores = updatedEvent.players[game][playerIndex].scores || {};
  //     updatedEvent.players[game][playerIndex].scores[judgeToEdit] = parseFloat(score);

  //     // Update the total score
  //     updateTotalScore(updatedEvent.players[game][playerIndex].scores);

  //     // Save the updated scores and total to the server
  //     await axios.put(`${API_URL}/api/events-aerobic/${eventId}`, updatedEvent);

  //     setEvent(updatedEvent);
  //     setSelectedPlayer(updatedEvent.players[game][playerIndex]); // Update the selected player with new score
  //     isDisab(false);
  //     closeModal();
  //   } catch (error) {
  //     console.error('Error updating score:', error);
  //   }
  // };

//   const handlePenaltySubmit = async () => {
//     try {
//       const updatedEvent = { ...event };
//       if (!updatedEvent.players[game]) {
//         console.error('No players for this game');
//         return;
//       }
//       const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
//       if (playerIndex === -1) {
//         console.error('Player not found');
//         return;
//       }
//       updatedEvent.players[game][playerIndex].scores = updatedEvent.players[game][playerIndex].scores || {};
//       updatedEvent.players[game][playerIndex].scores.penalty = parseFloat(penalty);

//       // Update the total score
//       updateTotalScore(updatedEvent.players[game][playerIndex].scores);

//       // Save the updated penalty and total to the server
//       await axios.put(`${API_URL}/api/events/${eventId}`, updatedEvent);

//       setEvent(updatedEvent);
//       setSelectedPlayer(updatedEvent.players[game][playerIndex]); // Update the selected player with new penalty
//       isDisab(false);
//       closeModal();
//     } catch (error) {
//       console.error('Error updating penalty:', error);
//     }
//   };

const handleScoreSubmit = async () => {
  if (!judgeToEdit) {
      return;
  }

  try {
      const payload = {
          game,
          playerId: selectedPlayer._id,
          judge: judgeToEdit,
          score: parseFloat(score),
      };

      // Send only the updated score as payload
      const response = await axios.patch(`${API_URL}/api/events-aerobic/${eventId}/scores`, payload);

      const updatedPlayer = response.data.updatedPlayer;

      // Update the selected player in the state
      setEvent(prevEvent => {
          const updatedEvent = { ...prevEvent };
          const playerIndex = updatedEvent.players[game].findIndex(p => p._id === updatedPlayer.playerId);
          if (playerIndex !== -1) {
              updatedEvent.players[game][playerIndex].scores = updatedPlayer.scores;
              updatedEvent.players[game][playerIndex].totalScore = updatedPlayer.totalScore;
          }
          return updatedEvent;
      });

      setSelectedPlayer(prevPlayer => ({
          ...prevPlayer,
          scores: updatedPlayer.scores,
          totalScore: updatedPlayer.totalScore,
      }));

      isDisab(false);
      closeModal();
  } catch (error) {
      console.error('Error updating score:', error);
  }
};


// const handleSaveChanges = async () => {
//     try {
//       const updatedEvent = { ...event };
//       if (!updatedEvent.players[game]) {
//         console.error('No players for this game');
//         return;
//       }
//       const playerIndex = updatedEvent.players[game].findIndex(player => player._id === selectedPlayer._id);
//       if (playerIndex === -1) {
//         console.error('Player not found');
//         return;
//       }

//       // Calculate the average score using the calculateAverage function
//       const averageScore = calculateScoreWithTolerance([
//         updatedEvent.players[game][playerIndex].scores.judge1,
//         updatedEvent.players[game][playerIndex].scores.judge2,
//         updatedEvent.players[game][playerIndex].scores.judge3,
//         updatedEvent.players[game][playerIndex].scores.judge4
//       ].filter(score => score !== undefined))
//       console.log("avd E score", averageScore);

//       // Calculate the average score using the calculateAverage function
//       const averageAScore = calculateScoreWithTolerance([
//         updatedEvent.players[game][playerIndex].scores.a1,
//         updatedEvent.players[game][playerIndex].scores.a2,
//         updatedEvent.players[game][playerIndex].scores.a3,
//         updatedEvent.players[game][playerIndex].scores.a4
//       ].filter(score => score !== undefined))
//       console.log("avd A score", averageAScore);

//       const finalDScore = (Number(updatedEvent.players[game][playerIndex].scores.d3) + Number(updatedEvent.players[game][playerIndex].scores.d2))/ Number(updatedEvent.players[game][playerIndex].scores.dividedby)
//       console.log("final d", finalDScore);

//       const result = Number (calculateScoreWithTolerance([
//         updatedEvent.players[game][playerIndex].scores.judge1,
//         updatedEvent.players[game][playerIndex].scores.judge2,
//         updatedEvent.players[game][playerIndex].scores.judge3,
//         updatedEvent.players[game][playerIndex].scores.judge4
//       ].filter(score => score !== undefined))) +
//         Number (calculateScoreWithTolerance([
//         updatedEvent.players[game][playerIndex].scores.a1,
//         updatedEvent.players[game][playerIndex].scores.a2,
//         updatedEvent.players[game][playerIndex].scores.a3,
//         updatedEvent.players[game][playerIndex].scores.a4
//       ].filter(score => score !== undefined))) +
//         (
//         (Number(updatedEvent.players[game][playerIndex].scores.d3) + Number(updatedEvent.players[game][playerIndex].scores.d2))/ Number(updatedEvent.players[game][playerIndex].scores.dividedby)
//         ) - 
//         Number(updatedEvent.players[game][playerIndex].scores.penalty) - 
//         Number(updatedEvent.players[game][playerIndex].scores.cjp)
//       console.log("Total Result", result);
  

//       updatedEvent.players[game][playerIndex].scores.total = result.toFixed(3)
//       updatedEvent.players[game][playerIndex].scores.e = averageScore;
//       updatedEvent.players[game][playerIndex].scores.a = averageAScore;
//       updatedEvent.players[game][playerIndex].scores.d = finalDScore.toFixed(3)

//       // Save the updated event including the total score to the server
//       await axios.put(`${API_URL}/api/events-aerobic/${eventId}`, updatedEvent);

//       setEvent(updatedEvent);
//       setSelectedPlayer(updatedEvent.players[game][playerIndex]); // Update the selected player with the new total
//       setInitialScores(updatedEvent.players[game][playerIndex].scores); // Reset initial scores to the updated scores
//       setIsChangesSaved(true); // Disable the Save Changes button after saving
//       isDisab(true);
//       // toast.success("Score Updated Successfully");
//       toast.success("Score Updated Successfully")
      
//     } catch (error) {
//       console.error('Error saving changes:', error);
//       toast.error(error)
//     }
//   };

const handleSaveChanges = async () => {
  try {
      const playerIndex = event.players[game]?.findIndex(player => player._id === selectedPlayer._id);
      if (playerIndex === -1) {
          console.error('Player not found');
          return;
      }

      // Calculate scores
      const scores = selectedPlayer.scores || {};
      const eScore = calculateScoreWithTolerance([
          scores.judge1, scores.judge2, scores.judge3, scores.judge4,
      ].filter(score => score !== undefined));
      const aScore = calculateScoreWithTolerance([
          scores.a1, scores.a2, scores.a3, scores.a4,
      ].filter(score => score !== undefined));
      const dScore = ((Number(scores.d3) + Number(scores.d2)) / Number(scores.dividedby)).toFixed(3)
      const totalScore = (
          Number(eScore) +
          Number(aScore) +
          Number(dScore) -
          Number(scores.penalty || 0) -
          Number(scores.cjp || 0)
      ).toFixed(3);

      const updatedScores = {
          e: Number(eScore),
          a: Number(aScore),
          d: Number(dScore),
          total: Number(totalScore),
      };

      // Send only the updated scores to the backend
      const payload = {
          game,
          playerId: selectedPlayer._id,
          scores: updatedScores,
      };

      const response = await axios.patch(`${API_URL}/api/events-aerobic/${eventId}/calculate-scores`, payload);

      // Update frontend state
      const updatedPlayerScores = response.data.updatedScores;
      setEvent(prevEvent => {
          const updatedEvent = { ...prevEvent };
          updatedEvent.players[game][playerIndex].scores = updatedPlayerScores;
          return updatedEvent;
      });

      setSelectedPlayer(prevPlayer => ({
          ...prevPlayer,
          scores: updatedPlayerScores,
      }));
      setInitialScores(updatedPlayerScores);
      setIsChangesSaved(true);
      isDisab(true);

      toast.success("Score Updated Successfully");
  } catch (error) {
      console.error('Error saving changes:', error);
      toast.error('Error saving changes');
  }
};


  const handleBack = () => {
    navigate(`/supervisorpanel/aerobics/${event._id}/IMW/${game}`);
    
  };

  if (loading || userLoading) {
    return <Spinner />;
  }

  if (!user || userSupervisorRole !== 'supervisor') {
    return <Text>You are not authorized to view this page.</Text>;
  }

  return (
    <Box p={4} bg={"#26263c"}>
      <Toaster toastOptions={{ duration: 4000 }} />
      <Flex flex={1} flexWrap={"wrap"} gap={{base:"30px", md:"0px", lg:"0px"}}  w={"100%"} m={"auto"}>
        <Box w={{base:"50%", md:"auto", lg:"auto"}}>
        <Button onClick={handleBack} mb={4}>Back</Button>
        </Box>
        <Box w={{base:"100%", md:"60%", lg:"60%"}} m={"auto"}>
        <Flex bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"  borderRadius={"20px"} flex={1} flexWrap={"wrap"} justifyContent={{base:"start", md:"space-between", lg:"space-between"}} mr={{base:"auto", md:"auto", lg:"65px"}} alignItems={"center"}>
        <Box ml={{base:"20px", md:"auto", lg:"20px"}}>
          <Text fontFamily={"sans-serif"} fontWeight={"bold"} fontSize="17px" color={"black"} mb={4} mt={4}>Name: {selectedPlayer?.name}</Text>
          <Text fontWeight={"bold"} fontSize="17px" color={"black"} mb={4}>Email: {selectedPlayer?.email || "abc@gmail.com"}</Text>
        </Box>
        <Box ml={{base:"20px", md:"auto", lg:"20px"}} mr={{base:"0px", md:"auto", lg:"20px"}}>
          <Text fontWeight={"bold"} fontSize="17px" color={"black"} mb={4} mt={4}>AthleteCode: {selectedPlayer?.athleteCode || "000000"}</Text>
          <Text fontWeight={"bold"} fontSize="17px" color={"black"} mb={4}>Event: {game === "MensPair"? "Individual Men": game === "WomensPair"?"Individual Women": game}</Text>
        </Box>
        </Flex>
        </Box>
      </Flex>
      <br/>
      {/* <Text fontSize="2xl" mb={4}>{selectedPlayer?.name}'s Score Details</Text> */}
      <Flex flex={1} justifyContent={"space-evenly"}>
      <VStack align="start" mt={4}>
        {/* <Text><strong>Total:</strong> {total}</Text> */}
        {selectedPlayer && (
          <Table color={"black"} textAlign={"center"} variant="striped" width={{base:"100%", md:"55%", lg:"55%"}} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray">
          <Thead>
            <Tr p={"10px"}>
              <Th color={"white"} >Type</Th>
              <Th color={"white"} textAlign={"center"}>Score</Th>
              <Th color={"white"} textAlign={"end"}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
            <Td fontWeight={"bold"}>E1</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.judge1 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button size="xs" onClick={() => openScoreModal('judge1')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>E2</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.judge2 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button background={"none"} size="xs" onClick={() => openScoreModal('judge2')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>E3</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.judge3 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button size="xs" onClick={() => openScoreModal('judge3')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>E4</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.judge4 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button background={"none"} size="xs" onClick={() => openScoreModal('judge4')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>E</Td>
            <Td fontWeight={"bold"} textAlign={"center"}><Text>{calculateScoreWithTolerance([
              selectedPlayer.scores.judge1,
              selectedPlayer.scores.judge2,
              selectedPlayer.scores.judge3,
              selectedPlayer.scores.judge4
            ].filter(score => score !== undefined))}</Text></Td>
            <Td textAlign={"end"}></Td>
            </Tr>
          </Tbody>
        </Table>
        )}
      </VStack>
      <VStack align="start" mt={4}>
        {/* <Text><strong>Total:</strong> {total}</Text> */}
        {selectedPlayer && (
          <Table color={"black"} textAlign={"center"} variant="striped" width={{base:"100%", md:"55%", lg:"55%"}} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray">
          <Thead>
            <Tr p={"10px"}>
              <Th color={"white"} >Type</Th>
              <Th color={"white"} textAlign={"center"}>Score</Th>
              <Th color={"white"} textAlign={"end"}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
            <Td fontWeight={"bold"}>A1</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.a1 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button size="xs" onClick={() => openScoreModal('a1')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>A2</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.a2 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button background={"none"} size="xs" onClick={() => openScoreModal('a2')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>A3</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.a3 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button size="xs" onClick={() => openScoreModal('a3')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>A4</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.a4 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button background={"none"} size="xs" onClick={() => openScoreModal('a4')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>A</Td>
            <Td fontWeight={"bold"} textAlign={"center"}><Text>{calculateScoreWithTolerance([
              selectedPlayer.scores.a1,
              selectedPlayer.scores.a2,
              selectedPlayer.scores.a3,
              selectedPlayer.scores.a4
            ].filter(score => score !== undefined))}</Text></Td>
            <Td></Td>
            </Tr>
          </Tbody>
        </Table>
        )}
      </VStack>
      <VStack align="start" mt={4}>
        {/* <Text><strong>Total:</strong> {total}</Text> */}
        {selectedPlayer && (
          <Table color={"black"} textAlign={"center"} variant="striped" width={{base:"100%", md:"55%", lg:"55%"}} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray">
          <Thead>
            <Tr p={"10px"}>
              <Th color={"white"} >Type</Th>
              <Th color={"white"} textAlign={"center"}>Score</Th>
              <Th color={"white"} textAlign={"end"}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
            <Td fontWeight={"bold"}>D1</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.d3 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button size="xs" onClick={() => openScoreModal('d3')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>D2</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.d2 || 'N/A'}</Td>
            <Td textAlign={"end"}><Button background={"none"} size="xs" onClick={() => openScoreModal('d2')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>Dividedby</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.dividedby || 'N/A'}</Td>
            <Td textAlign={"end"}><Button background={"none"} size="xs" onClick={() => openScoreModal('dividedby')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>D</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{<Td fontWeight={"bold"} textAlign={"center"}>{((Number(selectedPlayer.scores.d3) + Number(selectedPlayer.scores.d2))/ Number(selectedPlayer.scores.dividedby)).toFixed(3)}</Td>}</Td>
            <Td textAlign={"end"}></Td>
            </Tr>
          </Tbody>
        </Table>
        )}
      </VStack>
      <VStack align="start" mt={4}>
        {/* <Text><strong>Total:</strong> {total}</Text> */}
        {selectedPlayer && (
          <Table color={"black"} textAlign={"center"} variant="striped" width={{base:"100%", md:"55%", lg:"55%"}} paddingBottom={"1000px"} margin={"auto"} borderRadius={"20px"} bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)" colorScheme="gray">
          <Thead>
            <Tr p={"10px"}>
              <Th color={"white"} >Type</Th>
              <Th color={"white"} textAlign={"center"}>Score</Th>
              <Th color={"white"} textAlign={"end"}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
            <Td fontWeight={"bold"}>Penalty</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.penalty || 'N/A'}</Td>
            <Td textAlign={"end"}><Button size="xs" onClick={() => openScoreModal('penalty')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>CJP</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{selectedPlayer.scores.cjp || 'N/A'}</Td>
            <Td textAlign={"end"}><Button background={"none"} size="xs" onClick={() => openScoreModal('cjp')}><MdEdit style={{fontSize:"25px", color:"green"}} /></Button></Td>
            </Tr>
            <Tr>
            <Td fontWeight={"bold"}>Total</Td>
            <Td fontWeight={"bold"} textAlign={"center"}>{(Number (calculateScoreWithTolerance([
        selectedPlayer.scores.judge1,
        selectedPlayer.scores.judge2,
        selectedPlayer.scores.judge3,
        selectedPlayer.scores.judge4
      ].filter(score => score !== undefined))) +
        Number (calculateScoreWithTolerance([
        selectedPlayer.scores.a1,
        selectedPlayer.scores.a2,
        selectedPlayer.scores.a3,
        selectedPlayer.scores.a4
      ].filter(score => score !== undefined))) +
        (
        (Number(selectedPlayer.scores.d3) + Number(selectedPlayer.scores.d2))/ Number(selectedPlayer.scores.dividedby)
        ) - 
        Number(selectedPlayer.scores.penalty) - 
        Number(selectedPlayer.scores.cjp)).toFixed(3)}</Td>
            <Td textAlign={"end"}></Td>
            </Tr>
          </Tbody>
        </Table>
        )}
      </VStack>
      </Flex>
      <br/>
      <Box w={"55%"} m={"auto"}>
        <Flex flex={1} justifyContent={"space-between"} alignItems={"center"}>
        <Box mt={4} backgroundColor={"white"} borderRadius={"5px"} height={"40px"}>
        <Text padding={"8px"} fontWeight={"bold"}>Total Score: {(Number (calculateScoreWithTolerance([
        selectedPlayer.scores.judge1,
        selectedPlayer.scores.judge2,
        selectedPlayer.scores.judge3,
        selectedPlayer.scores.judge4
      ].filter(score => score !== undefined))) +
        Number (calculateScoreWithTolerance([
        selectedPlayer.scores.a1,
        selectedPlayer.scores.a2,
        selectedPlayer.scores.a3,
        selectedPlayer.scores.a4
      ].filter(score => score !== undefined))) +
        (
        (Number(selectedPlayer.scores.d3) + Number(selectedPlayer.scores.d2))/ Number(selectedPlayer.scores.dividedby)
        ) - 
        Number(selectedPlayer.scores.penalty) - 
        Number(selectedPlayer.scores.cjp)).toFixed(3)} </Text>        </Box>
        <Box textAlign={{base:"center", md:"end", lg:"end"}}>
        <Button mt={4} mr={4} colorScheme="green" isDisabled={disab}  onClick={handleSaveChanges}>Save Changes</Button>
        <Button mt={4} colorScheme="blue" onClick={openApproveModal}>Approve</Button> {/* Approve Button */}
        </Box>
        </Flex>
      
      </Box>


      {/* Score Edit Modal */}
      <Modal isOpen={isScoreModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit ({judgeToEdit === "judge1" ? "E1" : judgeToEdit === "judge2"? "E2" : judgeToEdit === "judge3"? "E3" : judgeToEdit === "judge4"? "E4" : judgeToEdit === "a1" ? "A1" : judgeToEdit === "a2"? "A2" : judgeToEdit === "a3"? "A3" : judgeToEdit === "a4"? "A4" : judgeToEdit === "d3"? "D1" : judgeToEdit === "d2"? "D2": judgeToEdit === "penalty"? "Penalty" : judgeToEdit === "cjp" ? "CJP" : judgeToEdit}) Score of {selectedPlayer?.name} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="number"
              id="score"
              value={score}
              onChange={(e) => setScore(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleScoreSubmit}>Save</Button>
            <Button variant="ghost" onClick={closeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Penalty Edit Modal */}
      {/* <Modal isOpen={isPenaltyModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Penalty for {selectedPlayer?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="number"
              id="penalty"
              value={penalty}
              onChange={(e) => setPenalty(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handlePenaltySubmit}>Save</Button>
            <Button variant="ghost" onClick={closeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}


      {/* Approve Modal */}
      <Modal isOpen={isApproveModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Approve Player</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to mark this player as completed?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleApprove}>Approve</Button>
            <Button variant="ghost" onClick={closeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PlayerScoreDetailsPageAerobic;
