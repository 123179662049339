import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react';
import TeamListPageAerobics from './TeamListPage2';
import TeamListPageAerobics3 from './TeamListPage3';

const TeamListTab = () => {
  return (
    <div>
        <Tabs>
            <TabList>
                <Tab>Basic</Tab>
                <Tab>Detailed</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <TeamListPageAerobics/>
                </TabPanel>
                <TabPanel>
                    <TeamListPageAerobics3/>
                </TabPanel>
            </TabPanels>
        </Tabs>
    </div>
  )
}

export default TeamListTab