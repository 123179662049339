import React, { useState } from 'react';
import axios from 'axios';
import {
    ChakraProvider, Box, Button, Text, VStack, HStack, Switch, Input, useToast,
    Select, useColorModeValue,
    useBreakpointValue,
    Flex,
} from '@chakra-ui/react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { API_URL } from "../../utils/apiConfig";
import { auto } from '@popperjs/core';
import { GiCheckMark } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";

const EventForm = () => {
    const [step, setStep] = useState(1);
    const [eventName, setEventName] = useState('');
    const [competitionLevel, setCompetitionLevel] = useState('');
    const [ageGroup, setAgeGroup] = useState('');
    const { user, loading: userLoading } = useAuth();
    const formBg = useColorModeValue("white", "gray.700");
    const inputBg = useColorModeValue("gray.50", "gray.800");
    const buttonSize = useBreakpointValue({ base: "sm", md: "md" });
    const [switches, setSwitches] = useState({
        MensPair: true,
        WomensPair: true,
        MixedPair: true,
        Trio: true,
        Group: true,
        AerobicDance: true,
        AerobicStep: true,
    });
    const navigate = useNavigate();
    const toast = useToast();

    const handleNextStep = () => {
        if (step < 3) setStep(step + 1);
    };

    const handlePrevStep = () => {
        if (step > 1) setStep(step - 1);
    };

    const [dateTime, setDateTime] = useState(new Date());

    const handleSwitchChange = (sport) => {
        setSwitches({ ...switches, [sport]: !switches[sport] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation checks
        if (!eventName) {
            toast({
                title: "Validation Error",
                description: "Please fill in the event name.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (!competitionLevel) {
            toast({
                title: "Validation Error",
                description: "Please select a competition level.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (!ageGroup) {
            toast({
                title: "Validation Error",
                description: "Please select a Age Group type.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }


        if (!dateTime) {
            toast({
                title: "Validation Error",
                description: "Please select a date and time.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        // if (category === 'men' && !Object.values(switches).some(v => v)) {
        //     toast({
        //         title: "Validation Error",
        //         description: "Please select at least one sport for men.",
        //         status: "error",
        //         duration: 3000,
        //         isClosable: true,
        //         position: "top",
        //     });
        //     return;
        // }

        // if (category === 'women' && !Object.values(switches).some(v => v)) {
        //     toast({
        //         title: "Validation Error",
        //         description: "Please select at least one sport for women.",
        //         status: "error",
        //         duration: 3000,
        //         isClosable: true,
        //         position: "top",
        //     });
        //     return;
        // }

        if (userLoading) {
            toast({
                title: "Loading User Data",
                description: "Please wait while we load your data.",
                status: "info",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (!user || !user.email) {
            toast({
                title: "Authentication Error",
                description: "User not authenticated. Please log in.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        const eventData = {
            eventName,
            competitionLevel,
            ageGroup,
            sports: switches,
            dateTime,
            event_admin: user.email,
        };
        try {
            const response = await axios.post(`${API_URL}/api/events-aerobic`, eventData);
            console.log(response.data);
            // alert("Event created successfully")
            toast({
                title: "Competition Created",
                description: "Your competition has been created successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            setTimeout(() => {
                navigate("/");
            }, 2000);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ChakraProvider>
            <Box p={5} bg={"gray.900"}>
                {step === 1 && (
                    <Box maxW="container.md"
                        mx="auto"
                        mt={8}
                        p={6}
                        boxShadow="lg"
                        borderRadius="md"
                        bg={formBg}>
                        <VStack spacing={8} align="stretch" p={"10px"}>
                            <Text fontSize="3xl" fontWeight="bold" textAlign="center">
                                CREATE AEROBIC COMPETITION
                            </Text>
                            <Box>
                            <Text fontSize="xl" mb={2}>Enter Competition Name</Text>
                            <Input
                                value={eventName}
                                onChange={(e) => setEventName(e.target.value)}
                                placeholder="Enter Competition Name"
                                bg={inputBg}
                                border={"2px solid gray"}
                                size="lg"
                            />
                            </Box>
                            <Box>
                            <Text fontSize="xl" mb={2}>Select Date and Time</Text>
                            <Box padding={"12px"} borderRadius={"10px"} border={"2px solid gray"}>
                            <DatePicker
                                selected={dateTime}
                                onChange={(date) => setDateTime(date)}
                                showTimeSelect
                                dateFormat="Pp"
                                bg={inputBg}
                                
                            />
                            </Box>
                            </Box>
                                <Box>
                                    <Text fontSize="xl" mb={2}>Select Competition Level</Text>
                                    <Select
                                        value={competitionLevel}
                                        border={"2px solid gray"}
                                        onChange={(e) => {
                                            setCompetitionLevel(e.target.value);
                                        }}
                                        placeholder="Select Competition Level"
                                        mb={4}
                                        bg={inputBg}
                                        size="lg"
                                    >
                                        <option value="national" style={{ color: "black" }}>National</option>
                                        <option value="state" style={{ color: "black" }}>State</option>
                                        <option value="district" style={{ color: "black" }}>District</option>
                                        <option value="club" style={{ color: "black" }}>Club</option>
                                        <option value="school" style={{ color: "black" }}>School</option>
                                        <option value="individual" style={{ color: "black" }}>Individual</option>
                                    </Select>
                                </Box>
                                <Box>
                                    <Text fontSize="xl" mb={2}>Select Age Group</Text>
                                    <Select
                                        value={ageGroup}
                                        border={"2px solid gray"}
                                        onChange={(e) => {
                                            setAgeGroup(e.target.value);
                                        }}
                                        placeholder="Select Age Group Type"
                                        mb={4}
                                        bg={inputBg}
                                        size="lg"
                                    >
                                        <option value="u6" style={{ color: "black" }}>U6</option>
                                        <option value="u8" style={{ color: "black" }}>U8</option>
                                        <option value="u10" style={{ color: "black" }}>U10</option>
                                        <option value="u12" style={{ color: "black" }}>U12</option>
                                        <option value="u14" style={{ color: "black" }}>U14</option>
                                        <option value="junior" style={{ color: "black" }}>Junior</option>
                                        <option value="senior" style={{ color: "black" }}>Senior</option>
                                    </Select>
                                </Box>
                                
                                {/* <VStack flex="1" justifyContent={"center"} alignItems="flex-start" > */}
                                {/* <VStack spacing={4} flex="1" alignItems="flex-start"> */}

                                
                                <Flex flex={1} justifyContent={"space-between"} gap={"10px"} flexWrap={"wrap"} w={"100%"} spacing={4}>
                                    <Box  w={{base:"100%", md:"100%",lg:"45%"}}>
                                    <VStack spacing={4}>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Individual Men</Text>
                                            <Switch
                                                isChecked={switches.MensPair}
                                                onChange={() => handleSwitchChange('MensPair')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Individual Women</Text>
                                            <Switch
                                                isChecked={switches.WomensPair}
                                                onChange={() => handleSwitchChange('WomensPair')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Mixed Pair</Text>
                                            <Switch
                                                isChecked={switches.MixedPair}
                                                onChange={() => handleSwitchChange('MixedPair')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Trio</Text>
                                            <Switch
                                                isChecked={switches.Trio}
                                                onChange={() => handleSwitchChange('Trio')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Group</Text>
                                            <Switch
                                                isChecked={switches.Group}
                                                onChange={() => handleSwitchChange('Group')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Aerobic Dance</Text>
                                            <Switch
                                                isChecked={switches.AerobicDance}
                                                onChange={() => handleSwitchChange('AerobicDance')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Aerobic Step</Text>
                                            <Switch
                                                isChecked={switches.AerobicStep}
                                                onChange={() => handleSwitchChange('AerobicStep')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                    </VStack>
                                </Box>
                                <Box  w={{base:"100%", md:"100%",lg:"45%"}}>

                                </Box>
                                </Flex>
                                
                                <HStack spacing={4}>
                                    {/* <Button onClick={handlePrevStep} colorScheme="gray">Back</Button> */}
                                    <Button m={"auto"} p={"10px 30px"} onClick={handleNextStep} colorScheme="blue">Next</Button>
                                </HStack>
                                {/* </VStack> */}
                                {/* </VStack> */}
                        </VStack>
                    </Box>
                )}
                {step === 2 && (
                    <Box maxW="container.md"
                    mx="auto"
                    mt={8}
                    p={6}
                    boxShadow="lg"
                    borderRadius="md"
                    bg={formBg}>
                    <Box spacing={4}>
                        <Text textAlign={"center"} fontSize="2xl">Review and Create Event</Text>
                        <Text fontSize="xl" mb={2}>Event Name: {eventName}</Text>
                        <Flex flex={1} justifyContent={"space-between"}>
                            <Box >
                                <Text fontSize="xl">Apparatus:</Text>
                                <ul>
                                        <>
                                            <Flex alignItems={"center"} gap={"10px"}><Box>IM: </Box><Box>{switches.MensPair ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                            <Flex alignItems={"center"} gap={"10px"}><Box>IW:</Box><Box> {switches.WomensPair ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                            <Flex alignItems={"center"} gap={"10px"}><Box>MXP:</Box><Box>{switches.MixedPair ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                            <Flex alignItems={"center"} gap={"10px"}><Box>TR:</Box><Box>{switches.Trio ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                            <Flex alignItems={"center"} gap={"10px"}><Box>GP:</Box><Box>{switches.Group ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                            <Flex alignItems={"center"} gap={"10px"}><Box>AD:</Box><Box>{switches.AerobicDance ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                            <Flex alignItems={"center"} gap={"10px"}><Box>AS:</Box><Box>{switches.AerobicStep ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                            

                                        </>
                                </ul>
                            </Box>
                        </Flex>
                        
                        {/* <Text>Date: {dateTime}</Text> */}
                        <HStack spacing={4}>
                            <Button onClick={handlePrevStep} colorScheme="gray">Back</Button>
                            <Button onClick={handleSubmit} colorScheme="green">Create Event</Button>
                        </HStack>
                    </Box>
                    </Box>
                )}
            </Box>
        </ChakraProvider>
    );
};

export default EventForm;
