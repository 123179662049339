import React, { useState } from 'react';
import axios from 'axios';
import {
    ChakraProvider, Box, Button, Text, VStack, HStack, Switch, Input, useToast,
    Select, useColorModeValue,
    useBreakpointValue,
    Flex,
} from '@chakra-ui/react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { API_URL } from "../utils/apiConfig";
import { auto } from '@popperjs/core';
import { GiCheckMark } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";

const EventForm = () => {
    const [step, setStep] = useState(1);
    const [eventName, setEventName] = useState('');
    const [competitionLevel, setCompetitionLevel] = useState('');
    const [ageGroup, setAgeGroup] = useState('');
    const [category, setCategory] = useState('');
    const { user, loading: userLoading } = useAuth();
    const formBg = useColorModeValue("white", "gray.700");
    const inputBg = useColorModeValue("gray.50", "gray.800");
    const buttonSize = useBreakpointValue({ base: "sm", md: "md" });
    const [switches, setSwitches] = useState({
        floor: false,
        pommel_horse: false,
        rings: false,
        vaultMen: false,
        vault1Men: false,
        vault2Men: false,
        parallel_bars: false,
        horizontal_bar: false,
        vaultWomen: false,
        vault1Women: false,
        vault2Women: false,
        uneven_bars: false,
        balance_beam: false,
        floor_exercise: false,
    });
    const navigate = useNavigate();
    const toast = useToast();

    const [resultList, setResultList] = useState({
        individual: true,
        allRound: true,
        team: true,
    });

    const [numVaults, setNumVaults] = useState("");
    const [teamFormat, setTeamFormat] = useState({ input1: 0, input2: 0, result: 0 });

    const handleNextStep = () => {
        if (step < 3) setStep(step + 1);
    };

    const handlePrevStep = () => {
        if (step > 1) setStep(step - 1);
    };


    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [result, setResult] = useState('');
    const [dateTime, setDateTime] = useState(new Date());

    const handleInputChange = (e, inputNumber) => {
        const value = e.target.value;
        if (inputNumber === 1) {
            setInput1(value);
        } else if (inputNumber === 2) {
            setInput2(value);
        }
        const sum = (Number(inputNumber === 1 ? value : input1) || 0) + (Number(inputNumber === 2 ? value : input2) || 0);
        setResult(sum);
    };

    const handleVaultChange = (numVaults) => {
        setNumVaults(numVaults);

        // For men
        if (category === 'men') {
            if (numVaults === 'one') {
                setSwitches({
                    ...switches,
                    vaultMen: true,
                    vault1Men: false,
                    vault2Men: false,
                });
            } else if (numVaults === 'two') {
                setSwitches({
                    ...switches,
                    vaultMen: false,
                    vault1Men: true,
                    vault2Men: true,
                });
            }
        }

        // For women
        if (category === 'women') {
            if (numVaults === 'one') {
                setSwitches({
                    ...switches,
                    vaultWomen: true,
                    vault1Women: false,
                    vault2Women: false,
                });
            } else if (numVaults === 'two') {
                setSwitches({
                    ...switches,
                    vaultWomen: false,
                    vault1Women: true,
                    vault2Women: true,
                });
            }
        }
    };

    const handleCategoryChange = (newCategory) => {
        setCategory(newCategory);

        // Default settings for men
        if (newCategory === 'men') {
            setSwitches({
                floor: true,
                pommel_horse: true,
                rings: true,
                vaultMen: numVaults === 'one',
                vault1Men: numVaults === 'two',
                vault2Men: numVaults === 'two',
                parallel_bars: true,
                horizontal_bar: true,
                vaultWomen: false,
                uneven_bars: false,
                balance_beam: false,
                floor_exercise: false,
            });
        }

        // Default settings for women
        else if (newCategory === 'women') {
            setSwitches({
                floor: false,
                pommel_horse: false,
                rings: false,
                vaultMen: false,
                vault1Men: false,
                vault2Men: false,
                parallel_bars: false,
                horizontal_bar: false,
                vaultWomen: numVaults === 'one',
                vault1Women: numVaults === 'two',
                vault2Women: numVaults === 'two',
                uneven_bars: true,
                balance_beam: true,
                floor_exercise: true,
            });
        }
    };


    const handleSwitchChange = (sport) => {
        setSwitches({ ...switches, [sport]: !switches[sport] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation checks
        if (!eventName) {
            toast({
                title: "Validation Error",
                description: "Please fill in the event name.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (!competitionLevel) {
            toast({
                title: "Validation Error",
                description: "Please select a competition level.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (!ageGroup) {
            toast({
                title: "Validation Error",
                description: "Please select a Age Group type.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }


        if (!category) {
            toast({
                title: "Validation Error",
                description: "Please select a category.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (!dateTime) {
            toast({
                title: "Validation Error",
                description: "Please select a date and time.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (numVaults === "") {
            toast({
                title: "Validation Error",
                description: "Please select the number of vaults.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (category === 'men' && !Object.values(switches).some(v => v)) {
            toast({
                title: "Validation Error",
                description: "Please select at least one sport for men.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (category === 'women' && !Object.values(switches).some(v => v)) {
            toast({
                title: "Validation Error",
                description: "Please select at least one sport for women.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (userLoading) {
            toast({
                title: "Loading User Data",
                description: "Please wait while we load your data.",
                status: "info",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (!user || !user.email) {
            toast({
                title: "Authentication Error",
                description: "User not authenticated. Please log in.",
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        const eventData = {
            eventName,
            competitionLevel,
            ageGroup,
            category,
            sports: switches,
            numVaults,
            resultList,
            teamFormat: {
                input1,
                input2,
                result
            },
            dateTime,
            event_admin: user.email,
        };
        try {
            const response = await axios.post(`${API_URL}/api/events`, eventData);
            console.log(response.data);
            // alert("Event created successfully")
            toast({
                title: "Competition Created",
                description: "Your competition has been created successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top",
            });
            setTimeout(() => {
                navigate("/");
            }, 2000);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ChakraProvider>
            <Box p={5} bg={"gray.900"}>
                {step === 1 && (
                    <Box maxW="container.md"
                        mx="auto"
                        mt={8}
                        p={6}
                        boxShadow="lg"
                        borderRadius="md"
                        bg={formBg}>
                        <VStack spacing={8} align="stretch" p={"10px"}>
                            <Text fontSize="3xl" fontWeight="bold" textAlign="center">
                                CREATE COMPETITION
                            </Text>
                            <Box>
                            <Text fontSize="xl" mb={2}>Enter Competition Name</Text>
                            <Input
                                value={eventName}
                                onChange={(e) => setEventName(e.target.value)}
                                placeholder="Enter Competition Name"
                                bg={inputBg}
                                border={"2px solid gray"}
                                size="lg"
                            />
                            </Box>
                            <Box>
                            <Text fontSize="xl" mb={2}>Select Date and Time</Text>
                            <Box padding={"12px"} borderRadius={"10px"} border={"2px solid gray"}>
                            <DatePicker
                                selected={dateTime}
                                onChange={(date) => setDateTime(date)}
                                showTimeSelect
                                dateFormat="Pp"
                                bg={inputBg}
                                
                            />
                            </Box>
                            </Box>
                                <Box>
                                    <Text fontSize="xl" mb={2}>Select Competition Level</Text>
                                    <Select
                                        value={competitionLevel}
                                        border={"2px solid gray"}
                                        onChange={(e) => {
                                            setCompetitionLevel(e.target.value);
                                        }}
                                        placeholder="Select Competition Level"
                                        mb={4}
                                        bg={inputBg}
                                        size="lg"
                                    >
                                        <option value="national" style={{ color: "black" }}>National</option>
                                        <option value="state" style={{ color: "black" }}>State</option>
                                        <option value="district" style={{ color: "black" }}>District</option>
                                        <option value="club" style={{ color: "black" }}>Club</option>
                                        <option value="school" style={{ color: "black" }}>School</option>
                                        <option value="individual" style={{ color: "black" }}>Individual</option>
                                    </Select>
                                </Box>
                                <Box>
                                    <Text fontSize="xl" mb={2}>Select Age Group</Text>
                                    <Select
                                        value={ageGroup}
                                        border={"2px solid gray"}
                                        onChange={(e) => {
                                            setAgeGroup(e.target.value);
                                        }}
                                        placeholder="Select Age Group Type"
                                        mb={4}
                                        bg={inputBg}
                                        size="lg"
                                    >
                                        <option value="u6" style={{ color: "black" }}>U6</option>
                                        <option value="u8" style={{ color: "black" }}>U8</option>
                                        <option value="u10" style={{ color: "black" }}>U10</option>
                                        <option value="u12" style={{ color: "black" }}>U12</option>
                                        <option value="u14" style={{ color: "black" }}>U14</option>
                                        <option value="junior" style={{ color: "black" }}>Junior</option>
                                        <option value="senior" style={{ color: "black" }}>Senior</option>
                                    </Select>
                                </Box>
                                
                                {/* <VStack flex="1" justifyContent={"center"} alignItems="flex-start" > */}
                                {/* <VStack spacing={4} flex="1" alignItems="flex-start"> */}
                                <Box >
                            <Text fontSize="xl" mb={2}>Team Format</Text>
                            <HStack spacing={4}>
                                <Input
                                    type="number"
                                    value={input1}
                                    onChange={(e) => handleInputChange(e, 1)}
                                    border={"2px solid gray"}
                                />
                                <Text>+</Text>
                                <Input
                                    type="number"
                                    value={input2}
                                    onChange={(e) => handleInputChange(e, 2)}
                                    border={"2px solid gray"}
                                />
                                <Text>=</Text>
                                <Input border={"2px solid gray"} value={result} isReadOnly />
                            </HStack>
                        </Box>

                        <Box spacing={4}>
                                    <Text fontSize="xl" mb={2}>Select Number of Vaults:</Text>
                                    {/* <RadioGroup onChange={setNumVaults} value={numVaults}> */}
                                    {/* <HStack spacing={4}>
                                    <Radio value="one">One</Radio>
                                    <Radio value="two">Two</Radio>
                                </HStack> */}
                                    <Flex flex={1} justifyContent={"space-between"} spacing={4}>
                                        <Button border={"2px solid gray"} w={"45%"} onClick={() => handleVaultChange('one')} colorScheme={numVaults === 'one' ? 'blue' : 'gray'}>One</Button>
                                        <Button border={"2px solid gray"} w={"45%"} onClick={() => handleVaultChange('two')} colorScheme={numVaults === 'two' ? 'blue' : 'gray'}>Two</Button>
                                    </Flex>

                                    {/* </RadioGroup> */}
                                </Box>

                                
                                <Flex flex={1} justifyContent={"space-between"} gap={"10px"} flexWrap={"wrap"} w={"100%"} spacing={4}>
                                    <Box  w={{base:"100%", md:"100%",lg:"45%"}}>
                                    <Text fontSize="xl" mb={2}>Select Category</Text>
                                    <Button mb={4} w={"50%"} onClick={() => handleCategoryChange('men')} colorScheme='teal' variant='outline'>Men</Button>
                                    <Button mb={4} w={"50%"} onClick={() => handleCategoryChange('women')} colorScheme="pink" variant='outline'>Women</Button>
                                {category === 'men' && (
                                    <VStack spacing={4}>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Floor</Text>
                                            <Switch
                                                isChecked={switches.floor}
                                                onChange={() => handleSwitchChange('floor')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Pommel Horse</Text>
                                            <Switch
                                                isChecked={switches.pommel_horse}
                                                onChange={() => handleSwitchChange('pommel_horse')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Rings</Text>
                                            <Switch
                                                isChecked={switches.rings}
                                                onChange={() => handleSwitchChange('rings')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>

                                        {switches.vaultMen === true ?
                                            <HStack justifyContent="space-between" width="100%">
                                                <Text>Vault</Text>
                                                <Switch
                                                    isChecked={switches.vaultMen}
                                                    onChange={() => handleSwitchChange('vaultMen')}
                                                    colorScheme="yellow"
                                                />
                                            </HStack> : <>
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text>Vault 1</Text>
                                                    <Switch
                                                        isChecked={switches.vault1Men}
                                                        onChange={() => handleSwitchChange('vault1Men')}
                                                        colorScheme="yellow"
                                                    />
                                                </HStack>
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text>Vault 2</Text>
                                                    <Switch
                                                        isChecked={switches.vault2Men}
                                                        onChange={() => handleSwitchChange('vault2Men')}
                                                        colorScheme="yellow"
                                                    />
                                                </HStack>
                                            </>
                                        }

                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Parallel Bars</Text>
                                            <Switch
                                                isChecked={switches.parallel_bars}
                                                onChange={() => handleSwitchChange('parallel_bars')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Horizontal Bar</Text>
                                            <Switch
                                                isChecked={switches.horizontal_bar}
                                                onChange={() => handleSwitchChange('horizontal_bar')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                    </VStack>
                                )}
                                {category === 'women' && (
                                    <VStack spacing={4}>
                                        {switches.vaultWomen === true ?
                                            <HStack justifyContent="space-between" width="100%">
                                                <Text>Vault</Text>
                                                <Switch
                                                    isChecked={switches.vaultWomen}
                                                    onChange={() => handleSwitchChange('vaultWomen')}
                                                    colorScheme="yellow"
                                                />
                                            </HStack> :
                                            <>
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text>Vault 1</Text>
                                                    <Switch
                                                        isChecked={switches.vault1Women}
                                                        onChange={() => handleSwitchChange('vault1Women')}
                                                        colorScheme="yellow"
                                                    />
                                                </HStack>
                                                <HStack justifyContent="space-between" width="100%">
                                                    <Text>Vault 2</Text>
                                                    <Switch
                                                        isChecked={switches.vault2Women}
                                                        onChange={() => handleSwitchChange('vault2Women')}
                                                        colorScheme="yellow"
                                                    />
                                                </HStack>
                                            </>
                                        }

                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Uneven Bars</Text>
                                            <Switch
                                                isChecked={switches.uneven_bars}
                                                onChange={() => handleSwitchChange('uneven_bars')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Balance Beam</Text>
                                            <Switch
                                                isChecked={switches.balance_beam}
                                                onChange={() => handleSwitchChange('balance_beam')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <Text>Floor Exercise</Text>
                                            <Switch
                                                isChecked={switches.floor_exercise}
                                                onChange={() => handleSwitchChange('floor_exercise')}
                                                colorScheme="yellow"
                                            />
                                        </HStack>
                                    </VStack>
                                )}
                                </Box>
                                <Box  w={{base:"100%", md:"100%",lg:"45%"}}>
                                <VStack spacing={4}>
                            <Text  w={"100%"} fontSize="xl" textAlign={"start"} >Result List:</Text>
                            <HStack justifyContent="space-between" width="100%">
                                <Text>Individual</Text>
                                <Switch
                                    isChecked={resultList.individual}
                                    colorScheme="yellow"
                                    onChange={() => setResultList({ ...resultList, individual: !resultList.individual })}
                                />
                            </HStack>
                            <HStack justifyContent="space-between" width="100%">
                                <Text>All Round</Text>
                                <Switch
                                    isChecked={resultList.allRound}
                                    colorScheme="yellow"
                                    onChange={() => setResultList({ ...resultList, allRound: !resultList.allRound })}
                                />
                            </HStack>
                            <HStack justifyContent="space-between" width="100%">
                                <Text>Team</Text>
                                <Switch
                                    isChecked={resultList.team}
                                    colorScheme="yellow"
                                    onChange={() => setResultList({ ...resultList, team: !resultList.team })}
                                />
                            </HStack>
                        </VStack>

                                </Box>
                                </Flex>
                                
                                <HStack spacing={4}>
                                    {/* <Button onClick={handlePrevStep} colorScheme="gray">Back</Button> */}
                                    <Button m={"auto"} p={"10px 30px"} onClick={handleNextStep} colorScheme="blue">Next</Button>
                                </HStack>
                                {/* </VStack> */}
                                {/* </VStack> */}
                        </VStack>
                    </Box>
                )}
                {/* {step === 2 && (
                    <Box maxW="container.md"
                    mx="auto"
                    mt={8}
                    p={6}
                    boxShadow="lg"
                    borderRadius="md"
                    bg={formBg}>
                    <VStack spacing={4}>
                        <VStack spacing={4}>
                            <Text fontSize="xl" mb={2}>Result List:</Text>
                            <HStack justifyContent="space-between" width="100%">
                                <Text>Individual</Text>
                                <Switch
                                    isChecked={resultList.individual}
                                    colorScheme="yellow"
                                    onChange={() => setResultList({ ...resultList, individual: !resultList.individual })}
                                />
                            </HStack>
                            <HStack>
                                <Text justifyContent="space-between" width="100%">All Round</Text>
                                <Switch
                                    isChecked={resultList.allRound}
                                    colorScheme="yellow"
                                    onChange={() => setResultList({ ...resultList, allRound: !resultList.allRound })}
                                />
                            </HStack>
                            <HStack justifyContent="space-between" width="100%">
                                <Text>Team</Text>
                                <Switch
                                    isChecked={resultList.team}
                                    colorScheme="yellow"
                                    onChange={() => setResultList({ ...resultList, team: !resultList.team })}
                                />
                            </HStack>
                        </VStack>


                        <VStack spacing={4}>
                            <Text fontSize="xl" mb={2}>Team Format</Text>
                            <HStack spacing={4}>
                                <Input
                                    type="number"
                                    value={input1}
                                    onChange={(e) => handleInputChange(e, 1)}
                                />
                                <Text>+</Text>
                                <Input
                                    type="number"
                                    value={input2}
                                    onChange={(e) => handleInputChange(e, 2)}
                                />
                                <Text>=</Text>
                                <Input value={result} isReadOnly />
                            </HStack>
                        </VStack>

                        <VStack spacing={4}>
                            <Text fontSize="xl" mb={2}>Select Date and Time:</Text>
                            <DatePicker
                                selected={dateTime}
                                onChange={(date) => setDateTime(date)}
                                showTimeSelect
                                dateFormat="Pp"
                            />
                        </VStack>



                        <HStack spacing={4}>
                            <Button onClick={handlePrevStep} colorScheme="gray">Back</Button>
                            <Button onClick={handleNextStep} colorScheme="blue">Next</Button>
                        </HStack>
                    </VStack>
                    </Box>

                )} */}
                {step === 2 && (
                    <Box maxW="container.md"
                    mx="auto"
                    mt={8}
                    p={6}
                    boxShadow="lg"
                    borderRadius="md"
                    bg={formBg}>
                    <Box spacing={4}>
                        <Text textAlign={"center"} fontSize="2xl">Review and Create Event</Text>
                        <Text fontSize="xl" mb={2}>Event Name: {eventName}</Text>
                        <Flex flex={1} justifyContent={"space-between"}>
                            <Box>
                                <Text fontSize="xl" >Category: {category==="men"?"MAG":"WAG"}</Text>
                                <Text fontSize="xl">No of Vaults: {numVaults === "one" ? 1 : numVaults === "two" ? 2 : ""}</Text>
                                <Text fontSize="xl">Team format: {result}</Text>
                            </Box>
                            <Box >
                                <Text fontSize="xl">Apparatus:</Text>
                                <ul>
                                    {category === 'men' && (
                                        <>
                                            <Flex alignItems={"center"} gap={"10px"}><Box>FX: </Box><Box>{switches.floor ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                            <Flex alignItems={"center"} gap={"10px"}><Box>PH:</Box><Box> {switches.pommel_horse ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                            <Flex alignItems={"center"} gap={"10px"}><Box>SR:</Box><Box>{switches.rings ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                            {switches.vaultMen === true ?
                                                <Flex alignItems={"center"} gap={"10px"}><Box>VT: </Box><Box>{switches.vaultMen ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex> :
                                                <>
                                                <Flex alignItems={"center"} gap={"10px"}><Box>V1: </Box><Box>{switches.vault1Men ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                                <Flex alignItems={"center"} gap={"10px"}><Box>V2: </Box><Box>{switches.vault2Men ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                                </>
                                            }


                                            <Flex alignItems={"center"} gap={"10px"}><Box>PB: </Box><Box>{switches.parallel_bars ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                            <Flex alignItems={"center"} gap={"10px"}><Box>HB: </Box><Box>{switches.horizontal_bar ? <GiCheckMark color='green'/> : <RxCross2 color='red'/>}</Box></Flex>
                                        </>
                                    )}
                                    {category === 'women' && (
                                        <>
                                            {switches.vaultWomen === true ?
                                                <li>Vault: {switches.vaultWomen ? 'Selected' : 'Not Selected'}</li> :
                                                <>
                                                    <li>Vault 1: {switches.vault1Women ? 'Selected' : 'Not Selected'}</li>
                                                    <li>Vault 2: {switches.vault2Women ? 'Selected' : 'Not Selected'}</li>
                                                </>
                                            }
                                            <li>Uneven Bars: {switches.uneven_bars ? 'Selected' : 'Not Selected'}</li>
                                            <li>Balance Beam: {switches.balance_beam ? 'Selected' : 'Not Selected'}</li>
                                            <li>Floor Exercise: {switches.floor_exercise ? 'Selected' : 'Not Selected'}</li>
                                        </>
                                    )}
                                </ul>
                            </Box>
                        </Flex>
                        
                        {/* <Text>Date: {dateTime}</Text> */}
                        <HStack spacing={4}>
                            <Button onClick={handlePrevStep} colorScheme="gray">Back</Button>
                            <Button onClick={handleSubmit} colorScheme="green">Create Event</Button>
                        </HStack>
                    </Box>
                    </Box>
                )}
            </Box>
        </ChakraProvider>
    );
};

export default EventForm;
