import React, { useState, useEffect } from 'react';
import { Box, Text, VStack, Spinner,Flex, Button, Tab, Tabs, TabList, TabPanels, TabPanel, Avatar, AvatarBadge, Heading } from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from '../../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import cardBackground from "../../../judgebackground.png";
import { useNotifications } from '../../../context/NotificationContext';
import { IoIosNotifications } from "react-icons/io";
import { useDisclosure } from '@chakra-ui/react';
import ManageEventModal from '../../RoughWork.js/TeamManageEvent';
import {API_URL} from "../../../utils/apiConfig";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  List, ListItem
} from '@chakra-ui/react'

const SupervisorDashboardAerobics = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, loading: userLoading } = useAuth();
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const btnRef = React.useRef();
  const navigate = useNavigate();
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [isNewNotificationStored, setIsNewNotificationStored] = useState(false);

  const { isNewNotification, notifications, clearNewNotification } = useNotifications();

  const setIsNewNotification = (value) => {
    setIsNewNotificationStored(value);
    localStorage.setItem('isNewNotification', value);
  };

  useEffect(() => {
    if (!userLoading && user) {
      const fetchEvents = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/events-supervisor-aerobic`);
          const allEvents = response.data;

          const userEvents = allEvents.map(event => {
            let isSupervisor = false;
            const updatedEvent = { ...event, judgedPlayers: {} };

            if (event.supervisors) {
              Object.entries(event.supervisors).forEach(([game, supervisorsObj]) => {
                if (supervisorsObj) {
                  Object.values(supervisorsObj).forEach(supervisor => {
                    if (supervisor && (supervisor.name.includes(user.email) || supervisor.mobile === user.phoneNumber)) {
                      isSupervisor = true;
                      updatedEvent.judgedPlayers[game] = event.supervisors[game] || [];
                    }
                  });
                }
              });
            }

            return isSupervisor ? updatedEvent : null;
          }).filter(event => event !== null);

          setEvents(userEvents);
        } catch (error) {
          console.error('Error fetching events:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchEvents();
    } else if (!userLoading && !user) {
      setEvents([]);
      setLoading(false);
    }
  }, [user, userLoading]);

  const openModal = () => setIsModalOpen1(true);
  const closeModal = () => setIsModalOpen1(false);

  const handleClose = () => {
    setIsNewNotificationStored(false);
    onDrawerClose(); // This closes the drawer
  };

  useEffect(() => {
    const isNewNotificationStored = localStorage.getItem('isNewNotification');
    if (isNewNotificationStored === 'true') {
      setIsNewNotification(true);
    }
  }, []);

  if (loading) {
    return <Spinner />;
  }

  // Filter notifications to show only those related to the supervisor's events
  const relevantNotifications = notifications.filter(notification => {
    return events.some(event => event._id === notification.eventId);
  });

  const handleViewNotifications = () => {
    clearNewNotification(); // Clear the new notification indicator when viewing notifications
    setIsModalOpen1(true);
  };

  const handleManage = (id) => {
    setSelectedEventId(id);
    onModalOpen();
  };

  const handleMoreDetails = (id) => {
    navigate(`/supervisorpanelresulttab/${id}`);
  };

  const handleTeamResult = (id) => {
    navigate(`/supervisorpanelTeamResult/${id}`);
  };

  const handleAllAthleteList = (id) => {
    navigate(`/supervisorpanelAthletelist/${id}`);
  };

  const handleCertficateButtonClick = (id) => {
    navigate(`/supervisorpanel/meritappratus/${id}`);
  };

  const handleCertficateListButtonClick = (id) => {
    navigate(`/supervisorpanel/certificatelist/${id}`);
  };



  const handleStatusChange = async (id, newStatus) => {
    try {
      await axios.put(`${API_URL}/api/events-aerobic/${id}`, { eventStatus: newStatus });
      setEvents(events.map(event => event._id === id ? { ...event, eventStatus: newStatus } : event));
    } catch (error) {
      console.error('Error updating event status:', error);
    }
  };

  const renderEvent = (event) => (
    <Box key={event._id} p={4} mb={6} borderRadius={"10px"} shadow="md" borderWidth="1px" sx={{
      backgroundColor: 'rgba(275, 245, 215, 0.4)',
      color: 'white',
      border: '1px solid white',
      _hover: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)'
      },
      _active: {
        backgroundColor: 'rgba(255, 255, 255, 0.)',
      }
    }}>
      <Text color={"#ffbf00"} fontSize="xl">{event.eventName}</Text>
      <Text>Competition Level: {event.competitionLevel.charAt(0).toUpperCase() + event.competitionLevel.slice(1)}</Text>
      <Text>Date: {new Date(event.dateTime).toLocaleString()}</Text>
      <VStack align="start" mt={4}>
        <Text fontSize="lg">Events:</Text>
        {event.judgedPlayers && Object.entries(event.judgedPlayers).map(([game, players]) => (
          <Box key={game}>
          <Link 
            to={
              game === 'MensPair' || game === 'WomensPair' 
                ? `/supervisorpanel/aerobics/${event._id}/IMW/${game}` 
                : `/supervisorpanel/aerobics/${event._id}/${game}`
            }
          >
            <Text color={"#ffbf00"} fontSize="md" fontWeight="bold">
              {/* {game.charAt(0).toUpperCase() + game.slice(1)} */}
              {game === "MensPair"? "Individual Men": game === "WomensPair"?"Individual Women": game}
            </Text>
          </Link>
        </Box>
        ))}
      </VStack>
      {event.eventStatus === "upcoming" && (
        <Button
          sx={{
            backgroundColor: "#ffbf00",
            color: "black",
            _hover: { backgroundColor: "rgba(255, 160, 0)" },
            _active: { backgroundColor: "rgba(255, 255, 255, 0.)" },
          }}
          margin={"auto"}
          colorScheme="blue"
          onClick={() => handleStatusChange(event._id, "live")}
        >
          Start Event
        </Button>
      )}
      <br/>
      {event.eventStatus === "live" && (
        <Button
          sx={{
            backgroundColor: "#ffbf00",
            color: "black",
            _hover: { backgroundColor: "rgba(255, 160, 0)" },
            _active: { backgroundColor: "rgba(255, 255, 255, 0.)" },
          }}
          margin={"auto"}
          colorScheme="blue"
          onClick={() => handleStatusChange(event._id, "ended")}
        >
          End Event
        </Button>
      )}
      <Button
        sx={{
          backgroundColor: "#ffbf00",
          color: "black",
          _hover: { backgroundColor: "rgba(255, 160, 0)" },
          _active: { backgroundColor: "rgba(255, 255, 255, 0.)" },
        }}
        margin={"auto"}
        colorScheme="blue"
        onClick={() => handleManage(event._id)}
      >
        Manage Competition
      </Button>
      <Button
        sx={{
          backgroundColor: "#ffbf00",
          color: "black",
          _hover: { backgroundColor: "rgba(255, 160, 0)" },
          _active: { backgroundColor: "rgba(255, 255, 255, 0.)" },
        }}
        margin={"auto"}
        colorScheme="blue"
        onClick={() => handleMoreDetails(event._id)}
      >
        All Around Result
      </Button>
      <Button
        sx={{
          backgroundColor: "#ffbf00",
          color: "black",
          _hover: { backgroundColor: "rgba(255, 160, 0)" },
          _active: { backgroundColor: "rgba(255, 255, 255, 0.)" },
        }}
        margin={"auto"}
        colorScheme="blue"
        onClick={() => handleTeamResult(event._id)}
      >
        Team Result
      </Button>
      <Button
        sx={{
          backgroundColor: "#ffbf00",
          color: "black",
          _hover: { backgroundColor: "rgba(255, 160, 0)" },
          _active: { backgroundColor: "rgba(255, 255, 255, 0.)" },
        }}
        margin={"auto"}
        colorScheme="blue"
        onClick={() => handleTeamResult(event._id)}
      >
        All Athlete List
      </Button>
      <Button
        sx={{
          backgroundColor: "#ffbf00",
          color: "black",
          _hover: { backgroundColor: "rgba(255, 160, 0)" },
          _active: { backgroundColor: "rgba(255, 255, 255, 0.)" },
        }}
        margin={"auto"}
        colorScheme="blue"
        onClick={() => handleCertficateButtonClick(event._id)}
      >
        Apparatus wise Merit Certificates
      </Button>
      <Button
        sx={{
          backgroundColor: "#ffbf00",
          color: "black",
          _hover: { backgroundColor: "rgba(255, 160, 0)" },
          _active: { backgroundColor: "rgba(255, 255, 255, 0.)" },
        }}
        margin={"auto"}
        colorScheme="blue"
        onClick={() => handleCertficateListButtonClick(event._id)}
      >
        Certificates List 
      </Button>
    </Box>
  );

  return (
    <Box p={4} minHeight={"90vh"} sx={{
      backgroundImage: `url(${cardBackground})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      color: "white",
    }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Text color={"#ffbf00"} fontWeight={"bold"} fontSize="2xl" mb={4}>Supervisor Dashboard</Text>
        {/* <Button w={"40px"} h={"40px"} p={"0px"} borderRadius={"50%"} onClick={openModal}><IoIosNotifications style={{ width: "60%", height: "60%" }} size={"50px"} /></Button> */}
        {/* <Button onClick={handleViewNotifications} w={"40px"} h={"40px"} p={"0px"} borderRadius={"50%"}>
        <IoIosNotifications color='black'  fontSize='1.7rem' />{isNewNotificationStored && <span style={{ width:"100px", color: 'red',position:'absolute', top:'-2', left:'10px' }}>● </span>}
      </Button> */}
        <Button ref={btnRef} onClick={() => { handleViewNotifications(); onDrawerOpen(); }}  w={"45px"} h={"45px"} p={"0px"} borderRadius={"50%"} spacing='1rem'>
          <Avatar backgroundColor="white" size={"sm"} icon={<IoIosNotifications color='black'  fontSize='1.7rem' />}>
          {isNewNotificationStored && 
          <AvatarBadge boxSize='.85em' bg='red' border={"none"} position='absolute' top='-2' right='0' />}
          </Avatar>
        </Button>
      </Box>
      <Tabs>
        <TabList>
          <Tab>Live Events</Tab>
          <Tab>Upcoming Events</Tab>
          <Tab>Recent Events</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {events.filter(event => event.eventStatus === 'live').length === 0 ? (
              <Heading>There is no live event going on</Heading>
            ) : (
              events.filter(event => event.eventStatus === 'live').map(renderEvent)
            )}
          </TabPanel>
          <TabPanel>
          {events.filter(event => event.eventStatus === 'upcoming').length === 0 ? (
              <Heading m={"auto"}>There is no upcoming events</Heading>
            ) : (
            events.filter(event => event.eventStatus === 'upcoming').map(renderEvent)
          )}
          </TabPanel>
          <TabPanel>
            {events.filter(event => event.eventStatus === 'ended').length === 0 ? (
              <Heading m={"auto"}>There is no completed events</Heading>
            ) : (
            events.filter(event => event.eventStatus === 'ended').map(renderEvent)
          )}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Drawer
        isOpen={isDrawerOpen}
        placement='right'
        onClose={handleClose}
        finalFocusRef={btnRef}
        size={'sm'}
      >
        <DrawerOverlay />
        <DrawerContent>
  <DrawerCloseButton />
  <DrawerHeader>Notifications</DrawerHeader>
  <DrawerBody>
    <List spacing={3}>
      {relevantNotifications.length === 0 ? (
        <Text>No notifications available</Text>
      ) : (
        relevantNotifications.map((notification) => {
          const messageParts = notification.message.split("Edited");
          return (
            <ListItem key={notification.id}>
              <Text>
                {messageParts[0]}
                {notification.message.includes("Edited") && (
                  <Text as="span" color="red" fontWeight="bold">
                    Edited
                  </Text>
                )}
                {messageParts[1]}
              </Text>
              <Flex alignItems={"center"}>
              <Text fontSize="xs" color="gray.500">
                {new Date(notification.timestamp).toLocaleString()}
              </Text><span>{notification.message.includes("Edited") && (
                  <Text as="span" fontSize="14px" ml={"5px"} color="gray.500">
                    ( Edited )
                  </Text>
                )}</span>
                </Flex>
            </ListItem>
          );
        })
      )}
    </List>
  </DrawerBody>
</DrawerContent>

      </Drawer>
      {selectedEventId && (
        <ManageEventModal
          isOpen={isModalOpen}
          onClose={onModalClose}
          eventId={selectedEventId}
        />
      )}
    </Box>
  );
};

export default SupervisorDashboardAerobics;
