import React, { useState, useEffect } from "react";
import { API_URL } from "../../utils/apiConfig";
import DatePicker from 'react-datepicker';
import {
  Box,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  HStack,
  Text,
  useDisclosure,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  List,
  ListItem,
  Avatar,
  useToast,
} from "@chakra-ui/react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
  } from '@chakra-ui/react'
import axios from "axios";
import { TiDelete } from "react-icons/ti";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { RiMore2Fill } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { AiOutlineUserAdd } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";

const ManageEventModal = ({isOpen, onClose, eventId, fetchEvents }) => {
    const [eventName, setEventName] = useState("");
    const [eventSports, setEventSports] = useState("");
    const [switches, setSwitches] = useState({
        MensPair: false,
        WomensPair: false,
        MixedPair: false,
        Trio: false,
        Group: false,
        AerobicDance: false,
        AerobicStep: false,
  });
    const [teams, setTeams] = useState([]);
    const [teamName, setTeamName] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [currentType, setCurrentType] = useState(null);
    const [currentPlayerIndex, setCurrentPlayerIndex] = useState(null);
    const [currentTeamIndex, setCurrentTeamIndex] = useState(null);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [judges, setJudges] = useState({});
    const [supervisors, setSupervisors] = useState({});
    const [judgeName, setJudgeName] = useState('');
  const [judgeMobile, setJudgeMobile] = useState('');
  const [supervisorName, setSupervisorName] = useState('');
  const [supervisorMobile, setSupervisorMobile] = useState('');
  const [isJudgeModalOpen, setIsJudgeModalOpen] = useState(false);
  const [isSupervisorModalOpen, setIsSupervisorModalOpen] = useState(false);
  const [currentGame, setCurrentGame] = useState('');
  const [currentRole, setCurrentRole] = useState('');
  const [searchQueryJudge1, setSearchQueryJudge1] = useState('');
  const [searchQueryJudge2, setSearchQueryJudge2] = useState('');
  const [searchQueryJudge3, setSearchQueryJudge3] = useState('');
  const [searchQueryJudge4, setSearchQueryJudge4] = useState('');
  const [searchQueryA1, setSearchQueryA1] = useState('');
  const [searchQueryA2, setSearchQueryA2] = useState('');
  const [searchQueryA3, setSearchQueryA3] = useState('');
  const [searchQueryA4, setSearchQueryA4] = useState('');
  const [searchQueryD1, setSearchQueryD1] = useState('');
  const [searchQueryD2, setSearchQueryD2] = useState('');
  const [searchQueryD3, setSearchQueryD3] = useState('');
  const [searchQuerySupervisor, setSearchQuerySupervisor] = useState('');
  const [searchResultsJudge1, setSearchResultsJudge1] = useState([]);
  const [searchResultsJudge2, setSearchResultsJudge2] = useState([]);
  const [searchResultsJudge3, setSearchResultsJudge3] = useState([]);
  const [searchResultsJudge4, setSearchResultsJudge4] = useState([]);
  const [searchResultsA1, setSearchResultsA1] = useState([]);
  const [searchResultsA2, setSearchResultsA2] = useState([]);
  const [searchResultsA3, setSearchResultsA3] = useState([]);
  const [searchResultsA4, setSearchResultsA4] = useState([]);
  const [searchResultsD1, setSearchResultsD1] = useState([]);
  const [searchResultsD2, setSearchResultsD2] = useState([]);
  const [searchResultsD3, setSearchResultsD3] = useState([]);
  const [searchResultsSupervisor, setSearchResultsSupervisor] = useState([]);
  const [selectedJudge1, setSelectedJudge1] = useState(null);
  const [selectedJudge2, setSelectedJudge2] = useState(null);
  const [selectedJudge3, setSelectedJudge3] = useState(null);
  const [selectedJudge4, setSelectedJudge4] = useState(null);
  const [selectedA1, setSelectedA1] = useState(null);
  const [selectedA2, setSelectedA2] = useState(null);
  const [selectedA3, setSelectedA3] = useState(null);
  const [selectedA4, setSelectedA4] = useState(null);
  const [selectedD1, setSelectedD1] = useState(null);
  const [selectedD2, setSelectedD2] = useState(null);
  const [selectedD3, setSelectedD3] = useState(null);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const toast = useToast();
  const { isOpen: isConfirmModalOpen, onOpen: onConfirmModalOpen, onClose: onConfirmModalClose } = useDisclosure();
  const { isOpen: isJudgeConfirmModalOpen, onOpen: onJudgeConfirmModalOpen, onClose: onJudgeConfirmModalClose } = useDisclosure();
  const { isOpen: isSupervisorConfirmModalOpen, onOpen: onSupervisorConfirmModalOpen, onClose: onSupervisorConfirmModalClose } = useDisclosure();
  
  
    const {
      isOpen: isTeamModalOpen,
      onOpen: onTeamModalOpen,
      onClose: onTeamModalClose,
    } = useDisclosure();
    const {
      isOpen: isPlayerModalOpen,
      onOpen: onPlayerModalOpen,
      onClose: onPlayerModalClose,
    } = useDisclosure();
    const {
      isOpen: isConfirmationModalOpen,
      onOpen: onConfirmationModalOpen,
      onClose: onConfirmationModalClose,
    } = useDisclosure();
  
    const groupConfig = {
      MensPair: 2,
      WomensPair: 2,
      MixedPair: 2,
      Trio: 3,
      Group: 5,
      AerobicDance: 8,
      AerobicStep: 8,
    };
  
    useEffect(() => {
      const fetchEvent = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/api/events-aerobic/${eventId}`
          );
          const event = response.data;
          setEventName(event.eventName);
          setSwitches(event.sports);
          setEventSports(event.sports);
          setJudges(event.judges || {});
        setSupervisors(event.supervisors || {});
          setTeams(event.teams || []);
        } catch (error) {
          console.error("Error fetching event:", error);
        }
      };
  
      if (eventId) {
        fetchEvent();
      }
    }, [eventId]);
  
    const handleAddTeam = () => {
      if (teamName.trim()) {
        setTeams([...teams, { teamName, aeroTypes: {} }]);
        setTeamName("");
        onTeamModalClose();
      }
    };
  
    const handleSearchPlayer = async (e) => {
      const query = e.target.value;
      setSearchQuery(query);
  
      if (query.length > 2) {
        try {
          const response = await axios.get(
            `${API_URL}/api/athlete/athletes?search=${query}`
          );
          setSearchResults(response.data);
        } catch (error) {
          console.error("Error searching players:", error);
        }
      } else {
        setSearchResults([]);
      }
    };
  
    const handleSelectPlayer = (player) => {
      setSelectedPlayer(player); // Temporarily store selected player
      onConfirmationModalOpen(); // Open confirmation modal
    };
  
    const handleConfirmAddPlayer = () => {
      const updatedTeams = [...teams];
      const currentTypeObj =
        updatedTeams[currentTeamIndex].aeroTypes[currentType] || {};
      currentTypeObj[`player${currentPlayerIndex}`] = selectedPlayer;
      updatedTeams[currentTeamIndex].aeroTypes[currentType] = currentTypeObj;
  
      setTeams(updatedTeams);
      setSearchQuery("");
      setSearchResults([]);
      onConfirmationModalClose();
      onPlayerModalClose();
    };
  
    const handleRemovePlayer = (type, playerIndex, teamIndex) => {
      const updatedTeams = [...teams];
      const currentTypeObj = updatedTeams[teamIndex].aeroTypes[type];
      if (currentTypeObj) {
        delete currentTypeObj[`player${playerIndex}`];
        updatedTeams[teamIndex].aeroTypes[type] = currentTypeObj;
      }
  
      setTeams(updatedTeams);
    };

    const handleAddJudge = () => {
        const newJudge = { name: judgeName, mobile: judgeMobile };
    
        const updatedJudges = { ...judges };
    
        if (!updatedJudges[currentGame]) {
          updatedJudges[currentGame] = { judge1: null, judge2: null, judge3: null, judge4: null,a1:null,a2:null,a3:null,a4:null, d1: null, d2:null, d3:null };
        }
    
        updatedJudges[currentGame][currentRole] = newJudge;
    
        setJudges(updatedJudges);
        setJudgeName('');
        setJudgeMobile('');
        setIsJudgeModalOpen(false);
      };
    
      const removeJudge = (sport, role) => {
        setJudges(prevJudges => {
          const updatedJudges = { ...prevJudges };
          if (updatedJudges[sport]) {
            delete updatedJudges[sport][role];
            if (Object.keys(updatedJudges[sport]).length === 0) {
              delete updatedJudges[sport];
            }
          }
          return updatedJudges;
        });
      };

      const handleAddSupervisor = () => {
        const newSupervisor = { name: supervisorName, mobile: supervisorMobile };
    
        const updatedSupervisors = { ...supervisors };
    
        if (!updatedSupervisors[currentGame]) {
          updatedSupervisors[currentGame] = { s1: null }
        }
    
        updatedSupervisors[currentGame][currentRole] = newSupervisor;
    
        setSupervisors(updatedSupervisors);
        setSupervisorName('');
        setSupervisorMobile('');
        setIsSupervisorModalOpen(false);
      };
    
      const removeSupervisor = (sport, role) => {
        setSupervisors(prevSupervisors => {
          const updatedSupervisors = { ...prevSupervisors };
          if (updatedSupervisors[sport]) {
            delete updatedSupervisors[sport][role];
            if (Object.keys(updatedSupervisors[sport]).length === 0) {
              delete updatedSupervisors[sport];
            }
          }
          return updatedSupervisors;
        });
      };
    
    
      const handleSearch = async (query, type) => {
        try {
          let response;
          if (type === 'judges' || type === 'supervisors') {
            response = await axios.get(`${API_URL}/api/auth/users?name=${query}`);
            const users = response.data.filter(user => user.role === type);
            setSearchResults(users);
          }
        } catch (error) {
          console.error('Error searching users:', error);
        }
      };

      const handleSearchJudge = async (e, role) => {
        const query = e.target.value;
        if (role === 'judge1') setSearchQueryJudge1(query);
        else if (role === 'judge2') setSearchQueryJudge2(query);
        else if (role === 'judge3') setSearchQueryJudge3(query);
        else if (role === 'judge4') setSearchQueryJudge4(query);
        else if (role === 'a1') setSearchQueryA1(query);
        else if (role === 'a2') setSearchQueryA2(query);
        else if (role === 'a3') setSearchQueryA3(query);
        else if (role === 'a4') setSearchQueryA4(query);
        else if (role === 'd1') setSearchQueryD1(query);
        else if (role === 'd2') setSearchQueryD2(query);
        else if (role === 'd3') setSearchQueryD3(query);
        else if (role === 's1') setSearchQuerySupervisor(query);
    
        if (query.length > 2) {
          try {
            const response = await axios.get(`${API_URL}/api/auth/users?name=${query}`);
            const results = response.data.filter(user => user.name.toLowerCase().includes(query.toLowerCase()));
            if (role === 'judge1') setSearchResultsJudge1(results);
            else if (role === 'judge2') setSearchResultsJudge2(results);
            else if (role === 'judge3') setSearchResultsJudge3(results);
            else if (role === 'judge4') setSearchResultsJudge4(results);
            else if (role === 'a1') setSearchResultsA1(results);
            else if (role === 'a2') setSearchResultsA2(results);
            else if (role === 'a3') setSearchResultsA3(results);
            else if (role === 'a4') setSearchResultsA4(results);
            else if (role === 'd1') setSearchResultsD1(results);
            else if (role === 'd2') setSearchResultsD2(results);
            else if (role === 'd3') setSearchResultsD3(results);
            else if (role === 's1') setSearchResultsSupervisor(results);
          } catch (error) {
            console.error('Error searching users:', error);
          }
        } else {
          if (role === 'judge1') setSearchResultsJudge1([]);
          else if (role === 'judge2') setSearchResultsJudge2([]);
          else if (role === 'judge3') setSearchResultsJudge3([]);
          else if (role === 'judge4') setSearchResultsJudge4([]);
          else if (role === 'a1') setSearchResultsA1([]);
          else if (role === 'a2') setSearchResultsA2([]);
          else if (role === 'a3') setSearchResultsA3([]);
          else if (role === 'a4') setSearchResultsA4([]);
          else if (role === 'd1') setSearchResultsD1([]);
          else if (role === 'd2') setSearchResultsD2([]);
          else if (role === 'd3') setSearchResultsD3([]);
          else if (role === 's1') setSearchResultsSupervisor([]);
        }
      };
    
      const handleSelectUser = (user, role) => {
        if (role === 'judge1') setSelectedJudge1(user);
        else if (role === 'judge2') setSelectedJudge2(user);
        else if (role === 'judge3') setSelectedJudge3(user);
        else if (role === 'judge4') setSelectedJudge4(user);
        else if (role === 'a1') setSelectedA1(user);
        else if (role === 'a2') setSelectedA2(user);
        else if (role === 'a3') setSelectedA3(user);
        else if (role === 'a4') setSelectedA4(user);
        else if (role === 'd1') setSelectedD1(user);
        else if (role === 'd2') setSelectedD2(user);
        else if (role === 'd3') setSelectedD3(user);
        else if (role === 's1') setSelectedSupervisor(user);
        onJudgeConfirmModalOpen();
      };
    
    
      const handleConfirmAddUser = async (role) => {
        let selectedUser = null;
        if (role === 'judge1') selectedUser = selectedJudge1;
        else if (role === 'judge2') selectedUser = selectedJudge2;
        else if (role === 'judge3') selectedUser = selectedJudge3;
        else if (role === 'judge4') selectedUser = selectedJudge4;
        else if (role === 'a1') selectedUser = selectedA1;
        else if (role === 'a2') selectedUser = selectedA2;
        else if (role === 'a3') selectedUser = selectedA3;
        else if (role === 'a4') selectedUser = selectedA4;
        else if (role === 'd1') selectedUser = selectedD1;
        else if (role === 'd2') selectedUser = selectedD2;
        else if (role === 'd3') selectedUser = selectedD3;
        else if (role === 's1') selectedUser = selectedSupervisor;
    
        if (selectedUser) {
          const updatedJudges = { ...judges };
          const updatedSupervisors = { ...supervisors };
    
          if (role === 'judge1' || role === 'judge2' || role === 'judge3' || role === 'judge4' || role === 'a1' || role === 'a2' || role === 'a3' || role === 'a4' || role === 'd1' || role === 'd2' || role === "d3") {
            if (!updatedJudges[currentGame]) {
              updatedJudges[currentGame] = { judge1: null, judge2: null, judge3: null, judge4: null, a1: null, a2: null, a3: null, a4: null, d1: null, d2: null, d3:null };
            }
            updatedJudges[currentGame][role] = {
              name: `${selectedUser.name} (${selectedUser.email})`,
              mobile: selectedUser.mobile,
              email: selectedUser.email,
            };
            setJudges(updatedJudges);
          } else if (role === 's1') {
            if (!updatedSupervisors[currentGame]) {
              updatedSupervisors[currentGame] = { s1: null }
            }
            updatedSupervisors[currentGame][role] = {
              name: `${selectedUser.name} (${selectedUser.email})`,
              mobile: selectedUser.mobile,
              email: selectedUser.email,
            };
            setSupervisors(updatedSupervisors);
          }
    
          toast({
            title: `${role.charAt(0).toUpperCase() + role.slice(1)} Added`,
            description: `${role.charAt(0).toUpperCase() + role.slice(1)} added successfully.`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
    
          onJudgeConfirmModalClose();
        }
      };
  
    const handleSaveChanges = async () => {
      try {
        await axios.patch(`${API_URL}/api/events-aerobic/${eventId}`, {
          teams,
          judges,
            supervisors,
        });
        alert("Teams saved successfully!");
      } catch (error) {
        console.error("Error saving teams:", error);
      }
    };
  return (
    <div>
        <Modal isOpen={isOpen} onClose={onClose} size="6xl" scrollBehavior="inside"
        trapFocus={false}  backgroundColor={"#edf2f7"}>
      <ModalOverlay />
      <ModalContent backgroundColor={"#edf2f7"}>
        <ModalHeader>Manage Compitition</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Tabs>
                <TabList>
                    <Tab>Add team & athletes</Tab>
                    <Tab>Add judges & supervisor</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                    <Button onClick={onTeamModalOpen} colorScheme="blue">
        Add Team
      </Button>

      

      <Modal isOpen={isTeamModalOpen} onClose={onTeamModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Team</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter team name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAddTeam}>
              Add
            </Button>
            <Button onClick={onTeamModalClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <VStack mt={4} spacing={4} align="stretch">
        {teams.map((team, teamIndex) => (
          <Box key={teamIndex} p={4} borderWidth="1px" borderRadius="md">
            <Text fontWeight="bold">{team.teamName}</Text>
            {Object.keys(groupConfig)
              .filter((type) => eventSports[type])
              .map((type) => (
                <Box key={type} mt={4}>
                  <Text>{type === "MensPair"? "Individual Men": type === "WomensPair"?"Individual Women": type}</Text>
                  <HStack spacing={2}>
                    {[...Array(groupConfig[type])].map((_, playerIndex) => {
                      const playerKey = `player${playerIndex + 1}`;
                      const currentPlayer =
                        team.aeroTypes?.[type]?.[playerKey];

                      return currentPlayer ? (
                        <HStack key={playerIndex}>
                          <Text>{currentPlayer.name}</Text>
                          <Button
                            size="sm"
                            colorScheme="red"
                            onClick={() =>
                              handleRemovePlayer(
                                type,
                                playerIndex + 1,
                                teamIndex
                              )
                            }
                          >
                            Remove
                          </Button>
                        </HStack>
                      ) : (
                        <Button
                          key={playerIndex}
                          size="sm"
                          onClick={() => {
                            setCurrentType(type);
                            setCurrentPlayerIndex(playerIndex + 1);
                            setCurrentTeamIndex(teamIndex);
                            onPlayerModalOpen();
                          }}
                        >
                          Add Player {playerIndex + 1}
                        </Button>
                      );
                    })}
                  </HStack>
                </Box>
              ))}
          </Box>
        ))}
      </VStack>
                    </TabPanel>
                    <TabPanel>
                {Object.keys(groupConfig)
                  .filter(sport => switches[sport]) // Filter to only show active games
                  .map((sport) => (
                    <Box key={sport} mt={5} p={3} style={{ overflowX: 'auto', width: '100%', padding: '20px 20px', backgroundColor:"white" }} borderWidth="1px" borderRadius="20px" w="100%">
                      <Text fontSize="xl" fontWeight="bold">{sport === "MensPair"? "Individual Men": sport === "WomensPair"?"Individual Women": sport}</Text>

                      {judges[sport] && judges[sport].judge1 ? (
                        <Flex style={{ border: "1px solid #ffbf00", marginTop: "10px", }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold" }}>E1</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {judges[sport].judge1.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].judge1.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].judge1.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].judge1.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'judge1')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove E1 judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge1');
                            setIsJudgeModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign E1 Judge
                        </Button>
                      )}
                      <br />

                      {judges[sport] && judges[sport].judge2 ? (
                        <Flex style={{ border: "1px solid #ffbf00" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold" }}>E2</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold" }}

                            >
                              {judges[sport].judge2.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].judge2.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].judge2.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].judge2.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'judge2')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove E3 judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge2');
                            setIsJudgeModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign E2 Judge
                        </Button>
                      )}
                      <br />

                      {judges[sport] && judges[sport].judge3 ? (
                        <Flex style={{ border: "1px solid gray" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", fontWeight: "bold",color: "#ffbf00" }}>E3</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {judges[sport].judge3.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].judge3.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].judge3.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].judge3.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'judge3')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove E3 Judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge3');
                            setIsJudgeModalOpen(true);

                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign E3 Judge
                        </Button>
                      )}

                      <br />

                      {judges[sport] && judges[sport].judge4 ? (
                        <Flex style={{ border: "1px solid gray" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", fontWeight: "bold",color: "#ffbf00" }}>E4</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold" }}

                            >
                              {judges[sport].judge4.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].judge4.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].judge4.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].judge4.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'judge4')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove E4 Judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('judge4');
                            setIsJudgeModalOpen(true);

                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign E4 Judge
                        </Button>
                      )}

                      <br />

                      {judges[sport] && judges[sport].a1 ? (
                        <Flex style={{ border: "1px solid #ffbf00", marginTop: "10px", }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold" }}>A1</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {judges[sport].a1.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].a1.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].a1.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].a1.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'a1')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove A1 judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('a1');
                            setIsJudgeModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign A1 Judge
                        </Button>
                      )}
                      <br />

                      {judges[sport] && judges[sport].a2 ? (
                        <Flex style={{ border: "1px solid #ffbf00", marginTop: "10px", }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold" }}>A2</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {judges[sport].a2.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].a2.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].a2.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].a2.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'a2')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove A2 judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('a2');
                            setIsJudgeModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign A2 Judge
                        </Button>
                      )}
                      <br />

                      {judges[sport] && judges[sport].a3 ? (
                        <Flex style={{ border: "1px solid #ffbf00", marginTop: "10px", }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold" }}>A3</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {judges[sport].a3.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].a3.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].a3.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].a3.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'a3')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove A3 judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('a3');
                            setIsJudgeModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign A3 Judge
                        </Button>
                      )}
                      <br />

                      {judges[sport] && judges[sport].a4 ? (
                        <Flex style={{ border: "1px solid #ffbf00", marginTop: "10px", }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold" }}>A4</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {judges[sport].a4.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].a4.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].a4.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].a4.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'a4')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove A4 judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('a4');
                            setIsJudgeModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign A4 Judge
                        </Button>
                      )}
                      <br />

                      {judges[sport] && judges[sport].d1 ? (
                        <Flex style={{ border: "1px solid gray" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", fontWeight: "bold", color: "#ffbf00" }}>CJP</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {judges[sport].d1.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].d1.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].d1.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].d1.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'd1')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove CJP judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        sx={{
                          backgroundColor: "#ffbf00", // Make the button background transparent
                          color: "black", // Button text color
                          _hover: {
                            backgroundColor: "rgba(255, 160, 0)", // Change background color on hover
                          },
                          _active: {
                            backgroundColor: "rgba(255, 255, 255, 0.)", // Change background color when active
                          },
                        }}  
                        colorScheme='yellow'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('d1');
                            setIsJudgeModalOpen(true);

                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign CJP Judge
                        </Button>
                      )}


                      <br />

                      {judges[sport] && judges[sport].d3 ? (
                        <Flex style={{ border: "1px solid gray" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", fontWeight: "bold", color: "#ffbf00" }}>D1</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {judges[sport].d3.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].d3.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].d3.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].d3.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'd3')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove D1 judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        sx={{
                          backgroundColor: "#ffbf00", // Make the button background transparent
                          color: "black", // Button text color
                          _hover: {
                            backgroundColor: "rgba(255, 160, 0)", // Change background color on hover
                          },
                          _active: {
                            backgroundColor: "rgba(255, 255, 255, 0.)", // Change background color when active
                          },
                        }}  
                        colorScheme='yellow'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('d3');
                            setIsJudgeModalOpen(true);

                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign D1 Judge
                        </Button>
                      )}

                      <br />

                      {judges[sport] && judges[sport].d2 ? (
                        <Flex style={{ border: "1px solid gray" }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", fontWeight: "bold", color: "#ffbf00" }}>D2</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {judges[sport].d2.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{judges[sport].d2.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {judges[sport].d2.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {judges[sport].d2.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeJudge(sport, 'd2')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove D2 judge
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        sx={{
                          backgroundColor: "#ffbf00", // Make the button background transparent
                          color: "black", // Button text color
                          _hover: {
                            backgroundColor: "rgba(255, 160, 0)", // Change background color on hover
                          },
                          _active: {
                            backgroundColor: "rgba(255, 255, 255, 0.)", // Change background color when active
                          },
                        }}  
                        colorScheme='yellow'
                          onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('d2');
                            setIsJudgeModalOpen(true);

                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign D2 Judge
                        </Button>
                      )}

                      <br />

                      {supervisors[sport] && supervisors[sport].s1 ? (
                        <Flex style={{ border: "1px solid #ffbf00", marginTop: "10px", }} justifyContent={"space-between"} borderRadius={"10px"} alignItems={"center"} backgroundColor={"white"} width={"100%"}
                        >
                          <Flex style={{}} padding={"10px"} alignItems={"center"} w={"82%"}>
                            <Text w={"10%"} style={{ textAlign: "center", color: "#ffbf00", fontWeight: "bold", backgroundColor:"black", borderRadius:"30%", border:"1px solid  #ffbf00" }}>S</Text>
                            <Text
                              colorScheme="red"
                              style={{ marginLeft: "10px", fontWeight: "bold"}}

                            >
                              {supervisors[sport].s1.name}
                            </Text>
                          </Flex>
                          <Flex w={"15%"} justifyContent={"end"} >
                            <Popover>
                              <PopoverTrigger>
                                <Box style={{
                                  as: "button",
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  // border:"1px solid green",
                                  // justifyContent: "center",
                                  backgroundColor: "white",
                                  alignItems: "end",
                                  padding: "0"
                                }} ><RiMore2Fill style={{ width: "80%", height: "80%", margin: "auto" }} /></Box>
                              </PopoverTrigger>
                              <PopoverContent style={{ textAlign: "start" }}>
                                <PopoverArrow />
                                <PopoverCloseButton fontWeight={"bold"} />
                                <PopoverHeader fontWeight={"bold"}>{supervisors[sport].s1.name}</PopoverHeader>
                                <PopoverBody>
                                  <Text><span style={{ fontWeight: "bold", textAlign: "start" }}>Email:</span> {supervisors[sport].s1.email}</Text>
                                  <Text><span style={{ fontWeight: "bold" }}>Mobile:</span> {supervisors[sport].s1.mobile}</Text>

                                  <Box
                                    as='button'
                                    onClick={() => removeSupervisor(sport, 's1')}
                                    color={"red"}
                                    fontWeight={"bold"}
                                  >
                                    Remove Supervisor
                                  </Box>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Flex>
                        </Flex>

                      ) : (
                        <Button
                        colorScheme='yellow' variant='outline'
                            onClick={() => {
                            setCurrentGame(sport);
                            setCurrentRole('s1');
                            setIsSupervisorModalOpen(true);
                          }}
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                          Assign Supervisor
                        </Button>
                      )}
                    </Box>
                  ))}
                  </TabPanel>
                </TabPanels>

            </Tabs>
            
        </ModalBody>
        <ModalFooter>
        <Button colorScheme="green" mt={4} onClick={handleSaveChanges}>
        Save Changes
      </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
        </Modal>

        ----------------------------------
        {/* Player Search Modal */}
      <Modal isOpen={isPlayerModalOpen} onClose={onPlayerModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Search Player</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter athlete code"
              value={searchQuery}
              onChange={handleSearchPlayer}
            />
            <VStack mt={2} align="stretch">
              {searchResults.map((player) => (
                <Button
                  key={player.id}
                  onClick={() => handleSelectPlayer(player)}
                  size="sm"
                  variant="outline"
                >
                  {player.name} - {player.athleteCode}
                </Button>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Confirmation Modal */}
      <Modal isOpen={isConfirmationModalOpen} onClose={onConfirmationModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Add Player</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to add {selectedPlayer?.name} as Player{" "}
              {currentPlayerIndex} in {currentType}?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleConfirmAddPlayer}>
              Yes
            </Button>
            <Button onClick={onConfirmationModalClose}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isJudgeModalOpen} onClose={() => setIsJudgeModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Judge</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              {/* <Input
          placeholder={`Search ${currentRole === 'judge1' ? 'Judge 1' : 'Judge 2'} by name...`}
          value={currentRole === 'judge1' ? searchQueryJudge1 : searchQueryJudge2}
          onChange={(e) => handleSearchJudge(e, currentRole)}
        /> */}
              <Input
                placeholder={`Search ${currentRole === 'judge1' ? 'Judge 1' : currentRole === 'judge2' ? 'Judge 2' : currentRole === 'judge3' ? 'Judge 3' : currentRole === 'judge4' ? 'Judge 4' :currentRole === 'a1' ? 'A 1' : currentRole === 'a2' ? 'A 2' : currentRole === 'a3' ? 'A 3' : currentRole === 'a4' ? 'A 4': currentRole === 'd1' ? 'CJP': currentRole === 'd2' ? 'D 2' : currentRole === "d3"? "D 1" : 'D'} by name...`}
                value={currentRole === 'judge1' ? searchQueryJudge1 : currentRole === 'judge2' ? searchQueryJudge2 : currentRole === 'judge3' ? searchQueryJudge3 : currentRole === 'judge4' ? searchQueryJudge4 : currentRole === 'a1' ? searchQueryA1 : currentRole === 'a2' ? searchQueryA2 : currentRole === 'a3' ? searchQueryA3 : currentRole === 'a4' ? searchQueryA4 : currentRole === 'd1' ? searchQueryD1 : currentRole === "d3" ? searchQueryD3 : searchQueryD2}
                onChange={(e) => handleSearchJudge(e, currentRole)}
              />
              <List w={"100%"} flex={1} justifyContent={"start"} spacing={2} mt={2}>
                {(currentRole === 'judge1' ? searchResultsJudge1 : currentRole === 'judge2' ? searchResultsJudge2 : currentRole === 'judge3' ? searchResultsJudge3 : currentRole === 'judge4' ? searchResultsJudge4 : currentRole === 'a1' ? searchResultsA1 : currentRole === 'a2' ? searchResultsA2 : currentRole === 'a3' ? searchResultsA3 : currentRole === 'a4' ? searchResultsA4 : currentRole === 'd1' ? searchResultsD1 : currentRole === "d3"? searchResultsD3 : searchResultsD2).map((user) => (
                  <ListItem key={user.id} flex={1} justifyContent={"start"} cursor="pointer" onClick={() => handleSelectUser(user, currentRole)}>
                    <Flex alignItems={"center"} gap={"5px"}>
                      <Box><Avatar size='md' name={user.name} src='https://bit.ly/broken-link' /></Box>
                      <Box>
                        <Text fontWeight={"bold"}>{user.name}</Text>
                        <Text>{user.email}</Text>
                      </Box>
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </VStack>
            <VStack spacing={4}>
              <Input
                placeholder="Judge Name"
                value={judgeName}
                onChange={(e) => setJudgeName(e.target.value)}
              />
              <PhoneInput
                placeholder="Judge Mobile"
                value={judgeMobile}
                required country={"in"}
                onChange={setJudgeMobile}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            {/* <Button colorScheme="blue" onClick={() => handleConfirmAddUser(currentRole)}>
        Add {currentRole === 'judge1' ? 'Judge 1' : 'Judge 2'}
      </Button> */}
            <Button colorScheme="blue" onClick={handleAddJudge}>
              Add Judge
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isSupervisorModalOpen} onClose={() => setIsSupervisorModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Supervisor</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Input
                placeholder={`Search ${currentRole === 'judge1' ? 'Judge 1' : currentRole === 'judge2' ? 'Judge 2' : currentRole === 'judge3' ? 'Judge 3' : currentRole === 'judge4' ? 'Judge 4' : currentRole === 'a1' ? 'A 1' : currentRole === 'a2' ? 'A 2' : currentRole === 'a3' ? 'A 3' : currentRole === 'a4' ? 'A 4' : currentRole === 's1' ? 'Supervisor' : currentRole === 'd1'? 'D1' : 'D2'} by name...`}
                value={currentRole === 'judge1' ? searchQueryJudge1 : currentRole === 'judge2' ? searchQueryJudge2 : currentRole === 'judge3' ? searchQueryJudge3 : currentRole === 'judge4' ? searchQueryJudge4 : currentRole === 'a1' ? searchQueryA1 : currentRole === 'a2' ? searchQueryA2 : currentRole === 'a3' ? searchQueryA3 : currentRole === 'a4' ? searchQueryA4 : currentRole === 's1' ? searchQuerySupervisor : currentRole === 'd1' ? searchQueryD1 : searchQueryD2}
                onChange={(e) => handleSearchJudge(e, currentRole)}
              />
              <List spacing={2} mt={2}>
                {searchResultsSupervisor.map((user) => (
                  <ListItem key={user.id} cursor="pointer" onClick={() => handleSelectUser(user, 's1')}>
                    <Flex alignItems={"center"} gap={"5px"}>
                      <Box><Avatar size='md' name={user.name} src='https://bit.ly/broken-link' /></Box>
                      <Box>
                        <Text fontWeight={"bold"}>{user.name}</Text>
                        <Text>{user.email}</Text>
                      </Box>
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </VStack>
            <VStack spacing={4}>
              <Input
                placeholder="Supervisor Name"
                value={supervisorName}
                onChange={(e) => setSupervisorName(e.target.value)}
              />
              <PhoneInput
                placeholder="Supervisor Mobile"
                value={supervisorMobile}
                required country={"in"}
                onChange={setSupervisorMobile}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddSupervisor}>
              Add Supervisor
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isJudgeConfirmModalOpen} onClose={onJudgeConfirmModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Add Judge</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Do you want to add {selectedPlayer?.name}?</Text>
          </ModalBody>
          <ModalFooter>
            {/* <Button colorScheme="blue" mr={3} onClick={handleConfirmAddUser}>
              Yes
            </Button> */}
            <Button colorScheme="blue" onClick={() => handleConfirmAddUser(currentRole)}>
              Add {currentRole === 'judge1' ? 'Judge 1' : currentRole === 'judge2' ? 'Judge 2' : currentRole === 'judge3' ? 'Judge 3' : currentRole === 'judge4' ? 'Judge 4' : currentRole === 'a1' ? 'A 1' : currentRole === 'a2' ? 'A 2' : currentRole === 'a3' ? 'A 3' : currentRole === 'a4' ? 'A 4' : currentRole === 's1' ? 'Supervisor' : currentRole === 'd1' ? 'CJP' : currentRole === "d3"? "D1" : 'D2'}
            </Button>
            <Button variant="ghost" onClick={onConfirmModalClose}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  )
}

export default ManageEventModal;