import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react';
import PlayerListPageAerobics from './PlayerListPage';
import PlayerListPageAerobics2 from './PlayerListPage2';

const PlayerListTab = () => {
  return (
    <div>
        <Tabs>
            <TabList>
                <Tab>Basic</Tab>
                <Tab>Detailed</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <PlayerListPageAerobics/>
                </TabPanel>
                <TabPanel>
                    <PlayerListPageAerobics2/>
                </TabPanel>
            </TabPanels>
        </Tabs>
    </div>
  )
}

export default PlayerListTab